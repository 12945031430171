import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReadInputBox from "../../../../Elements/ReadInputBox";
import Cookies from "universal-cookie";
import Loading from "../../../../Elements/Loading";
import { viewFileHandler } from "../../../../../constants/Request";
import { momSchema } from "../../../../../schema";

function NoticeReadModal({ notice, setShowNoticeViewModel }) {
  const cookies = new Cookies();

  const [modelLoading, setModelLoading] = useState(false);
  // console.log(notice);
  const {
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(momSchema),
  });

  const handleClose = () => {
    setShowNoticeViewModel(false);
  };

  const [Documentt, setDocument] = useState(null);
  const [DocumenttType, setDocumentType] = useState(null);

  const assignMoMData = async () => {
    if (notice) {
      reset(notice);
      setValue(
        "Sharing_Status",
        getValues("Sharing_Status") === 0
          ? "Both"
          : getValues("Sharing_Status") === 1
          ? "Owner"
          : getValues("Sharing_Status") === 2
          ? "Tenant"
          : "Null"
      );
      if (notice.Notice_Document?.endsWith(".pdf")) {
        const fileurl = await viewFileHandler(notice.Notice_Document, "notice");
        setDocumentType("pdf");
        setDocument(fileurl);
      } else {
        setDocumentType("image");
        setDocument(notice.Notice_Document);
      }
    } else {
      reset();
    }
  };
  // Set the form values when the modal is opened in edit mode
  useEffect(() => {
    assignMoMData();
  }, [notice, reset]);

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-30`}
    >
      <style>{`body { overflow: hidden; }`}</style>
      <Loading load={modelLoading} />
      <div className="flex  justify-center items-center h-full bg-transparent">
        <div className="bg-white rounded-2xl    w-[80%] h-[80%] overflow-auto ">
          <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 ">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-secondaryl font-extrabold text-2xl euclidbold">
                Notice Details
              </h2>
              <button
                onClick={handleClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <Icon
                  icon="carbon:close-filled"
                  className="text-secondaryl hover:text-opacity-95 text-3xl"
                />
              </button>
            </div>

            <form className="mb-3">
              <div className="mb-3">
                <ReadInputBox
                  title={"Notice Title"}
                  register={register}
                  filedName={"Notice_Title"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                <ReadInputBox
                  title={"Notice Description"}
                  register={register}
                  filedName={"Notice_Description"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                <ReadInputBox
                  type="date"
                  title={"Start Date"}
                  register={register}
                  filedName={"Notice_Start_Date"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                <ReadInputBox
                  type="date"
                  title={"End Date "}
                  register={register}
                  filedName={"Notice_End_Date"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              {cookies.get("companyType") === "corporation" && (
                <div className="mb-3">
                  <ReadInputBox
                    title={"Who Can Show"}
                    register={register}
                    filedName={"Sharing_Status"}
                    must={true}
                    errors={errors}
                    className={"md:text-sm lg:text-base"}
                    cursorNotAllowed={false}
                  />
                </div>
              )}

              <div className="mb-3">
                {notice && (
                  <>
                    <label
                      className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
                    >
                      Uploaded Notice Document :
                    </label>
                    <div className="pt-3">
                      {modelLoading ? (
                        "Loading..."
                      ) : Documentt ? (
                        DocumenttType === "pdf" ? (
                          <object
                            data={Documentt}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                            className="w-full h-[90vh]"
                          >
                            <p>PDF could not be displayed</p>
                          </object>
                        ) : (
                          <img
                            src={Documentt}
                            alt="MoM Document"
                            className="w-full  h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                          />
                        )
                      ) : (
                        <div className="text-infromation">
                          No Documents Provided
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className="flex flex-wrap gap-5 justify-start ">
                {/* {onDelete && (
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    onClick={onDelete}
                  >
                    Delete
                  </button>
                )} */}
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  onClick={(e) => setShowNoticeViewModel(false)}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeReadModal;
