import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { noticeSchema } from "../../../../../schema";
import InputBox from "../../../../Elements/InputBox";
import {
  ApprovedIcon,
  InputFileIcon,
  Spinner,
} from "../../../../../assets/images";
import toast from "react-hot-toast";
import InputBoxLiveDate from "../../../../Elements/InputBoxLiveDate";
import DropDownStyle2 from "../../../../Elements/DropDownStyle2";
import Cookies from "universal-cookie";
import axios from "axios";
import { corporationNoticeAPI } from "../../../../../constants/API";
import { viewFileHandler } from "../../../../../constants/Request";

const whoCanShowOption = [
  { ID: 0, Sharing_Status: "Both" },
  { ID: 1, Sharing_Status: "Owner" },
  { ID: 2, Sharing_Status: "Tenant" },
];

const NoticeModel = ({
  selectedNotice,
  onClose,
  fetchNotice,
  isEditNotice,
  setLoad,
  setIsEditNotice,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(noticeSchema),
  });

  // console.log(selectedNotice);
  const cookies = new Cookies();

  const [selectDate, setSelectDate] = useState(true);

  const [minStartDate, setMinStartDate] = useState(
    // new Date().toISOString().split("T")[0]
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );

  const [minEndDate, setMinEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [maxProposalDeadline, setMaxProposalDeadline] = useState("");

  const [modelLoading, setModelLoading] = useState(false);
  const [selectWhoCanShow, setSelectWhoCanShow] = useState(null);

  // image upload handling constants
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "application/pdf"];
  const [Document, setDocument] = useState(null);
  const [DocumentError, setDocumentError] = useState("");
  const [DocumentUploded, setDocumentUploded] = useState("");
  const [DocumentURL, setDocumentURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingDocument, setUploadingDocument] = useState(false);

  // for Edit
  const [noticeDocument, setnoticeDocument] = useState(null);
  const [noticeDocumentType, setnoticeDocumentType] = useState(null);

  const handleChangeDocument = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDocument(null);
      setDocumentError("");
      setDocumentUploded("");
      setDocumentURL("");
      setValue("Notice_Document", "");
      clearErrors("Notice_Document");
    }

    if (!file) {
      setDocument(null);
      setDocumentError("");
      setDocumentUploded("");
      setDocumentURL("");
      setValue("Notice_Document", "");
      clearErrors("Notice_Document");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setDocument(file);
        setDocumentUploded("");
        setDocumentError("");
        setDocumentURL("");
        setValue("Notice_Document", file);
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setDocument(null);
        setDocumentError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        setDocumentUploded("");
        setDocumentURL("");
        setValue("Notice_Document", "");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setDocument(null);
        setDocumentError("File size exceeds limit (5 MB)");
        setDocumentUploded("");
        setDocumentURL("");
        setValue("Notice_Document", "");
      } else {
        setDocument(null);
        setDocumentError("Something went wrong! Please try again.");
        setDocumentUploded("");
        setDocumentURL("");
        setValue("Notice_Document", "");
      }
    }
  };

  const assignNoticeData = async () => {
    reset(selectedNotice);
    setSelectWhoCanShow(selectedNotice.Sharing_Status);
    if (selectedNotice?.Notice_Document?.endsWith(".pdf")) {
      const fileurl = await viewFileHandler(
        selectedNotice.Notice_Document,
        "notice"
      );
      setnoticeDocumentType("pdf");
      setnoticeDocument(fileurl);
    } else {
      setnoticeDocumentType("image");
      setnoticeDocument(selectedNotice.Notice_Document);
    }
  };

  useEffect(() => {
    if (selectedNotice) {
      assignNoticeData();
    }
  }, [isEditNotice, selectedNotice]);

  const onSubmit = async (data) => {
    try {
      // console.log(data);
      setLoad(true);
      const formData = new FormData();

      formData.append("Notice_Title", data.Notice_Title);
      formData.append("Notice_Description", data.Notice_Description);
      formData.append("Sharing_Status", selectWhoCanShow);
      formData.append("Notice_Start_Date", data.Notice_Start_Date);
      formData.append("Notice_End_Date", data.Notice_End_Date);

      if (Document) {
        formData.append("Notice_Document", Document);
      }

      if (isEditNotice) {
        const response = await axios.put(
          `${corporationNoticeAPI}/${selectedNotice.ID}`,
          formData,
          {
            headers: {
              authorization: cookies.get("token"),
              companyType: cookies.get("companyType"),
              // Don't manually set Content-Type here
            },
          }
        );
        toast.success("Notice updated successfully");
        fetchNotice();
        // setIsEditNotice(false);
        // onClose(false);
      } else {
        const response = await axios.post(corporationNoticeAPI, formData, {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        });
        toast.success("Notice added successfully");
        fetchNotice();
        setIsEditNotice(false);
        onClose(false);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-secondaryl font-extrabold text-xl euclidbold">
            {isEditNotice ? "Edit Notice" : "Add Notice"}
          </h2>
          <button
            onClick={() => onClose(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            ✖
          </button>
        </div>
        <div className="mb-3">
          {/* service name */}
          <InputBox
            title={"Notice Title"}
            register={register}
            filedName={"Notice_Title"}
            must={true}
            errors={errors}
            className={"md:text-sm lg:text-base"}
          />
          <InputBox
            title={"Notice Description"}
            register={register}
            filedName={"Notice_Description"}
            must={true}
            errors={errors}
            className={"md:text-sm lg:text-base"}
          />
          <InputBoxLiveDate
            title={"Start Date"}
            register={register}
            filedName={"Notice_Start_Date"}
            must={true}
            type="date"
            setValue={setValue}
            min={minStartDate}
            errors={errors}
            className={"md:text-sm lg:text-base"}
            schema={noticeSchema}
            setError={setError}
            setMinEndDate={setMinEndDate}
            setMaxProposalDeadline={setMaxProposalDeadline}
            setSelectDate={setSelectDate}
            otherDependencies={true}
          />
          <InputBoxLiveDate
            title={"End Date"}
            register={register}
            filedName={"Notice_End_Date"}
            must={true}
            type="date"
            errors={errors}
            min={minEndDate}
            className={"md:text-sm lg:text-base"}
            schema={noticeSchema}
            setError={setError}
            disabled={selectDate}
          />
          <div className="mb-3">
            <label
              htmlFor="Sharing_Status"
              className="block font-normal text-secondaryl"
            >
              <p>
                Who Can Show
                <span className="text-red-500 text-base ml-1">*</span>
              </p>
            </label>

            <div className="">
              <DropDownStyle2
                title="Who Can Show"
                options={whoCanShowOption}
                onSelect={setSelectWhoCanShow}
                selectOption={selectWhoCanShow}
                setValue={setValue}
                filedName={"Sharing_Status"}
                clearErrors={clearErrors}
                errors={errors}
                dbfield={"Sharing_Status"}
              />
            </div>
          </div>
          <div className="mb-2 lg:mb-0 mt-2">
            {/* Input */}
            <label
              className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base`}
            >
              Document Upload :{" "}
              <span className="text-red-500 text-base">*</span>
            </label>

            <div>
              <div className="mb-6">
                {/* Business Card File Input */}
                <div className="col-span-2 sm:col-span-2 md:col-span-3">
                  <input
                    type="file"
                    id="Document"
                    name="Document"
                    accept={SUPPORTED_FILE_TYPES.join(",")}
                    className="hidden"
                    onChange={handleChangeDocument}
                    disabled={uploadingFile}
                  />

                  {/* Label For style is input tag and use in File input */}
                  <label
                    htmlFor="Document"
                    className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none ${
                      uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                  >
                    <div className="flex items-center h-full space-x-1 overflow-hidden">
                      <span>
                        <InputFileIcon />
                      </span>
                      {Document ? (
                        <span className="line-clamp-1">
                          <span>{Document.name}</span>
                        </span>
                      ) : (
                        <span className="text-infromation text-opacity-50   text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                          No File Chosen
                        </span>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              {selectedNotice && (
                <div className="mb-6">
                  <label
                    className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
                  >
                    Uploaded Notice Document :
                  </label>
                  <div className="">
                    {modelLoading ? (
                      "Loading..."
                    ) : noticeDocument ? (
                      noticeDocumentType === "pdf" ? (
                        <object
                          data={noticeDocument}
                          type="application/pdf"
                          width="100%"
                          height="600px"
                          className="w-full h-[90vh]"
                        >
                          <p>PDF could not be displayed</p>
                        </object>
                      ) : (
                        <img
                          src={noticeDocument}
                          alt="Meeting Document"
                          className="w-full  h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                        />
                      )
                    ) : (
                      <div className="text-infromation">
                        No Documents Provided
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* Error */}
              <div className="h-[3vh] mb-3">
                {uploadingDocument && <Spinner />}
                {DocumentError && (
                  <p className="text-red-500 text-xs leading-none">
                    {DocumentError}
                  </p>
                )}
                {errors.Document && ( // Error message from yup validation
                  <p className="text-red-500 text-xs leading-none">
                    {errors.Document.message}
                  </p>
                )}
                {DocumentUploded && (
                  <p className="text-infromation text-sm leading-none flex items-center gap-2 mt-1">
                    {/* {DocumentUploded}  */}
                    <ApprovedIcon />
                    Uploaded Successfully!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Save Button */}
        <div className="my-3 mx-auto w-fit">
          <button
            type="submit"
            className={`
        h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out  ${
          uploadingDocument ? "cursor-not-allowed" : "cursor-pointer"
        }`}
            disabled={uploadingDocument}
          >
            {isEditNotice ? "Edit" : "Add"} Notice
          </button>
        </div>
      </form>
    </div>
  );
};

export default NoticeModel;
