import React, { useEffect, useState } from "react";
import { FaCalendarDays } from "react-icons/fa6";
import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "../../constants/API";
import { Link } from "react-router-dom";
import BlogSkeleton from "./BlogSkeleton";
import Cookies from "universal-cookie";

const Blog = () => {
  const cookies = new Cookies();
  const [blogs, setBlogs] = useState([]);
  const [load, setLoad] = useState(false);
  const [isLogin, setIsLogin] = useState(cookies.get("companyType"));

  const [companyType, setCompanyType] = useState(cookies.get("companyType"));

  const featchBlog = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${baseUrl}/blog/publishblogs`);
      response?.data?.data.sort(
        (a, b) => new Date(b.Blog_Publish_Date) - new Date(a.Blog_Publish_Date)
      );
      // console.log(response.data.data);
      setBlogs(response.data.data);
    } catch (error) {
      console.error("Error:", error.response);
      toast.error(
        error.response.data.message ||
          "Something went wrong. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const dateFormate = (date) => {
    const originalDate = new Date(date);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = originalDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  useEffect(() => {
    if (isLogin === "corporation") {
      setCompanyType("corporations");
    } else if (isLogin === "contractor") {
      setCompanyType("contractors");
    }
    featchBlog();
  }, []);

  return (
    <div className="w-full mx-auto  p-6 rounded-2xl flex flex-col justify-center">
      {isLogin && (
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          Blog
        </h1>
      )}

      {load ? (
        <BlogSkeleton card={isLogin ? 6 : 8} />
      ) : blogs.length > 0 ? (
        <div
          className={`grid ${
            isLogin
              ? "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
              : "sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4"
          }  gap-5 mt-4 justify-center`}
        >
          {blogs.map((blog, index) => (
            <div
              className="w-full max-w-sm min-w-sm rounded  overflow-hidden shadow-lg ring-[0.5px] ring-[#3E5CAAB2] p-2 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.25)_-46.44%,_rgba(153,_153,_153,_0.25)_100%)] "
              key={index}
            >
              <div className="flex flex-col items-center justify-center">
                {blog.Blog_Image ? (
                  <img
                    className="w-80 rounded-md h-56 "
                    src={blog.Blog_Image}
                    alt={blog.Blog_Title}
                  />
                ) : (
                  <div className="w-80 rounded-md h-56 ring-[0.5px] ring-infromation flex justify-center items-center">
                    No Image
                  </div>
                )}
                <div className="pt-3 space-y-1 ">
                  <h1 className="text-secondaryl text-center  text-2xl  line-clamp-1">
                    {blog.Blog_Title}
                  </h1>
                  <div className="truncate">
                    <p className="text-infromation text-xs flex items-center  justify-center gap-1">
                      <FaCalendarDays />
                      {dateFormate(blog.Blog_Publish_Date)}
                    </p>
                  </div>
                </div>
                <Link
                  className="
                h-11 w-fit euclidbold my-3 ring-secondaryl rounded-md flex justify-center text-secondaryl  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 cursor-pointer euclidbold  text-lg  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out"
                  to={
                    isLogin
                      ? `/${companyType}/dashboard/blog/${blog.Blog_Slug}`
                      : `/blog/${blog.Blog_Slug}`
                  }
                >
                  READ MORE
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold">
            There are no blog posts available right now. Check back later for
            updates!
          </h2>
        </div>
      )}
    </div>
  );
};

export default Blog;
