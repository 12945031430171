import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import { Header, Hero } from "../../components";
import Footer from "../../components/Layout/Footer";
import aboutimg from "../../assets/image/Rectangle 524.png";
import wcu from "../../assets/images/home/wcu.png";
import hiw from "../../assets/images/home/hiw.png";
import t1 from "../../assets/images/home/t1.jpg";
import t2 from "../../assets/images/home/t2.png";
import t3 from "../../assets/images/home/t3.png";
import t4 from "../../assets/images/home/t4.png";
import { set } from "react-hook-form";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { MdCancel } from "react-icons/md";

function Home() {
  const [signUnModel, setSignUnModel] = useState(false);
  const signUpModelRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutsideSignUpBox = (event) => {
  //     if (
  //       signUpModelRef.current &&
  //       !signUpModelRef.current.contains(event.target)
  //     ) {
  //       setSignUnModel(false);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutsideSignUpBox);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutsideSignUpBox);
  //   };
  // }, []);

  return (
    <div className="overflow-hidden " id="homepagestart">
      <Header />
      <Hero />
      <div className="py-4 md:py-16 px-[2.5%] md:px-[5%]">
        <div className="">
          <div className="grid grid-cols-1 lg:grid-cols-7 gap-2 items-center">
            {/* Image Section */}
            <div className="lg:px-4 xl:px-16 lg:col-span-3 order-2 lg:order-2">
              <div className="w-full">
                <img src={aboutimg} alt="About Us" className="w-full ml-auto" />
              </div>
            </div>
            {/* About Us Section */}
            <div className="px-0 lg:col-span-4 order-1 lg:order-1">
              <div className="mb-4 md:-ml-2">
                <h2 className="text-2xl lg:text-4xl pl-2 text-primaryl euclidbold border-l-2 border-secondaryl">
                  Who We Are?
                </h2>
              </div>
              {/* <hr className="border-t-2 mb-4" /> */}
              <p className="mb-4 italic text-gray-800 text-justify capitalize tracking-wider">
                At Condominum Portal Inc, we bridge the gap between corporations
                and contractors with our innovative portal designed for seamless
                collaboration. Our platform empowers corporations to post their
                contracts, allowing contractors to submit detailed proposals.
                Through our streamlined process, contracts are finalized upon
                approval from the corporation. We also provide a secure space
                for both parties to discuss terms and conditions, ensuring
                clarity and mutual agreement. Our mission is to facilitate
                efficient and transparent interactions, transforming the way
                contracts are managed and executed. Join us and experience a new
                era of contract management.
              </p>
              <p className="mb-4 italic text-gray-800 text-justify tracking-wider">
                <b className="text-primaryl font-bold not-italic">
                  Our Mission :
                </b>{" "}
                To revolutionize contract management by providing a seamless
                platform for corporations and contractors to connect,
                collaborate, and finalize agreements efficiently and
                transparently.
              </p>
              <p className="mb-4 italic text-gray-800 text-justify tracking-wider">
                <b className="text-primaryl font-bold not-italic">
                  Our Vision :
                </b>{" "}
                To be the leading platform that transforms how corporations and
                contractors manage contracts, fostering successful partnerships
                and driving industry innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full">
        <img src={wcu} alt="About Us" className="why_choose_us" />
      </div>
      <div className="px-[2.5%] md:px-[5%] mb-12">
        <div className="md:-ml-2 mt-8">
          <h2 className="text-2xl lg:text-4xl  text-primaryl euclidbold border-l-2 border-secondaryl pl-2">
            How It Works?
          </h2>
        </div>
        <p className="my-3 w-full md:w-96 italic text-gray-800 text-justify capitalize tracking-wide">
          Simplifying the Contract Process from Posting to Finalization
        </p>
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full  md:w-1/3 md:mr-8">
            <img
              src={hiw}
              alt="About Us"
              className="w-full h-72 object-cover"
            />
          </div>
          <div className="w-full md:ml-8 lg:ml-10 mt-2 md:mt-0">
            <div className="gap-10  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center justify-center">
              <div className="flex flex-col w-64 my-2 px-1 mx-auto">
                <div className="w-8 mb-2">
                  <img src={t1} alt="About Us" className="w-full" />
                </div>
                <div className="text-primaryl font-bold mb-1 ">
                  For Corporations
                </div>
                <p className="w-64 italic">
                  Post your contracts with detailed requirements.
                </p>
              </div>
              <div className="flex flex-col w-64 my-2 px-1 mx-auto">
                <div className="w-8 mb-2">
                  <img src={t2} alt="About Us" className="w-full" />
                </div>
                <div className="text-primaryl font-bold mb-1">
                  For Contractors
                </div>
                <p className="w-64 italic">
                  Submit your proposals to the posted contracts.
                </p>
              </div>
              <div className="flex flex-col w-64 my-2 px-1 md:row-start-2 mx-auto">
                <div className="w-8 mb-2">
                  <img src={t3} alt="About Us" className="w-full" />
                </div>
                <div className="text-primaryl font-bold mb-1">
                  Approval & Finalization
                </div>
                <p className="w-64 italic">
                  Corporations review and approve proposals to finalize
                  contracts.
                </p>
              </div>
              <div className="flex flex-col w-64 my-2 px-1 md:row-start-2 mx-auto">
                <div className="w-8 mb-2">
                  <img src={t4} alt="About Us" className="w-full" />
                </div>
                <div className="text-primaryl font-bold mb-1">
                  Discussion Portal
                </div>
                <p className="w-64 italic">
                  Collaborate and discuss contract conditions in real- Time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center py-12 bg-white px-[2.5%] md:px-[5%] mb-12 ">
        <div className="border-l-4 border-secondaryl pl-4 flex flex-col items-start w-full">
          <div className="">
            <h2 className="text-2xl lg:text-4xl text-primaryl euclidbold">
              Ready To Simplify Your Contract Management?
            </h2>
            <p className="italic text-gray-800 py-3">
              Join Our Platform Today And Streamline Your Contract Process
              Effortlessly.
            </p>
          </div>

          <div className="relative w-full">
            <div
              className="text-center py-2 ring-2 w-32 cursor-pointer  ring-primaryl text-white bg-primaryl  font-semibold rounded hover:bg-secondaryl hover:ring-secondaryl transition "
              onClick={() => setSignUnModel(true)}
              ref={signUpModelRef}
            >
              Sign Up
            </div>
            {/* {signUnModel && (
              <div className="mt-2 mx-2 rounded-lg absolute shadow-md border-[1px] bg-white px-3 py-3 -left-[5%] md:left-[0%]  z-[999]">
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-5">
                  <NavLink
                    to="/corporations/signup"
                    className=" py-2 ring-2 cursor-pointer w-28 sm:w-32 text-center ring-primaryl text-white bg-primaryl  font-semibold rounded hover:bg-secondaryl hover:ring-secondaryl transition "
                  >
                    Corporations
                  </NavLink>
                  <NavLink
                    to="/contractors/signup"
                    className="py-2 ring-2 cursor-pointer w-28 sm:w-32 text-center  ring-primaryl text-white bg-primaryl  font-semibold rounded hover:bg-secondaryl hover:ring-secondaryl transition "
                  >
                    Contractors
                  </NavLink>
                  <div
                    className="py-2 ring-2 cursor-pointer  w-28 sm:w-32 text-center ring-primaryl text-white bg-primaryl  font-semibold rounded hover:bg-secondaryl hover:ring-secondaryl transition "
                    onClick={() => toast.success("Coming Soon")}
                  >
                    Tenant
                  </div>
                  <div
                    className=" py-2 ring-2 cursor-pointer w-28 sm:w-32 text-center  ring-primaryl text-white bg-primaryl  font-semibold rounded hover:bg-secondaryl hover:ring-secondaryl transition "
                    onClick={() => toast.success("Coming Soon")}
                  >
                    Owner
                  </div>
                </div>
              </div>
            )} */}

            {signUnModel && (
              <>
                {/* Apply overflow-hidden to the body when modal is open */}
                <style>{`body { overflow: hidden; }`}</style>

                <div className="fixed inset-0 flex items-center justify-center z-[999] bg-white/60">
                  <div className="relative w-full max-w-lg mx-4 p-6 bg-white rounded-lg shadow-lg transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
                    <div className="absolute top-0 right-0 mt-2 mr-2">
                      <MdCancel
                        className="text-secondaryl text-3xl cursor-pointer hover:text-secondaryd transition duration-200"
                        onClick={() => setSignUnModel(false)}
                      />
                    </div>
                    <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
                      Sign Up
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <NavLink
                        to="/corporations/signup"
                        className="py-3 px-4 rounded-lg bg-primaryl text-white text-center font-semibold shadow-md hover:bg-secondaryl hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 tracking-widest"
                      >
                        Corporation
                      </NavLink>
                      <NavLink
                        to="/contractors/signup"
                        className="py-3 px-4 rounded-lg bg-primaryl text-white text-center font-semibold shadow-md hover:bg-secondaryl hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 tracking-widest"
                      >
                        Contractor
                      </NavLink>
                      <NavLink
                        // div
                        to="/tenant/signup"
                        className="py-3 px-4 rounded-lg bg-primaryl text-white text-center font-semibold shadow-md hover:bg-secondaryl hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 cursor-pointer tracking-widest"
                        // onClick={() => toast.success("Coming Soon")}
                      >
                        Tenant
                      </NavLink>
                      <NavLink
                        // div
                        to="/owners/signup"
                        className="py-3 px-4 rounded-lg bg-primaryl text-white text-center font-semibold shadow-md hover:bg-secondaryl hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 cursor-pointer tracking-widest"
                        // onClick={() => toast.success("Coming Soon")}
                      >
                        Owners
                      </NavLink>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
