import React, { useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import { contractAPI } from "../../../constants/API";
import axios from "axios";
import toast from "react-hot-toast";
import DisplayContracts from "../../Contracts/Resident/DisplayContracts";
import { useLoading } from "../../../context/LoadingContext";
import { debounce } from "../../../utils/helper";

function Maintenance({ selectMonth, selectDay, selectYear, selectStatus }) {
  const { load, setLoad } = useLoading();
  const cookies = new Cookies();
  const [contracts, setContracts] = useState([]);
  const [displayContracts, setDisplayContracts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const [IsOwners, setIsOwners] = useState(false);

  const token = cookies.get("token");

  const fetchData = async () => {
    setLoading(true);
    try {
      if (!contracts.length > 0) {
        setLoad(true);
      }
      let apiUrl;
      if (cookies.get("companyType") === "owners") {
        setIsOwners(true);
        apiUrl = `${contractAPI}/residentarchivescontracts`;
      } else if (cookies.get("companyType") === "tenant") {
        setIsOwners(false);
        apiUrl = `${contractAPI}/residentarchivescontracts`;
      } else if (cookies.get("companyType") === "corporation") {
        apiUrl = `${contractAPI}/corporationresidentrequestcontracts`;
      }
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: token,
          companyType: cookies.get("companyType"),
        },
        params: {
          page,
          pageSize: 5,
        },
      });
      setContracts((prevData) => [
        ...prevData,
        ...response.data?.data.contracts,
      ]);
      setTotalPages(response.data?.data?.totalPages);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoad(false);
      setTimeout(() => {
        setLoading(false); // Stop loading spinner
      }, 1000);
    }
  };

  // const filterData = () => {
  //   const filteredContracts = contracts.filter((contract) => {
  //     return (
  //       (selectStatus && selectStatus?.value !== ""
  //         ? contract.Request_Status === selectStatus.value
  //         : true) &&
  //       (selectYear
  //         ? new Date(contract.Start_Date).getFullYear() === selectYear.value
  //         : true) &&
  //       (selectMonth
  //         ? new Date(contract.Start_Date).getMonth() + 1 === selectMonth.value
  //         : true) &&
  //       (selectDay
  //         ? new Date(contract.Start_Date).getDate() === selectDay.value
  //         : true)
  //     );
  //   });
  //   setDisplayContracts(filteredContracts);
  // };

  const filterData = () => {
    const filteredContracts = contracts.filter((contract) => {
      return (
        (!selectStatus ||
          selectStatus?.value === "" ||
          contract.Request_Status === selectStatus.value) &&
        (!selectYear ||
          new Date(contract.Start_Date).getFullYear() === selectYear.value) &&
        (!selectMonth ||
          new Date(contract.Start_Date).getMonth() + 1 === selectMonth.value) &&
        (!selectDay ||
          new Date(contract.Start_Date).getDate() === selectDay.value)
      );
    });
    setDisplayContracts(filteredContracts);
  };

  useEffect(() => {
    if (cookies.get("companyType") === "owners") {
      // console.log("owners");
      setIsOwners(true);
    } else if (cookies.get("companyType") === "tenant") {
      // console.log("tenant");
      setIsOwners(false);
    }
  }, []);

  useEffect(() => {
    filterData();
  }, [contracts, selectDay, selectMonth, selectYear, selectStatus]);

  useEffect(() => {
    const fetchDebouncedData = debounce(fetchData, 500);
    fetchDebouncedData();
  }, [page]);

  // Function to handle scroll event in the container
  const handleContainerScroll = debounce(() => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        // Load next page when the container reaches the bottom
        if (page < totalPages) {
          setPage(page + 1);
        }
      }
    }
  }, 200);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleContainerScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleContainerScroll);
      }
    };
  }, [page, totalPages]);

  return (
    <DisplayContracts
      title="Maintenance Request"
      contracts={displayContracts}
      load={load}
      loading={loading}
      containerRef={containerRef}
    />
  );
}

export default Maintenance;
