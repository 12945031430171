import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import ComplaintSuggestionCard from "../../../../components/Resident/ComplaintSuggestion/ComplaintSuggestionCard";
import DocumentCardSkeleton from "../../../../components/Resident/ComplaintSuggestion/ComplaintSuggestionSkeleton";
import ComplaintSuggestionModal from "../../../../components/Resident/ComplaintSuggestion/ComplaintSuggestionModal";
import axios from "axios";
import Loading from "../../../../components/Elements/Loading";
import {
  ownersComplaintSuggestionAPI,
  tenantComplaintSuggestionAPI,
} from "../../../../constants/API";

const ComplaintSuggestion = () => {
  const cookies = new Cookies();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoad(true);

      const response1 = await axios.get(
        `${ownersComplaintSuggestionAPI}/corporation`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );

      const response2 = await axios.get(
        `${tenantComplaintSuggestionAPI}/corporation`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );

      // console.log(response1.data.data);

      // console.log(response2.data.data);

      setData(response1.data.data.concat(response2.data.data));
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDelete = async (ComplaintSuggestion) => {
    setPageLoading(true);
    try {
      await axios.delete(
        `${ownersComplaintSuggestionAPI}/${ComplaintSuggestion.ID}`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );

      const newData = data.filter((doc) => doc.ID !== ComplaintSuggestion.ID);
      setData(newData);
      // fetchData(); // Fetch meetings after adding/updating
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message || "Failed to save meeting");
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      <div className="rounded-2xl">
        <div className="w-full  rounded-2xl px-4 m-auto  p-6 flex flex-col justify-center">
          <div className="flex flex-wrap gap-5 justify-between items-center pb-2">
            <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
              Complaint/Suggestion
            </h1>
          </div>

          <div className="">
            {load ? (
              <DocumentCardSkeleton />
            ) : data.length > 0 ? (
              data.map((data, index) => (
                <div key={index}>
                  <ComplaintSuggestionCard
                    data={data}
                    onDelete={() => handleDelete(data)}
                    fetchData={fetchData}
                    setLoad={setPageLoading}
                  />
                </div>
              ))
            ) : (
              <div className="text-secondaryl text-lg py-4 text-center">
                No Public Information Certificate Found
              </div>
            )}
          </div>
        </div>

        {showModal && (
          <div className={`fixed inset-0 z-30 overflow-y-auto bg-[#231F20B2] `}>
            <div className="flex justify-center items-center h-full">
              <div className="bg-white max-h-fit rounded-lg md:w-[40%] w-4/5 h-[80%] overflow-y-auto relative">
                <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 relative">
                  <ComplaintSuggestionModal
                    onClose={handleCloseModal}
                    fetchData={fetchData}
                    setLoad={setLoad}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ComplaintSuggestion;
