import React from "react";
import { Location, Star } from "../../../assets/images";

function ReviewPortfolio({ contractor, cityAreaData, ratings }) {
  const renderRatingStars = () => {
    const stars = [];
    const fullStars = Math.floor(contractor.averageRating);

    for (let i = 0; i < fullStars; i++) {
      stars.push(<Star key={i} />);
    }

    return stars;
  };

  return (
    <div className="w-[100%] lg:w-2/6 h-[100%] py-4 px-2 min-w-60 bg-primarybg rounded text-center">
      <div className="flex justify-center min-w-56">
        <img
          className="flex w-52 h-52 md:w-56 md:h-56 rounded-full ring-[0.5px] ring-secondaryl"
          src={contractor.Profile_Photo}
          alt=""
        />
      </div>

      <div className="text-secondaryl space-y-2 my-4">
        <h1 className="text-4xl md:text-5xl flex justify-center euclidbold ">
          {contractor.First_Name} {contractor.Last_Name}
        </h1>
        <h2 className="text-2xl  flex justify-center text-infromation euclidbold">
          {contractor.Designation}
        </h2>
        <div className="text-base flex justify-center break-words items-center text-infromation gap-2">
          <Location />
          <p>
            {cityAreaData.map((name, index) => {
              if (index === cityAreaData.length - 1) {
                return name;
              } else {
                return name + " ,";
              }
            })}
          </p>
        </div>
      </div>

      <div className="euclidbold text-secondaryl space-y-2">
        <p className="text-2xl">Project Completed: 50</p>
        <p className="text-2xl ">Current Rating: {ratings}</p>
        <div className="flex gap-2 lg:gap-3 ">{renderRatingStars()}</div>
      </div>
    </div>
  );
}

export default ReviewPortfolio;
