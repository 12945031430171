import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApprovedIcon, InputFileIcon, Spinner } from "../../assets/images";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { contractAPI } from "../../constants/API";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import InputBox from "../../components/Elements/InputBox";
import DropDownStyle1 from "../../components/Elements/DropDownStyle1";
import Loading from "../../components/Elements/Loading";
import { Icon } from "@iconify/react";
import InputBoxLiveDateWithoutProposalDate from "../../components/Elements/InputBoxLiveDateWithoutProposalDate";

const { useNavigate, Link } = require("react-router-dom");
const notifysuccess = (props) => toast.success(props, { duration: 4000 });
const notifyfailure = (props) => toast.error(props, { duration: 2000 });

function CreateContractByResidents() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  const [minStartDate, setMinStartDate] = useState(
    // new Date().toISOString().split("T")[0]
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );

  const [minEndDate, setMinEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [todayDate, setTodayDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [selectDate, setSelectDate] = useState(true);

  const schema = yup.object().shape({
    projectName: yup.string().required("Project Name is required"),
    projectLocation: yup.string().required("Project Location is required"),
    projectType: yup.string().required("Project Type is required"),
    // budget: yup.string(),
    startDate: yup.string().required("Start Date is required"),
    endDate: yup.string().required("End Date is required"),
    projectDescription: yup
      .string()
      .required("Project Description is required"),
    projectDocs: yup.string(),
    projectPhotos0: yup.string(),
    projectPhotos1: yup.string(),
    projectPhotos2: yup.string(),
    projectPhotos3: yup.string(),
    projectPhotos4: yup.string(),
    projectPhotos5: yup.string(),
    projectPhotos6: yup.string(),
    projectPhotos7: yup.string(),
    projectPhotos8: yup.string(),
    projectPhotos9: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onCreateListing = async (data) => {
    // console.log(data);

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    const keysToRemove = [
      "projectPhotos0",
      "projectPhotos1",
      "projectPhotos2",
      "projectPhotos3",
      "projectPhotos4",
      "projectPhotos5",
      "projectPhotos6",
      "projectPhotos7",
      "projectPhotos8",
      "projectPhotos9",
      "projectDocs",
    ];

    keysToRemove.forEach((key) => formData.delete(key));

    contractImages.forEach((image, index) => {
      if (image) {
        formData.append(`projectPhotos${index + 1}`, image);
      }
    });

    if (contractDocs) {
      formData.append("projectDocs", contractDocs);
      // console.log("contractDocs", contractDocs);
    } else {
      formData.append("projectDocs", "");
    }

    if (cookies.get("companyType") === "owners") {
      formData.set("Is_Owners", true);
    } else {
      formData.set("Is_Owners", false);
    }

    formData.forEach((value, key) => {
      // console.log(key, value);
    });

    setLoad(true);
    try {
      await axios.post(`${contractAPI}/createcontractbyresident`, formData, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      notifysuccess("Contract Create Successfully", { duration: 4000 });
      reset();
      navigate("/corporations/dashboard/home");
    } catch (error) {
      notifyfailure(
        error.response.data.message ||
          "Failed to Create Contract. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const ProjectTypeOption = [
    { ID: 2, projectType: "Once" },
    { ID: 3, projectType: "Twice" },
    { ID: 4, projectType: "Quarterly" },
    { ID: 5, projectType: "Monthly" },
    { ID: 6, projectType: "Daily" },
    { ID: 7, projectType: "Seasonal" },
  ];

  const [selectProjectType, setSelectProjectType] = useState(null);

  // image upload handling constants
  const FILE_SIZE_LIMIT_DOCS = 5 * 1024 * 1024; // 5MB
  const SUPPORTED_FILE_TYPES_DOCS = ["application/pdf"];
  const [contractDocs, setContractDocs] = useState(null);
  const [contractDocsError, setContractDocsError] = useState("");
  const [contractDocsUploded, setContractDocsUploded] = useState("");
  const [contractDocsURL, setContractDocsURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingContractDocs, setUploadingContractDocs] = useState(false);

  const handleChangeContractDocs = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setContractDocs(null);
      setContractDocsError("Please Upload a Docs of Project/Building");
      setContractDocsUploded("");
      setContractDocsURL("");
      clearErrors("projectDocs");
      setValue("projectDocs", "");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT_DOCS &&
        SUPPORTED_FILE_TYPES_DOCS.includes(file.type)
      ) {
        setContractDocs(file);
        setContractDocsError("");
        setContractDocsURL("");
        clearErrors("projectDocs");
        setContractDocsUploded("");
        setValue("projectDocs", file);
      } else if (file && !SUPPORTED_FILE_TYPES_DOCS.includes(file.type)) {
        setContractDocs(null);
        setContractDocsError("Unsupported file type file type must be PDF ");
        setContractDocsUploded("");
        setContractDocsURL("");
        clearErrors("projectDocs");
        setValue("projectDocs", "");
      } else if (file && !file.size <= FILE_SIZE_LIMIT_DOCS) {
        setContractDocs(null);
        setContractDocsError(
          "File size exceeds limit. File size must be less than 5 MB"
        );
        setContractDocsUploded("");
        setContractDocsURL("");
        clearErrors("projectDocs");
        setValue("projectDocs", "");
      } else {
        setContractDocs(null);
        setContractDocsError("Something went wrong please try again later");
        setContractDocsUploded("");
        setContractDocsURL("");
        clearErrors("projectDocs");
        setValue("projectDocs", "");
      }
    }
  };

  const [contractImages, setContractImages] = useState(Array(10).fill(null));
  const [displayContractImages, setDisplayContractImages] = useState(
    Array(10).fill(null)
  );
  const [contractImagesError, setContractImagesError] = useState("");
  const [contractImagesUploading, setContractImagesUploading] = useState(false);

  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"]; // Example supported file types

  const handleChangeContractImages = (event, index) => {
    setContractImagesUploading(true);
    const files = Array.from(event.target.files);
    let newContractImages = [...contractImages];
    let validFiles = [];
    let errorMessage = "";

    for (let file of files) {
      if (file.size > FILE_SIZE_LIMIT) {
        errorMessage = `File size should be less than 5MB is too large.`;
      } else if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
        errorMessage = `File type should be .jpeg, .jpg, .png.`;
      } else {
        validFiles.push(file);
      }
    }

    if (validFiles.length > 0) {
      newContractImages[index] = validFiles[0];

      validFiles = validFiles.slice(1);

      for (let i = 0; i < validFiles.length; i++) {
        const newIndex = newContractImages.findIndex(
          (element) => element === null
        );

        if (newIndex !== -1) {
          newContractImages[newIndex] = validFiles[i];
        } else {
          break;
        }
      }
    }

    if (newContractImages.length > 10) {
      newContractImages = newContractImages.slice(0, 10);
      errorMessage = "You can only upload up to 10 images.";
    }

    setContractImages(newContractImages);

    if (errorMessage) {
      setContractImagesError(errorMessage);
    } else {
      setContractImagesError("");
    }

    const newDisplayImages = newContractImages.map((file) =>
      file ? URL.createObjectURL(file) : null
    );
    setDisplayContractImages(newDisplayImages);
    setContractImagesUploading(false);
  };

  const handleRemoveImage = (index) => {
    setContractImagesError("");
    const newContractImages = [...contractImages];
    const newDisplayImages = [...displayContractImages];
    // newContractImages.splice(index, 1);
    // newDisplayImages.splice(index, 1);
    newContractImages[index] = null;
    newDisplayImages[index] = null;
    setContractImages(newContractImages);
    setDisplayContractImages(newDisplayImages);
  };

  const clearErrorsProjectAttachment = () => {
    setContractDocsError("");
    setContractImagesError("");
  };

  return (
    <div>
      <Loading load={load} />

      <div className="w-full px-4 m-auto p-6 rounded-2xl flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          Create Work Request
        </h1>
        <form
          onSubmit={handleSubmit(onCreateListing)}
          className="mt-8"
          encType="multipart/form-data"
        >
          {/* Project Name */}
          <div className="mb-6">
            <InputBox
              title={"Project Name"}
              register={register}
              filedName={"projectName"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />
          </div>

          {/* Project Location, Type, Budget */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-14 mb-3">
            {/* Project Location */}
            <div className="">
              <InputBox
                title={"Project Location"}
                register={register}
                filedName={"projectLocation"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
              />
            </div>
            {/* Project Type */}
            <div className="">
              <label
                htmlFor={"projectType"}
                className="block font-normal text-secondaryl"
              >
                <p>
                  Project Type
                  <span className="text-red-500 text-base ml-1">*</span>
                </p>
              </label>

              <DropDownStyle1
                title="Select Project Type"
                options={ProjectTypeOption}
                onSelect={setSelectProjectType}
                selectOption={selectProjectType}
                setValue={setValue}
                filedName={"projectType"}
                clearErrors={clearErrors}
                errors={errors}
                dbfield={"projectType"}
              />
            </div>

            {/* Budget */}
            {/* <div className="mb-6 relative ">
              <span className="absolute top-[45%] left-[2%]">$</span>
              <InputBox
                title={"Budget"}
                register={register}
                type="number"
                filedName={"budget"}
                // must={true}
                errors={errors}
                min={0}
                className={"pl-4 md:text-sm lg:text-base"}
                requiredError={false}
              />
              <div className="flex justify-between h-[2vh] -mt-3 pt-1">
                {errors.budget && (
                  <p className="text-red-500 text-xs leading-none">
                    {errors.budget.message}
                  </p>
                )}

                <p className=" text-[12px] ml-auto">
                  (Remains hidden from Contractor)
                </p>
              </div>
            </div> */}
          </div>

          {/* Project Dates */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-14 mb-3">
            {/* Start Date */}
            <InputBoxLiveDateWithoutProposalDate
              title={"Project Start Date"}
              register={register}
              filedName={"startDate"}
              must={true}
              type="date"
              setValue={setValue}
              min={minStartDate}
              errors={errors}
              className={"md:text-sm lg:text-base"}
              schema={schema}
              setError={setError}
              setMinEndDate={setMinEndDate}
              setSelectDate={setSelectDate}
              otherDependencies={true}
            />
            {/* End Date */}

            <InputBoxLiveDateWithoutProposalDate
              title={"Project End Date"}
              register={register}
              filedName={"endDate"}
              must={true}
              type="date"
              errors={errors}
              min={minEndDate}
              className={"md:text-sm lg:text-base"}
              schema={schema}
              setError={setError}
              disabled={selectDate}
            />
          </div>

          {/* Project Description */}
          <div className="mb-6">
            <label
              htmlFor="projectDescription"
              className="block mb-2 text-secondaryl"
            >
              Project Description
              <span className="text-red-500 text-base">*</span>
            </label>
            <textarea
              id="projectDescription"
              name="projectDescription"
              {...register("projectDescription")}
              className={`bg-[#FFFFFF80] w-full max-h-40 min-h-40 px-2 py-2 h-36 rounded-sm text-black   focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none ${
                errors.projectDescription ? "ring-[0.5px] ring-red-500" : ""
              }`}
              placeholder="Add Project Details Here"
            ></textarea>

            <div className="h-[2vh] pt-1">
              {errors.projectDescription && (
                <p className="text-red-500 text-xs leading-none">
                  {errors.projectDescription.message}
                </p>
              )}
            </div>
          </div>

          {/* Attach Photos */}
          <div className="">
            <div className="mb-2 lg:mb-0">
              {/* Input */}
              <label
                className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base mt-4`}
              >
                Document Upload :
              </label>

              <div>
                <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-2 gap-5  md:gap-16 md:mr-4 lg:mr-20">
                  {/* Business Card File Input */}
                  <div className="col-span-2 sm:col-span-2 md:col-span-1">
                    <input
                      type="file"
                      id="companyContractDocs"
                      name="companyContractDocs"
                      accept=".pdf"
                      className="hidden"
                      onChange={handleChangeContractDocs}
                      disabled={uploadingFile}
                    />

                    {/* Label For style is input tag and use in File input */}
                    <label
                      htmlFor="companyContractDocs"
                      className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none ${
                        uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                      }`}
                      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                    >
                      <div className="flex items-center h-full space-x-1 overflow-hidden">
                        <span>
                          <InputFileIcon />
                        </span>
                        {contractDocs ? (
                          <span className="line-clamp-1">
                            <span>{contractDocs.name}</span>
                          </span>
                        ) : (
                          <span className="text-infromation text-opacity-50   text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                            PDF files only
                          </span>
                        )}
                      </div>
                    </label>
                  </div>
                </div>

                {/* Error */}
                <div className="h-[3vh] mb-3">
                  {uploadingContractDocs && <Spinner />}
                  {contractDocsError && (
                    <p className="text-red-500 text-xs leading-none">
                      {contractDocsError}
                    </p>
                  )}
                  {errors.projectDocs && ( // Error message from yup validation
                    <p className="text-red-500 text-xs leading-none">
                      {errors.projectDocs.message}
                    </p>
                  )}
                  {contractDocsUploded && (
                    <p className="text-infromation text-sm leading-none flex items-center gap-2 mt-1">
                      {/* {contractDocsUploded}  */}
                      <ApprovedIcon /> Attach PDF of Project/Building uploaded
                      Successfully!
                    </p>
                  )}
                </div>
              </div>

              <label
                className={`block font-normal mb-3 text-secondaryl text-[12px] sm:text-[12px] md:text-base mt-4 `}
              >
                Attach Photos of Project/Building :
              </label>

              <div className="flex flex-wrap gap-3 max-w-[720px]">
                {displayContractImages.map((imageSrc, index) =>
                  imageSrc ? (
                    <div key={`contractImage${index}`} className="relative">
                      <img
                        src={imageSrc}
                        alt=""
                        className="object-cover h-40 w-40 rounded-sm "
                      />

                      <Icon
                        onClick={() => handleRemoveImage(index)}
                        icon="icon-park-solid:close-one"
                        className="absolute top-1 right-1 text-primaryl text-2xl hover:text-secondaryl cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div key={`companyContractImages${index}`}>
                      <div className="col-span-2 sm:col-span-2 md:col-span-1">
                        <input
                          type="file"
                          id={`companyContractImages${index}`}
                          name={`companyContractImages${index}`}
                          className="hidden"
                          accept=".jpg,.jpeg,.png"
                          onChange={(e) => handleChangeContractImages(e, index)}
                          disabled={contractImagesUploading}
                          multiple
                        />

                        <label
                          htmlFor={`companyContractImages${index}`}
                          className={`block bg-[#FFFFFF80] h-40 w-40 rounded-sm text-black  px-2  focus:ring-sky-600 focus:outline-none ${
                            contractImagesUploading
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          style={{
                            boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                          }}
                          disabled={contractImagesUploading}
                        >
                          <div className="flex flex-col opacity-50 justify-center items-center h-full space-x-1 overflow-hidden">
                            <InputFileIcon width="40" height="45" />

                            <span className="text-infromation text-center text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                              .jpg, .png, .jpeg files only
                            </span>
                          </div>
                        </label>
                      </div>
                    </div>
                  )
                )}

                {/* Error */}
                <div className="h-[3vh] mb-3">
                  {contractImagesUploading && <Spinner />}

                  {contractImagesError && (
                    <p className="text-red-500 text-xs leading-none">
                      {contractImagesError}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="my-3">
            <button
              type="submit"
              className={`
                h-11 w-fit euclidbold  ring-secondaryl rounded-md flex justify-center text-secondaryl focus:outline-none truncate font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 cursor-pointer euclidbold  text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out  ${
                  uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              disabled={uploadingFile}
            >
              POST
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateContractByResidents;
