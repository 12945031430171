import React, { useState } from "react";
import Cookies from "universal-cookie";
import { toast } from "react-hot-toast";
import { Icon } from "@iconify/react";
import AssignBoardMember from "./AssignBoardMember";
import uimage from "../../../../assets/image/user.png";
import { directApproveProposal } from "../../../../constants/Request";
import { useNavigate } from "react-router-dom";

function DashboardProposalCard({
  proposal,
  viewProposalsHandler,
  proposalapprovalHandler,
  handleCloseModel,
  isModelOpen,
  setLoad,
  load,
  sampleApprovalProposalPdf,
  setSampleApprovalProposalPdf,
}) {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const approvalHandler = async (id) => {
    setLoad(true);
    try {
      if (proposal.CpContract.Is_Request) {
        const res = await directApproveProposal(id);
        // console.log(res);
        if (res.status === 200) {
          toast.success("proposal approved successfully");
        }
        navigate("/corporations/dashboard/home");

        return res;
      }

      if (cookies.get("status") === 2) {
        return toast.error("profile paused");
      } else {
        return proposalapprovalHandler(proposal.Sample_Approval_Proposal_PDF);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <div
      key={proposal.ID}
      className="w-full flex flex-col lg:flex-row  bg-[#FFFFFF] rounded p-3 hover:bg-opacity-50  gap-4 mx-auto my-3 "
      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
    >
      {/* Card Images  */}
      <div className="h-full relative lg:w-[100px] flex-none bg-cover rounded-md text-center flex justify-center my-auto">
        <img
          src={uimage}
          alt={proposal.projectName}
          className="w-[100px] h-[100px] object-fill rounded-full"
        />
      </div>
      {/* Card Content */}
      <div className="flex-col w-full lg:w-[50%] my-auto leading-normal overflow-hidden">
        <div className="space-y-1.5">
          <div className="text-secondaryl euclidbold text-2xl mx flex justify-center lg:justify-start">
            {proposal.Company_Name}
          </div>
          <div className="text-base flex gap-1 items-center justify-center lg:justify-start">
            <Icon
              icon="carbon:location-filled"
              className="text-secondaryl text-lg"
            />
            <p className="text-infromation"> {proposal.Designation}</p>
          </div>
        </div>
      </div>
      {/* Card Buttons */}
      <div className="flex-col justify-center my-auto relative w-auto lg:w-[50%] space-y-3">
        <div
          onClick={() =>
            viewProposalsHandler(proposal.ID, proposal.Status, proposal)
          }
          className="h-11 w-full px-1.5 py-2 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
        >
          VIEW PROPOSAL
        </div>

        {cookies.get("access") === "board member" ? (
          ""
        ) : (
          <button
            disabled={cookies.get("access") === "board member"}
            onClick={() => approvalHandler(proposal.ID)}
            className="h-11 w-full px-1.5 py-2 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
          >
            ACCEPT PROPOSAL
          </button>
        )}
      </div>
      {/* Model */}
      <AssignBoardMember
        isOpen={isModelOpen}
        isRequest={proposal.CpContract.Is_Request}
        onClose={handleCloseModel}
        proposalId={proposal.ID}
        sampleApprovalProposalPdf={sampleApprovalProposalPdf}
        setSampleApprovalProposalPdf={setSampleApprovalProposalPdf}
      />
    </div>
  );
}

export default DashboardProposalCard;
