import React, { useEffect, useState } from "react";
import { ownerAPI, tenantAPI } from "../../../../constants/API";
import { NavLink, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { LogoWithText1 } from "../../../../assets/images";
import { Icon } from "@iconify/react";
import { FaServices } from "react-icons/fa6";
import ServiceIcon from "../../../../assets/images/ServiceIcon.jsx";
import { set } from "date-fns";

function DashboardSidebar() {
  const cookies = new Cookies();

  const [showProjectsSublist, setShowProjectsSublist] = useState(true);
  const [showResidentsSublist, setShowResidentsSublist] = useState(true);
  const [showServicesSublist, setShowServicesSublist] = useState(true);
  const [showDocumentsSublist, setShowDocumentsSublist] = useState(true);
  const [showMomSublist, setShowMomSublist] = useState(true);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (showProjectsSublist) {
      setShowResidentsSublist(false);
      setShowServicesSublist(false);
      setShowDocumentsSublist(false);
      setIsCollapsed(false);
    }
  }, [showProjectsSublist]);

  // Effect to ensure when showResidentsSublist is true, showProjectsSublist becomes false
  useEffect(() => {
    if (showResidentsSublist) {
      setShowProjectsSublist(false);
      setShowServicesSublist(false);
      setShowDocumentsSublist(false);
      setIsCollapsed(false);
    }
  }, [showResidentsSublist]);

  useEffect(() => {
    if (showServicesSublist) {
      setShowProjectsSublist(false);
      setShowResidentsSublist(false);
      setShowDocumentsSublist(false);
      setIsCollapsed(false);
    }
  }, [showServicesSublist]);

  // Effect to manage Documents and MoM sublists together
  useEffect(() => {
    if (showMomSublist) {
      setShowProjectsSublist(false);
      setShowResidentsSublist(false);
      setShowServicesSublist(false);
      setIsCollapsed(false);
      // Ensure that Documents sublist stays open when MoM sublist is open
      setShowDocumentsSublist(true);
    }
  }, [showMomSublist]);

  // Effect to manage Documents sublist without collapsing MoM
  useEffect(() => {
    // Only collapse other sublists if MoM is not active
    if (
      (showDocumentsSublist && !showMomSublist) ||
      (showDocumentsSublist && showMomSublist)
    ) {
      setShowProjectsSublist(false);
      setShowResidentsSublist(false);
      setShowServicesSublist(false);
      setIsCollapsed(false);
    }
  }, [showDocumentsSublist]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsCollapsed(true); // Default collapsed on small screens
      } else {
        setIsCollapsed(false); // Default expanded on larger screens
      }
    };

    handleResize();
  }, []);

  // Handle sublist visibility based on URL location
  useEffect(() => {
    const path = location.pathname;

    // Residents sublist
    if (path.includes("owners") || path.includes("tenant")) {
      setShowResidentsSublist(true);
    } else {
      setShowResidentsSublist(false);
    }

    // Projects sublist
    if (
      path.includes("archivescontract") ||
      path.includes("contract") ||
      path.includes("requestlisting")
    ) {
      setShowProjectsSublist(true);
    } else {
      setShowProjectsSublist(false);
    }

    // Services sublist
    if (path.includes("manageservice") || path.includes("managerequest")) {
      setShowServicesSublist(true);
    } else {
      setShowServicesSublist(false);
    }

    // MoM and Documents sublist
    if (
      path.includes("mom/managemom") ||
      path.includes("mom/managemomrequest")
    ) {
      setShowMomSublist(true);
      setShowDocumentsSublist(true); // Ensure Documents sublist stays open when MoM is open
    } else if (
      path.includes("rules") ||
      path.includes("bylaws") ||
      path.includes("declaration") ||
      path.includes("budget") ||
      path.includes("notices") ||
      path.includes("pic")
    ) {
      setShowMomSublist(false); // MoM shouldn't open for rules and bylaws
      setShowDocumentsSublist(true); // Documents open for rules and bylaws
    } else {
      setShowMomSublist(false);
      setShowDocumentsSublist(false);
    }
  }, [location]);

  // Ensure MoM and Documents are managed together properly
  useEffect(() => {
    if (showMomSublist) {
      if (!showDocumentsSublist) {
        setShowDocumentsSublist(true); // Keep Documents open when MoM is open
      }
    }
  }, [showMomSublist]);

  return (
    <aside
      className={`my-3 ml-3 bg-gradient-to-b  from-[rgba(40,42,115,0.15)] to-[rgba(0,148,191,0.15)]  rounded-2xl transition-all duration-300   ${
        isCollapsed ? "w-14" : "w-72"
      } 
    `}
    >
      <div className="flex justify-center py-2 md:py-0">
        <NavLink to="/corporations/dashboard/overview">
          <img
            src={LogoWithText1} // Replace with your logo
            alt="Condominium Portal"
            className={`${
              isCollapsed ? "h-14 w-14 " : "h-40 w-40 "
            } transition-all duration-300 `}
          />
        </NavLink>
      </div>

      {/* Toggle Button */}
      <div className="flex justify-end px-2 py-1">
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="p-2 bg-primaryl text-white rounded-full cursor-pointer hover:bg-opacity-80"
        >
          {isCollapsed ? (
            <Icon icon="ion:chevron-forward-outline" className="text-xl" />
          ) : (
            <Icon icon="ion:chevron-back-outline" className="text-xl" />
          )}
        </div>
      </div>

      <nav className="mb-2">
        <ul className="space-y-1.5 euclidbold text-primaryl text-lg">
          <NavLink
            to="overview"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mage:dashboard-3-fill" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Dashboard
            </span>
            {/* <span className="hidden lg:inline-block">Dashboard</span> */}
          </NavLink>
          <NavLink
            to="home?page=1"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="typcn:home" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Home
            </span>
            {/* <span className="hidden lg:inline-block">Dashboard</span> */}
          </NavLink>
          <li
            className=""
            onClick={() => {
              setShowProjectsSublist(!showProjectsSublist);
            }}
          >
            <div className=" bg-[#ffffff] bg-opacity-70 hover:bg-primaryl hover:text-white rounded-md flex items-center px-3 py-2 gap-4 cursor-pointer ">
              <Icon
                icon="fluent-emoji-high-contrast:office-building"
                className="text-2xl"
              />
              <span
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                }`}
              >
                Project Listing
              </span>

              <div
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                } ml-auto`}
              >
                {showProjectsSublist ? (
                  <div className="rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                ) : (
                  <div className="-rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                )}
              </div>
            </div>
            {!isCollapsed && showProjectsSublist && (
              <div className="bg-[#ffffff] bg-opacity-50">
                <ul className=" euclidnormal text-base px-1 md:px-3 space-y-1 py-1">
                  {cookies.get("access") === "property manager" && (
                    <NavLink
                      to="contract"
                      className={({ isActive }) =>
                        `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                          isActive
                            ? "bg-primaryl text-white hover:bg-opacity-80"
                            : ""
                        }`
                      }
                    >
                      Create Listing
                    </NavLink>
                  )}

                  <NavLink
                    to="requestlisting?page=1"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Request Listing
                  </NavLink>
                  <NavLink
                    to="archivescontract?page=1"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Archived Project
                  </NavLink>
                </ul>
              </div>
            )}
          </li>

          <li
            className=""
            onClick={() => setShowResidentsSublist(!showResidentsSublist)}
          >
            <div className=" bg-[#ffffff] bg-opacity-70 hover:bg-primaryl hover:text-white rounded-md flex items-center px-3 py-2 gap-4 cursor-pointer ">
              <Icon
                icon="fa6-solid:building-circle-check"
                className="text-2xl"
              />
              <span
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                }`}
              >
                Residents
              </span>
              <div
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                } ml-auto`}
              >
                {showResidentsSublist ? (
                  <div className="rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                ) : (
                  <div className="-rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                )}
              </div>
            </div>
            {!isCollapsed && showResidentsSublist && (
              <div className="bg-[#ffffff] bg-opacity-50">
                <ul className=" euclidnormal text-base px-1 md:px-3 space-y-1 py-1">
                  <NavLink
                    to="owners"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Owners
                  </NavLink>
                  <NavLink
                    to="tenant"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Tenants
                  </NavLink>
                </ul>
              </div>
            )}
          </li>
          <li
            className=""
            onClick={() => {
              setShowServicesSublist(!showServicesSublist);
            }}
          >
            <div className="service-li bg-[#ffffff] bg-opacity-70 hover:bg-primaryl hover:text-white rounded-md flex items-center px-3 py-2 gap-4 cursor-pointer ">
              <Icon icon="mdi:account-service" className="text-2xl " />
              <span
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                }`}
              >
                Services
              </span>

              <div
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                } ml-auto`}
              >
                {showServicesSublist ? (
                  <div className="rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                ) : (
                  <div className="-rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                )}
              </div>
            </div>
            {!isCollapsed && showServicesSublist && (
              <div className="bg-[#ffffff] bg-opacity-50">
                <ul className=" euclidnormal text-base px-1 md:px-3 space-y-1 py-1">
                  {/* {cookies.get("access") === "property manager" && ( */}
                  <NavLink
                    to="manageservice"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Manage Service
                  </NavLink>
                  {/* )} */}

                  <NavLink
                    to="managerequest?page=1"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Manage Request
                  </NavLink>
                </ul>
              </div>
            )}
          </li>

          <li
            className=""
            onClick={() => {
              setShowDocumentsSublist(!showDocumentsSublist);
            }}
          >
            <div className="service-li bg-[#ffffff] bg-opacity-70 hover:bg-primaryl hover:text-white rounded-md flex items-center px-3 py-2 gap-4 cursor-pointer ">
              <Icon icon="solar:documents-bold" className="text-2xl" />
              <span
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                }`}
              >
                Documents
              </span>

              <div
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                } ml-auto`}
              >
                {showDocumentsSublist ? (
                  <div className="rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                ) : (
                  <div className="-rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                )}
              </div>
            </div>
            {!isCollapsed && showDocumentsSublist && (
              <div className="bg-[#ffffff] bg-opacity-50">
                <ul className=" euclidnormal text-base px-1 md:px-3 space-y-1 py-1">
                  {/* {cookies.get("access") === "property manager" && ( */}
                  <NavLink
                    to="rules"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Rules
                  </NavLink>
                  {/* )} */}

                  <NavLink
                    to="bylaws"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Bylaws
                  </NavLink>
                  <NavLink
                    to="declaration"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Declaration
                  </NavLink>
                  <NavLink
                    to="budget"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Budget
                  </NavLink>
                  <NavLink
                    to="pic"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    PIC
                  </NavLink>
                  <NavLink
                    to="notices"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Notices
                  </NavLink>
                  <div
                    className=""
                    onClick={() => {
                      setShowMomSublist(!showMomSublist);
                    }}
                  >
                    <div className="service-li p-1 bg-opacity-70 hover:bg-primaryl hover:text-white rounded-md flex items-center py-2 gap-4 cursor-pointer ">
                      <span
                        className={`transition-all duration-300 ${
                          isCollapsed ? "hidden" : "inline-block"
                        }`}
                      >
                        Minutes Of Meeting
                      </span>

                      <div
                        className={`transition-all duration-300 ${
                          isCollapsed ? "hidden" : "inline-block"
                        } ml-auto`}
                      >
                        {showMomSublist ? (
                          <div className="rotate-90 ">
                            <Icon
                              icon="ion:chevron-back"
                              className="text-2xl "
                            />
                          </div>
                        ) : (
                          <div className="-rotate-90 ">
                            <Icon
                              icon="ion:chevron-back"
                              className="text-2xl "
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {!isCollapsed && showMomSublist && (
                      <div className="bg-[#ffffff] bg-opacity-50">
                        <ul className=" euclidnormal text-base px-1 md:px-3 space-y-1 py-1">
                          {/* {cookies.get("access") === "property manager" && ( */}
                          <NavLink
                            to="mom/managemom"
                            className={({ isActive }) =>
                              `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                                isActive
                                  ? "bg-primaryl text-white hover:bg-opacity-80"
                                  : ""
                              }`
                            }
                          >
                            Manage MoM
                          </NavLink>
                          {/* )} */}

                          <NavLink
                            to="mom/managemomrequest"
                            className={({ isActive }) =>
                              `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                                isActive
                                  ? "bg-primaryl text-white hover:bg-opacity-80"
                                  : ""
                              }`
                            }
                          >
                            Manage MoM Request
                          </NavLink>
                        </ul>
                      </div>
                    )}
                  </div>
                </ul>
              </div>
            )}
          </li>

          <NavLink
            to="complaintsuggestion"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="hugeicons:complaint" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Complaint/Suggestion
            </span>
          </NavLink>

          <NavLink
            to="about"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:about" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              About Us
            </span>
          </NavLink>
          <NavLink
            to="contact"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:contact" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Contact Us
            </span>
          </NavLink>
          <NavLink
            to="message"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="tabler:message-filled" className="text-2xl" />

            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Message
            </span>
          </NavLink>
          <NavLink
            to="blog"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:email-newsletter" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Blog
            </span>
          </NavLink>
        </ul>
      </nav>
    </aside>
  );
}

export default DashboardSidebar;
