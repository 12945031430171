import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format,
  isSameDay,
  isWithinInterval,
  isBefore,
  startOfDay,
  getDay,
  set,
} from "date-fns";
import DropDownStyle1 from "../../components/Elements/DropDownStyle1";
import {
  serviceCorporationApi,
  serviceRequestOwnersAPI,
  serviceRequestTenantAPI,
} from "../../constants/API";
import axios from "axios";
import toast from "react-hot-toast";
import Cookies from "universal-cookie";
import Loading from "../../components/Elements/Loading";
import Clock from "../../components/Resident/RequestService/Clock";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

function RequestService() {
  const [fromDate, setFromDate] = useState("");
  const [visibalCalenderError, setVisibalCalenderError] = useState("");
  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromHour, setFromHour] = useState(0);
  const [toHour, setToHour] = useState(0);
  const [fromPeriod, setFromPeriod] = useState("AM");
  const [toPeriod, setToPeriod] = useState("AM");
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("calendar");
  const [isSelectingFrom, setIsSelectingFrom] = useState(false);
  const cookies = new Cookies();
  const [service, setService] = useState([]);
  const [load, setLoad] = useState(false);
  const [subService, setSubService] = useState([]);
  const [selectService, setSelectService] = useState("");
  const [selectSubService, setSelectSubService] = useState("");
  const [bookedDates, setBookedDates] = useState([]);
  const [selectedDaySlots, setSelectedDaySlots] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [combinedFromDateAndTime, setCombinedFromDateAndTime] = useState("");
  const [combinedToDateAndTime, setCombinedToDateAndTime] = useState("");
  const [IsOwners, setIsOwners] = useState(false);
  const navigate = useNavigate();

  const handleDateChange = (name) => {
    if (name === "from") {
      setCombinedFromDateAndTime(
        `${
          fromDate
            ? `${fromDate}  ${fromHour === 0 ? 12 : fromHour}:00 ${fromPeriod} `
            : ""
        }`
      );
    } else if (name === "to") {
      setCombinedToDateAndTime(
        `${
          toDate
            ? `${toDate}  ${toHour === 0 ? 12 : toHour}:00 ${toPeriod} `
            : ""
        }`
      );
    }
  };

  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];

  const getService = async () => {
    setLoad(true);
    try {
      const response = await axios.get(
        `${serviceCorporationApi}/residentwithrequest`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      // console.log(response?.data?.data);
      setService(response?.data?.data);
    } catch (error) {
      toast.error("Something went wrong. Please try again later");
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getService();
  }, []);

  // useEffect(() => {
  //   const selectedService = service.find(
  //     (s) => s.ID === parseInt(selectService)
  //   );

  //   if (
  //     selectedService &&
  //     selectedService.ServiceRequest &&
  //     !selectedService.SubServiceCorporations
  //   ) {
  //     const booked = selectedService.ServiceRequest.map((request) => ({
  //       from: new Date(request.Book_From),
  //       to: new Date(request.Book_To),
  //     }));
  //     setBookedDates(booked);
  //     // console.log("runclere error funcation");
  //   } else {
  //     setBookedDates([]);
  //   }

  //   if (selectedService && selectedService.SubServiceCorporations) {
  //     setSubService(selectedService.SubServiceCorporations);
  //   } else {
  //     setSubService([]);
  //   }

  //   setSelectedDaySlots([]);
  //   setToDate("");
  //   setFromDate("");
  //   setFromHour(0);
  //   setToHour(0);
  // }, [selectService]);

  // useEffect(() => {
  //   const selectedSubService = subService.find(
  //     (s) => s.ID === parseInt(selectSubService)
  //   );

  //   if (selectedSubService) {
  //     const selectedService = service.find(
  //       (s) => s.ID === parseInt(selectService)
  //     );

  //     if (selectedSubService && selectedService.ServiceRequest) {
  //       const subServiceRequest = selectedService.ServiceRequest.filter(
  //         (request) => {
  //           return request.Sub_Service_ID === selectSubService;
  //         }
  //       );

  //       const booked = subServiceRequest.map((request) => ({
  //         from: new Date(request.Book_From),
  //         to: new Date(request.Book_To),
  //       }));

  //       setBookedDates(booked);
  //     } else {
  //       setBookedDates([]);
  //     }
  //   }

  //   setSelectedDaySlots([]);
  //   setToDate("");
  //   setFromDate("");
  //   setFromHour(0);
  //   setToHour(0);
  // }, [selectSubService]);

  useEffect(() => {
    const selectedService = service.find(
      (s) => s.ID === parseInt(selectService)
    );

    if (
      selectedService &&
      selectedService.ServiceRequest &&
      !selectedService.SubServiceCorporations
    ) {
      const booked = selectedService.ServiceRequest.map((request) => ({
        from: request.Book_From, // Use the string as is from backend
        to: request.Book_To, // Use the string as is from backend
      }));
      setBookedDates(booked);
    } else {
      setBookedDates([]);
    }

    if (selectedService && selectedService.SubServiceCorporations) {
      setSubService(selectedService.SubServiceCorporations);
    } else {
      setSubService([]);
    }

    // Reset other states
    setSelectedDaySlots([]);
    setToDate("");
    setFromDate("");
    setFromHour(0);
    setToHour(0);
  }, [selectService]);

  useEffect(() => {
    const selectedSubService = subService.find(
      (s) => s.ID === parseInt(selectSubService)
    );

    if (selectedSubService) {
      const selectedService = service.find(
        (s) => s.ID === parseInt(selectService)
      );

      if (selectedSubService && selectedService.ServiceRequest) {
        const subServiceRequest = selectedService.ServiceRequest.filter(
          (request) => {
            return request.Sub_Service_ID === selectSubService;
          }
        );

        const booked = subServiceRequest.map((request) => ({
          from: request.Book_From, // Use the string as is from backend
          to: request.Book_To, // Use the string as is from backend
        }));

        setBookedDates(booked);
      } else {
        setBookedDates([]);
      }
    }

    // Reset other states
    setSelectedDaySlots([]);
    setToDate("");
    setFromDate("");
    setFromHour(0);
    setToHour(0);
  }, [selectSubService]);

  const schema = yup.object().shape({
    Service_ID: yup.number().required("Service Selection is required"),
    Sub_Service_ID: yup
      .number()
      .nullable()
      .test(
        "issubservicerequired",
        "Select Sub Service is required",
        function (value) {
          if (subService && subService.length > 0) {
            return value != null && value !== 0;
          }
          return true;
        }
      )
      .default(null),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onRequestService = async (data) => {
    if (!fromDate || !toDate) {
      toast.error("Please select both from and to dates.");
      return;
    }

    const fromDateTime = combineDateAndTime(fromDate, fromHour, fromPeriod);
    const toDateTime = combineDateAndTime(toDate, toHour, toPeriod);

    // console.log("From Date-Time:", fromDateTime);
    // console.log("To Date-Time:", toDateTime);

    if (!fromDateTime || !toDateTime) {
      toast.error("Invalid date-time combination. Please try again.");
      return;
    }

    data.Book_From = fromDateTime;
    data.Book_To = toDateTime;

    // console.log("Request Data with combined Date-Time:", data);

    let apiUrl = "";
    if (cookies.get("companyType") === "owners") {
      setIsOwners(true);
      apiUrl = serviceRequestOwnersAPI;
    } else if (cookies.get("companyType") === "tenant") {
      setIsOwners(false);
      apiUrl = serviceRequestTenantAPI;
    }

    setLoad(true);
    try {
      const response = await axios.post(apiUrl, data, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      toast.success(
        response?.data?.message || "Service Requested successfully"
      );

      cookies.get("companyType") === "tenant"
        ? navigate("/tenant/dashboard/home/details")
        : navigate("/owners/dashboard/home/details");
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Something went wrong. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Returns the date in "yyyy-MM-dd" format
  };

  const normalizeDate = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0); // Set time to midnight
    return newDate;
  };

  useEffect(() => {
    handleBookingSlot();
    if (fromDate && toDate) {
      setActiveTab("clock");
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    // console.log("From Hour has been updated:", fromHour);
    handleDateChange("from");
  }, [fromDate, fromHour, fromPeriod]);

  useEffect(() => {
    handleDateChange("to");
  }, [toDate, toHour, toPeriod]);

  const handleBookingSlot = () => {
    const bookedSlots = [];
    const selectedService = service.find((s) => s.ID === selectService);

    if (selectService && selectSubService) {
      const subServiceRequest = selectedService.ServiceRequest.filter(
        (request) => request.Sub_Service_ID === selectSubService
      );

      subServiceRequest.forEach((s) => {
        const bookFromDate = new Date(s.Book_From);
        const bookToDate = new Date(s.Book_To);

        // Convert 'fromDate' and 'toDate' to Date objects
        const selectedFromDate = new Date(fromDate);
        const selectedToDate = toDate ? new Date(toDate) : null;

        const normalizedBookFromDate = normalizeDate(bookFromDate);
        const normalizedBookToDate = normalizeDate(bookToDate);
        const normalizedSelectedFromDate = normalizeDate(selectedFromDate);
        const normalizedSelectedToDate = selectedToDate
          ? normalizeDate(selectedToDate)
          : null;

        // If both 'from' and 'to' dates are selected, check if the booking falls within that range
        if (normalizedSelectedFromDate && normalizedSelectedToDate) {
          const isWithinRange =
            normalizedBookFromDate >= normalizedSelectedFromDate &&
            normalizedBookToDate <= normalizedSelectedToDate;

          if (isWithinRange) {
            // If the booking is within the selected range, store the details
            bookedSlots.push({
              from: bookFromDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              to: bookToDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              status: "booked", // Set the status to "booked"
              fromDate: bookFromDate.toLocaleDateString(),
              toDate: bookToDate.toLocaleDateString(),
            });
          }
        }
        // If only 'from' date is selected, check if the booking starts from that date
        else if (normalizedSelectedFromDate && !normalizedSelectedToDate) {
          const isStartingFrom =
            normalizedBookFromDate.toDateString() ===
            normalizedSelectedFromDate.toDateString();

          if (isStartingFrom) {
            // If the booking starts from the selected 'from' date, store the details
            bookedSlots.push({
              from: bookFromDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              to: bookToDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              status: "booked", // Set the status to "booked"
              fromDate: bookFromDate.toLocaleDateString(),
              toDate: bookToDate.toLocaleDateString(),
            });
          }
        }
      });
    } else if (selectService && !selectSubService) {
      selectedService?.ServiceRequest.forEach((s) => {
        // Convert 'Book_From' and 'Book_To' to Date objects
        const bookFromDate = new Date(s.Book_From);
        const bookToDate = new Date(s.Book_To);

        // Convert 'fromDate' and 'toDate' to Date objects
        const selectedFromDate = new Date(fromDate);
        const selectedToDate = toDate ? new Date(toDate) : null;

        const normalizedBookFromDate = normalizeDate(bookFromDate);
        const normalizedBookToDate = normalizeDate(bookToDate);
        const normalizedSelectedFromDate = normalizeDate(selectedFromDate);
        const normalizedSelectedToDate = selectedToDate
          ? normalizeDate(selectedToDate)
          : null;

        // If both 'from' and 'to' dates are selected, check if the booking falls within that range
        if (normalizedSelectedFromDate && normalizedSelectedToDate) {
          const isWithinRange =
            normalizedBookFromDate >= normalizedSelectedFromDate &&
            normalizedBookToDate <= normalizedSelectedToDate;

          if (isWithinRange) {
            // If the booking is within the selected range, store the details
            bookedSlots.push({
              from: bookFromDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              to: bookToDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              status: "booked", // Set the status to "booked"
              fromDate: bookFromDate.toLocaleDateString(),
              toDate: bookToDate.toLocaleDateString(),
            });
          }
        }
        // If only 'from' date is selected, check if the booking starts from that date
        else if (normalizedSelectedFromDate && !normalizedSelectedToDate) {
          const isStartingFrom =
            normalizedBookFromDate.toDateString() ===
            normalizedSelectedFromDate.toDateString();

          if (isStartingFrom) {
            // If the booking starts from the selected 'from' date, store the details
            bookedSlots.push({
              from: bookFromDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              to: bookToDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              status: "booked", // Set the status to "booked"
              fromDate: bookFromDate.toLocaleDateString(),
              toDate: bookToDate.toLocaleDateString(),
            });
          }
        }
      });
    }

    setSelectedDaySlots(bookedSlots);
    if (bookedSlots.length > 0) {
      setActiveTab("clock");
    }
  };

  const handleCalendarChange = (day) => {
    console.log("Day Selected:", day);

    // Format the selected date as a string (no timezone manipulation)
    const formattedDate = formatDateToYYYYMMDD(day);
    console.log("Formatted Date:", formattedDate);

    if (isSelectingFrom) {
      // If currently selecting the 'from' date
      const currentToDate = toDate; // Use the string value of the 'to' date

      // Set the 'from' date
      setFromDate(formattedDate);
      setIsSelectingFrom(false); // Switch to selecting 'to' date

      // Check if the newly selected 'from' date is greater than or equal to the 'to' date
      if (formattedDate > currentToDate) {
        // Swap the dates if 'from' is greater than or equal to 'to'
        setToDate(formattedDate);
        setFromDate(currentToDate);
      }
    } else {
      // If currently selecting the 'to' date
      const currentFromDate = fromDate; // Use the string value of the 'from' date
      const selectedToDate = formattedDate;

      // Check if the selected 'to' date is before the 'from' date
      if (selectedToDate < currentFromDate) {
        // If the 'to' date is before 'from', set 'from' date to 'to' date
        setFromDate(selectedToDate);
        setToDate(currentFromDate); // Adjust the 'to' date
      } else {
        setToDate(selectedToDate); // Otherwise, set 'to' date to the selected date
      }

      setIsSelectingFrom(true); // Switch back to selecting 'from' date
    }
  };

  // const handleCalendarChange = (day) => {
  //   // console.log("Day Selected:", day);

  //   const date = new Date(day);
  //   const formattedDate = formatDateToYYYYMMDD(date); // Format the date

  //   // console.log("Formatted Date:", formattedDate);

  //   if (isSelectingFrom) {
  //     // If currently selecting the 'from' date
  //     const currentToDate = new Date(toDate);
  //     // console.log("Current To Date:", currentToDate);

  //     // Set the 'from' date
  //     setFromDate(formattedDate);
  //     setIsSelectingFrom(false); // Switch to selecting 'to' date

  //     // Check if the newly selected 'from' date is greater than or equal to the 'to' date
  //     if (new Date(formattedDate) > currentToDate) {
  //       // Swap the dates if 'from' is greater than or equal to 'to'
  //       setToDate(formattedDate);
  //       setFromDate(formatDateToYYYYMMDD(currentToDate));
  //     }
  //   } else {
  //     // If currently selecting the 'to' date
  //     const currentFromDate = new Date(fromDate);
  //     const selectedToDate = new Date(formattedDate);

  //     // Check if the selected 'to' date is before the 'from' date
  //     if (selectedToDate < currentFromDate) {
  //       // If the 'to' date is before 'from', set 'from' date to 'to' date
  //       setFromDate(formattedDate);
  //       setToDate(formatDateToYYYYMMDD(currentFromDate)); // Also adjust the 'to' date
  //     } else {
  //       setToDate(formattedDate); // Otherwise, set 'to' date to the selected date
  //     }

  //     setIsSelectingFrom(true); // Switch back to selecting 'from' date
  //   }
  // };

  const combineDateAndTime = (date, hour, period) => {
    // console.log("date " + date);

    if (!date) {
      console.error("Invalid date provided");
      return null;
    }

    // Create a new Date object based on the provided date
    // let selectedDate = new Date(date);

    const [year, month, day] = date.split("-").map(Number);
    let hours = parseInt(hour, 10);

    // let hours = parseInt(hour, 10);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    const selectedDate = new Date(year, month - 1, day, hours, 0, 0, 0);
    // console.log("selectedDate " + selectedDate);

    // Manually format the date to "yyyy-MM-dd HH:mm:ss.SSS"
    const formattedYear = selectedDate.getFullYear();
    const formattedMonth = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(selectedDate.getDate()).padStart(2, "0");
    const formattedHours = String(selectedDate.getHours()).padStart(2, "0");
    const minutes = String(selectedDate.getMinutes()).padStart(2, "0");
    const seconds = String(selectedDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(selectedDate.getMilliseconds()).padStart(
      3,
      "0"
    );

    // Return the formatted date string in the desired format
    return `${formattedYear}-${formattedMonth}-${formattedDay} ${formattedHours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const daysInMonth = eachDayOfInterval({
    start: startOfMonth(currentMonth),
    end: endOfMonth(currentMonth),
  });

  const handlePrevMonth = () =>
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() - 1))
    );

  const handleNextMonth = () =>
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() + 1))
    );

  const isDateBooked = (day) =>
    bookedDates.some((slot) =>
      isWithinInterval(day, { start: slot.from, end: slot.to })
    );

  const formateDisplaySelectedDate = (dateString) => {
    // console.log("formateDisplaySelectedDate : ", dateString);

    const [year, month, day] = dateString.split("-");
    const date = new Date(year, month - 1, day); // month is 0-based
    return date.toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleCalenderVisible = () => {
    if (calendarVisible) {
      setCalendarVisible(!calendarVisible);
    } else {
      if (selectService) {
        // console.log("selectService", selectService);
        const selectServiceData = service.find(
          (s) => s.ID === parseInt(selectService)
        );

        if (
          selectService &&
          selectServiceData?.SubServiceCorporations?.length > 0
        ) {
          // console.log("selectSubService", selectSubService);

          if (selectSubService) {
            // console.log("running");

            setCalendarVisible(true);
          } else {
            setError("Sub_Service_ID", {
              type: "manual",
              message:
                "Please Select Sub Service first After that you can select date",
            });
          }
        } else {
          // console.log("Else running");

          setCalendarVisible(true);
        }
      } else {
        setError("Service_ID", {
          type: "manual",
          message: "Please Select Service first After that you can select date",
        });
      }
    }
  };

  const today = startOfDay(new Date()); // Get today's date at 00:00 to block the entire day

  // console.log(errors);

  return (
    <div className="rounded-2xl bg-white ">
      <div className="w-full  m-auto  flex flex-col justify-center ">
        <div className="w-ful h-full">
          <Loading load={load} />
        </div>
        <form
          onSubmit={handleSubmit(onRequestService)}
          className=""
          encType="multipart/form-data"
        >
          <div className="w-full flex gap-4 min-h-[81vh]">
            <div className="w-full rounded-2xl px-4 p-6 bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15">
              <h1 className="text-secondaryl font-extrabold text-3xl euclidbold -2">
                Request Service
              </h1>

              <div className="w-full mt-8">
                <div
                  className={`grid grid-cols-1 gap-6  ${
                    calendarVisible && selectService
                      ? "lg:grid-cols-1 lg:gap-6"
                      : "lg:grid-cols-2 lg:gap-14"
                  }    mb-3 w-full md:w-[90%] lg:w-[80%]`}
                >
                  <div className="">
                    <label
                      htmlFor={"Service_Name"}
                      className="block font-normal text-secondaryl"
                    >
                      <p>
                        Select Service
                        <span className="text-red-500 text-base ml-1">*</span>
                      </p>
                    </label>

                    <DropDownStyle1
                      title="Select Project Type"
                      options={service}
                      onSelect={setSelectService}
                      selectOption={selectService}
                      setValue={setValue}
                      filedName={"Service_ID"}
                      errors={errors}
                      dbfield={"Service_Name"}
                      clearErrors={clearErrors}
                    />
                  </div>

                  {subService.length > 0 && (
                    <div className="">
                      <label
                        htmlFor={"Sub_Service_Name"}
                        className="block font-normal text-secondaryl"
                      >
                        <p>
                          Select Sub Service
                          <span className="text-red-500 text-base ml-1">*</span>
                        </p>
                      </label>

                      <DropDownStyle1
                        title="Select Sub Project Type"
                        options={subService}
                        onSelect={setSelectSubService}
                        selectOption={selectSubService}
                        setValue={setValue}
                        filedName={"Sub_Service_ID"}
                        errors={errors}
                        dbfield={"Sub_Service_Name"}
                        clearErrors={clearErrors}
                      />
                    </div>
                  )}
                </div>

                <label className="block font-normal w-fit text-secondaryl mb-2">
                  Available
                </label>
                <div
                  className={`grid grid-cols-1  ${
                    calendarVisible && selectService
                      ? "lg:grid-cols-1 lg:gap-6"
                      : "lg:grid-cols-2 lg:gap-14"
                  }  gap-6  mb-3 w-full md:w-[90%] lg:w-[80%]`}
                >
                  <div className="w-full" onClick={handleCalenderVisible}>
                    <label htmlFor="Book_From" className="text-secondaryl pr-2">
                      From
                    </label>
                    <input
                      type="text"
                      id="Book_From"
                      name="Book_From"
                      className="md:text-sm w-[80%]  bg-gray-100 p-2 lg:text-base outline-none border-none"
                      placeholder="Select date and time"
                      value={combinedFromDateAndTime}
                      readOnly
                    />
                  </div>

                  <div className="w-full" onClick={handleCalenderVisible}>
                    <label htmlFor="Book_To" className="text-secondaryl pr-2">
                      To
                    </label>
                    <input
                      type="text"
                      id="Book_To"
                      value={combinedToDateAndTime}
                      name="Book_To"
                      placeholder="Select date and time"
                      readOnly
                      className="md:text-sm w-[80%] bg-gray-100 p-2 lg:text-base outline-none border-none"
                    />
                  </div>
                </div>

                <div className="mt-5">
                  <button
                    type="submit"
                    className={`h-11 w-fit euclidbold ring-secondaryl rounded-md flex justify-center text-secondaryl truncate font-bold px-6 py-2.5 items-center ring-1 cursor-pointer euclidbold  text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out`}
                  >
                    Submit Request
                  </button>
                </div>
              </div>
            </div>

            {/* Calendar and time selection section */}
            {calendarVisible && selectService && (
              <div className="relative w-full overflow-hidden flex flex-col  rounded-2xl px-4 p-6 bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15">
                <div className="flex w-full ring-1 ring-secondaryl rounded-md  mb-4  font-medium">
                  <div
                    className={`px-4 py-2 w-full  text-center rounded-l-md outline-none border-none cursor-pointer ${
                      activeTab === "calendar"
                        ? "bg-secondaryl text-white"
                        : "text-secondaryl"
                    }`}
                    onClick={() => setActiveTab("calendar")}
                  >
                    CALENDER
                  </div>

                  <div
                    className={`px-4 py-2 w-full   rounded-r-md   text-center outline-none border-none cursor-pointer ${
                      activeTab === "clock"
                        ? "bg-secondaryl text-white"
                        : "text-secondaryl"
                    }`}
                    onClick={() => setActiveTab("clock")}
                  >
                    TIME
                  </div>
                </div>
                {activeTab === "calendar" ? (
                  <div className="w-full md:w-[90%] lg:w-[80%] mx-auto">
                    <div className="euclidbold w-full text-center text-secondaryl h-4">
                      <p>
                        {fromDate ? (
                          <span>{formateDisplaySelectedDate(fromDate)}</span>
                        ) : (
                          ""
                        )}

                        {toDate
                          ? " - " + formateDisplaySelectedDate(toDate)
                          : ""}
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full my-4">
                      <div
                        onClick={handlePrevMonth}
                        className="text-secondaryl cursor-pointer"
                      >
                        <Icon
                          icon="icon-park-outline:left"
                          className="text-2xl"
                        />
                      </div>
                      <h3 className="text-lg text-secondaryl euclidbold">
                        {format(currentMonth, "MMMM yyyy")}
                      </h3>
                      <div
                        onClick={handleNextMonth}
                        className="text-secondaryl cursor-pointer"
                      >
                        <Icon
                          icon="icon-park-outline:right"
                          className="text-2xl"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-7 gap-1 w-full">
                      {weekdays.map((weekday, index) => (
                        <div
                          key={`${weekday}-${index}`}
                          className="text-secondaryl font-bold w-full text-center"
                        >
                          {weekday}
                        </div>
                      ))}

                      {Array.from({
                        length: getDay(startOfMonth(daysInMonth[0])),
                      }).map((_, index) => (
                        <div key={`empty-${index}`} className="w-full"></div>
                      ))}

                      {daysInMonth.map((day) => {
                        const startOfDayDate = startOfDay(day); // Start of the day for consistent comparison

                        const isBeforeToday = isBefore(startOfDayDate, today); // Block today and all previous dates
                        // const isBeforeFromDate =
                        //   fromDate &&
                        //   isBefore(
                        //     startOfDayDate,
                        //     startOfDay(new Date(fromDate))
                        // ); // Block dates before fromDate if selected
                        const isBlockedDate = isBeforeToday; // Combine both conditions for blocking

                        // const isSelected =
                        //   isSameDay(day, new Date(fromDate)) ||
                        //   isSameDay(day, new Date(toDate));
                        const isSelected =
                          new Date(day).toISOString().slice(0, 10) ===
                            fromDate ||
                          new Date(day).toISOString().slice(0, 10) === toDate;

                        // console.log(
                        //   isSelected,
                        //   " from : ",
                        //   fromDate,
                        //   " and ",
                        //   day
                        // );
                        // console.log("to : ", toDate);

                        return (
                          //   <div
                          //     key={day.toISOString()}
                          //     className={`border cursor-pointer w-full text-center  ${
                          //       isSameDay(day, new Date(fromDate)) ||
                          //       isSameDay(day, new Date(toDate))
                          //         ? "bg-secondaryl text-white rounded-sm "
                          //         : isDateBooked(day)
                          //         ? "bg-red-300 text-white"
                          //         : " text-secondaryl"
                          //     }`}
                          //     onClick={() => handleCalendarChange(day)}
                          //   >
                          //     {format(day, "d")}
                          //   </div>
                          <div
                            key={day.toISOString()}
                            className={` w-full text-center ${
                              isBlockedDate
                                ? "cursor-not-allowed opacity-80 text-gray-400" // Blocked dates styling
                                : "cursor-pointer"
                            } ${
                              isSelected
                                ? "bg-secondaryl text-white rounded-sm"
                                : isDateBooked(day)
                                ? "bg-red-300 text-white"
                                : "text-secondaryl"
                            }`}
                            onClick={() =>
                              !isBlockedDate && handleCalendarChange(day)
                            } // Disable onClick for blocked dates
                          >
                            {format(day, "d")}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <Clock
                    fromHour={fromHour}
                    toHour={toHour}
                    setFromHour={setFromHour}
                    setToHour={setToHour}
                    setFromPeriod={setFromPeriod}
                    setToPeriod={setToPeriod}
                    fromPeriod={fromPeriod}
                    toPeriod={toPeriod}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                )}

                {selectedDaySlots.length > 0 && (
                  <div className="w-full h-52 overflow-y-auto scrollbar-color-secondaryl space-y-2 mx-auto mt-3 bg-white p-3 ">
                    {selectedDaySlots.map((slot, index) => (
                      <div
                        key={index}
                        className="flex flex-col md:flex-row w-full  rounded-md items-center gap-4"
                      >
                        <div className="bg-[#3E5CAA1A] w-fit h-fit p-2.5  rounded-full flex justify-center items-center">
                          <Icon
                            icon="uis:calender"
                            className="text-3xl text-secondaryl"
                          />
                        </div>
                        <div className="w-full text-sm space-y-1">
                          <p className="text-secondaryl euclidbold">
                            {/* {formateDisplaySelectedDate(slot.fromDate) +
                              " - " +
                              formateDisplaySelectedDate(slot.toDate)} */}

                            {slot.fromDate + " - " + slot.toDate}
                          </p>

                          <p className="flex text-secondaryl">
                            {slot.from + " To " + slot.to}
                          </p>
                        </div>
                        <span className="w-fit bg-[#0095C01A] px-4 py-1.5 rounded-md text-primaryl font-semibold">
                          Booked
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default RequestService;
