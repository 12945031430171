import * as yup from "yup";

const serviceSchema = yup.object().shape({
  Service_Name: yup.string().required(),
  Service_Description: yup.string().required(),
  Service_Image: yup.string(),
  Is_Sub_Service: yup.boolean(),
  Sub_Services: yup.array().of(
    yup.object().shape({
      Sub_Service_Name: yup.string().required(),
      Sub_Service_Description: yup.string().required(),
    })
  ),
});

//  1 = Rules, 2 = Bylaws, 3 = Declaration, 4 = Budget
const documentSchema = yup.object().shape({
  Section: yup.number(),
  Document_Name: yup.string().required(),
  Document_URL: yup.string().required(),
  IsActive: yup.boolean(),
});

//  1 = Rules, 2 = Bylaws, 3 = Declaration, 4 = Budget
const noticeSchema = yup.object().shape({
  Notice_Title: yup.string().required(),
  Notice_Description: yup.string().required(),
  Notice_Document: yup.string().required(),
  Notice_Start_Date: yup.string().required(),
  Notice_End_Date: yup.string().required(),
  Sharing_Status: yup.string().required(),
});

const momSchema = yup.object().shape({
  Meeting_Topic: yup.string().required("MoM Topic is required"),
  Meeting_Details: yup.string().required("MoM Summary is required"),
  Meeting_Outcome: yup.string().required("MoM Outcome is required"),
  Meeting_Document: yup.string().required("MoM Docs is required"),
  Sharing_Status: yup.number().required("MoM Who Can Show is required"),
  Meeting_Date: yup.string().required("MoM Date is required"),
});

const picSchema = yup.object().shape({
  PIC_Name: yup
    .string()
    .required("Pubic Information Certificate Name is required"),
  PIC_URL: yup
    .string()
    .required("Public Information Certificate Document is required"),
  IsActive: yup.boolean(),
});

const complaintSuggestionSchema = yup.object().shape({
  Description: yup
    .string()
    .required("Complaint/Suggestion Description is required"),
  Comment: yup.string(),
  Status: yup.boolean(),
});

export {
  serviceSchema,
  documentSchema,
  momSchema,
  noticeSchema,
  picSchema,
  complaintSuggestionSchema,
};
