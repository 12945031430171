import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { picSchema } from "../../../schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Cookies from "universal-cookie";
import ReadTextArea from "../../Elements/ReadTextArea";
import {
  ownersComplaintSuggestionAPI,
  tenantComplaintSuggestionAPI,
} from "../../../constants/API";
import axios from "axios";
import toast from "react-hot-toast";
import Loading from "../../Elements/Loading";

const ComplaintSuggestionReadModal = ({
  data,
  handleView,
  fetchData,
  isViewModal,
}) => {
  const {
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(picSchema),
  });
  const cookies = new Cookies();
  const [load, setLoad] = useState(false);
  const [residentData, setResidentData] = useState({});

  const assignData = async () => {
    if (data) {
      reset(data);
      if (cookies.get("companyType") === "corporation") {
        if (data.Owners_ID) {
          setResidentData(data.Owner);
        } else {
          setResidentData(data.Tenant);
        }
      }
    } else {
      reset();
    }
  };

  const handleAddComment = async (e, id, isOwner) => {
    e.preventDefault();
    try {
      setLoad(true);
      const api = isOwner
        ? `${ownersComplaintSuggestionAPI}/${id}`
        : `${tenantComplaintSuggestionAPI}/${id}`;
      const response = await axios.put(
        api,
        { Comment: getValues("Comment"), Status: getValues("Status") },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      fetchData();
      // console.log(response.data);
      toast.success(`Updated successfully`);
    } catch (error) {
      // console.log(error);
      toast.error(`Something went wrong. Please try again later`);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    assignData();
  }, [data, reset]);

  return (
    <>
      <div
        className={`fixed inset-0 z-30 overflow-y-auto pt-32 bg-black bg-opacity-30`}
      >
        <Loading load={load} />
        <div className="flex  justify-center items-center  bg-transparent">
          <div className="bg-white rounded-2xl w-[80%] overflow-auto ">
            <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 ">
              <div className="flex justify-between items-center mb-3">
                <h2 className="text-secondaryl font-extrabold text-2xl euclidbold">
                  Details Of Complaint/Suggestion
                </h2>
                <button
                  onClick={() => handleView(false)}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  <Icon
                    icon="carbon:close-filled"
                    className="text-secondaryl hover:text-opacity-95 text-3xl"
                  />
                </button>
              </div>

              <form className="mb-3">
                {cookies.get("companyType") === "corporation" && (
                  <div className="mt-4">
                    <h2 className="text-secondaryl font-extrabold euclidbold  items-center justify-start  mb-3 underline  gap-2  flex">
                      <Icon
                        icon="material-symbols:person"
                        className="text-2xl"
                      />
                      Resident Details :
                    </h2>

                    <div className="space-y-1">
                      <div>
                        {" "}
                        <label htmlFor="" className="text-secondaryl">
                          Resident Type :
                        </label>{" "}
                        <span className="text-infromation">
                          {" "}
                          {data.Owners_ID ? "Owners" : "Tenant"}
                        </span>
                      </div>

                      <div>
                        {" "}
                        <label htmlFor="" className="text-secondaryl">
                          Resident Name :{" "}
                        </label>{" "}
                        <span className="text-infromation">
                          {" "}
                          {`${residentData?.First_Name}  ${residentData?.Last_Name}`}
                        </span>
                      </div>

                      <div>
                        {" "}
                        <label htmlFor="" className="text-secondaryl">
                          Resident Address :{" "}
                        </label>{" "}
                        <span className="text-infromation">
                          {" "}
                          {residentData?.Residential_Address}{" "}
                        </span>
                      </div>

                      <div>
                        {" "}
                        <label htmlFor="" className="text-secondaryl">
                          Resident Contact No :{" "}
                        </label>{" "}
                        <span className="text-infromation">
                          {" "}
                          {residentData?.Phone_No}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mb-3 mt-3">
                  <ReadTextArea
                    title={`Complaint/Suggestion`}
                    register={register}
                    filedName={"Description"}
                    errors={errors}
                    className={"md:text-sm lg:text-base"}
                    cursorNotAllowed={false}
                  />
                </div>
                {isViewModal ? (
                  data.Comment ? (
                    <div className="mb-3 mt-3">
                      <ReadTextArea
                        title={`Comment`}
                        register={register}
                        filedName={"Comment"}
                        errors={errors}
                        className={"md:text-sm lg:text-base"}
                        cursorNotAllowed={false}
                      />
                    </div>
                  ) : (
                    <div className="text-secondaryl pb-3">
                      {" "}
                      Not Comment Yet{" "}
                    </div>
                  )
                ) : (
                  cookies.get("access") === "property manager" && (
                    <>
                      {data.Status === 0 && (
                        <div className=" mb-3">
                          <label
                            htmlFor="Status"
                            className={`block font-normal text-secondaryl`}
                          >
                            Status
                          </label>
                          <div className="flex justify-start gap-3">
                            <div
                              className={`font-normal text-secondaryl flex justify-start items-center gap-1`}
                            >
                              <input
                                type="radio"
                                id="Approve"
                                name="Approve"
                                value={1}
                                {...register("Status")}
                                className={`bg-[#FFFFFF80] focus:outline-none px-2 ps-10 focus:border-rose-600 ${
                                  errors.Status ? "ring-1 ring-red-500" : ""
                                }`}
                              />
                              <label htmlFor="Approve" className="mb-0">
                                Approve
                              </label>
                            </div>
                            <div
                              className={`font-normal text-secondaryl flex justify-start items-center gap-1`}
                            >
                              <input
                                type="radio"
                                id="reject"
                                name="reject"
                                value={2}
                                {...register("Status")}
                                className={`bg-[#FFFFFF80]  focus:outline-none px-2 ps-10 focus:border-rose-600 ${
                                  errors.Status ? "ring-1 ring-red-500" : ""
                                }`}
                              />
                              <label htmlFor="reject" className="mb-0">
                                Reject
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className=" mb-3">
                        {/* Label */}
                        <label
                          htmlFor="Comment"
                          className={`block font-normal text-secondaryl`}
                        >
                          Comment
                        </label>
                        {/* Input */}
                        <textarea
                          type="text"
                          id="Comment"
                          name="Comment"
                          {...register("Comment")}
                          className={`bg-[#FFFFFF80] focus:ring-[0.5px] focus:ring-secondaryl focus:outline-none block h-40 max-h-40 min-h-40 w-full rounded-sm text-black px-2 ps-10 focus:border-rose-600 ${
                            errors.Comment ? "ring-1 ring-red-500" : ""
                          }`}
                          placeholder="Comment"
                        />

                        {/* Error */}
                        <div className="h-[2vh] pt-1">
                          {errors.Comment && (
                            <p className="text-red-500 text-xs  leading-none">
                              {errors.Comment.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )
                )}

                <div className="pb-3">
                  {data.Status === 1 && (
                    <div className="text-sm flex gap-1 items-center text-[#00A701]">
                      <Icon icon="duo-icons:approved" className=" text-lg" />
                      <p className="">Approved</p>
                    </div>
                  )}

                  {data.Status === 2 && (
                    <div className="text-sm flex gap-1 items-center text-[#EB3333]">
                      <Icon icon="tabler:cancel" className=" text-lg" />
                      <p className="">Reject</p>
                    </div>
                  )}

                  {data.Status === 0 && (
                    <div className="text-sm flex gap-1 items-center text-[#FFA640]">
                      <Icon icon="lsicon:minus-filled" className=" text-lg" />
                      <p className="">Pending</p>
                    </div>
                  )}
                </div>

                <div className="flex flex-wrap gap-5 justify-between ">
                  <button
                    className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                    onClick={(e) => handleView(false)}
                  >
                    Close
                  </button>

                  {!isViewModal && (
                    <button
                      className="
          h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out  "
                      onClick={(e) =>
                        handleAddComment(
                          e,
                          data.ID,
                          data.Owners_ID ? true : false
                        )
                      }
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintSuggestionReadModal;
