import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReadInputBox from "../../Elements/ReadInputBox";
import ReadTextArea from "../../Elements/ReadTextArea";
import Loading from "../../Elements/Loading";
import { viewFileHandler } from "../../../constants/Request";

function ContractDetailsModal({ contract, onClose }) {
  const [modelLoading, setModelLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [projectImages, setProjectImages] = useState([]);
  const [meetingDocumentType, setMeetingDocumentType] = useState(null);
  const [meetingDocument, setMeetingDocument] = useState(null);

  const schema = yup.object().shape({
    Project_Name: yup.string().required("Project Name is required"),
    Project_Location: yup.string().required("Project Location is required"),
    Budget: yup.number().required("Budget is required"),
    Start_Date: yup.string().required("Start Date is required"),
    End_Date: yup.string().required("End Date is required"),
    Project_Description: yup.string().required("Description is required"),
  });

  const { register, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const assignContractData = async () => {
    reset(contract);

    // Check if the document is a PDF or an image
    if (contract.Project_Photos?.endsWith(".pdf")) {
      const fileUrl = await viewFileHandler(
        contract.Project_Photos,
        "contracts"
      );
      setMeetingDocumentType("pdf");
      setMeetingDocument(fileUrl);
    } else {
      setMeetingDocumentType("image");
      setMeetingDocument(contract.Project_Photos);
    }

    // Collect all image URLs into a list (ignoring null values)
    const images = [
      contract.Project_Photos_1,
      contract.Project_Photos_2,
      contract.Project_Photos_3,
      contract.Project_Photos_4,
      contract.Project_Photos_5,
      contract.Project_Photos_6,
      contract.Project_Photos_7,
      contract.Project_Photos_8,
      contract.Project_Photos_9,
      contract.Project_Photos_10,
    ].filter(Boolean); // Filter out null/undefined values
    setProjectImages(images);
  };

  useEffect(() => {
    setModelLoading(true);
    assignContractData();
    setModelLoading(false);
  }, [contract]);

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-30`}
    >
      <Loading load={modelLoading} />
      <div className="flex justify-center items-center h-full bg-transparent">
        <div className="bg-white rounded-2xl w-[80%] h-[80%] overflow-auto">
          <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 min-h-full p-8">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-secondaryl font-extrabold text-3xl euclidbold">
                {contract.Project_Name}
              </h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                ✖
              </button>
            </div>

            {/* Contract Details Form */}
            <div className="my-3">
              {/* Project Name */}
              <div className="mb-3">
                <ReadInputBox
                  title={"Project Name"}
                  register={register}
                  filedName={"Project_Name"}
                  cursorNotAllowed={true}
                />
              </div>

              {/* Project Location */}
              <div className="mb-3">
                <ReadInputBox
                  title={"Project Location"}
                  register={register}
                  filedName={"Project_Location"}
                  cursorNotAllowed={true}
                />
              </div>

              {/* Budget */}
              {contract.Request_Status === 1 && (
                <div className="mb-3">
                  <ReadInputBox
                    title={"Budget"}
                    register={register}
                    filedName={"Budget"}
                    cursorNotAllowed={true}
                  />
                </div>
              )}

              {/* Start and End Dates */}
              <div className="grid grid-cols-2 gap-4 mb-3">
                <div>
                  <ReadInputBox
                    title={"Start Date"}
                    register={register}
                    filedName={"Start_Date"}
                    cursorNotAllowed={true}
                  />
                </div>
                <div>
                  <ReadInputBox
                    title={"End Date"}
                    register={register}
                    filedName={"End_Date"}
                    cursorNotAllowed={true}
                  />
                </div>
              </div>

              {/* (if approval then proposal deadline post sure then display otherwice not ) */}
              {contract.Request_Status === 1 && (
                <div className="mb-3">
                  <ReadInputBox
                    title={"Proposal Deadline"}
                    register={register}
                    filedName={"Proposal_Deadline"}
                    cursorNotAllowed={true}
                  />
                </div>
              )}

              {/* Project Description */}
              <div className="mb-3">
                <ReadTextArea
                  title={"Project Description"}
                  register={register}
                  filedName={"Project_Description"}
                  cursorNotAllowed={true}
                />
              </div>

              {/* PDF Document (if any) */}
              {meetingDocumentType === "pdf" && (
                <div className="mb-3">
                  <label className="block font-normal text-secondaryl text-base">
                    Project Document (PDF):
                  </label>
                  <object
                    data={`${meetingDocument}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                    className="w-full h-[90vh] cursor-not-allowed user-select-none"
                  >
                    <p>PDF could not be displayed</p>
                  </object>
                </div>
              )}

              {/* Project Image (if any) */}
              {meetingDocumentType === "image" && meetingDocument && (
                <div className="mb-3">
                  <label className="block font-normal text-secondaryl text-base">
                    Project Document (Image):
                  </label>
                  <img
                    src={meetingDocument}
                    alt="Project Document"
                    className="w-full h-[50vh] object-fill ring-[0.1px] ring-information"
                  />
                </div>
              )}

              {/* Additional Project Images */}
              {projectImages.length > 0 && (
                <div className="mb-3">
                  <label className="block font-normal text-secondaryl text-base">
                    Project Images:
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    {projectImages.map((imageUrl, index) => (
                      <div key={index} className="mb-3">
                        <img
                          src={imageUrl}
                          alt={`Project ${index + 1}`}
                          className="w-full h-[20vh] object-fill ring-[0.1px] ring-information"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Close Button */}
              <div className="flex flex-wrap gap-5 justify-start">
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContractDetailsModal;
