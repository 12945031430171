import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { Icon } from "@iconify/react";
import DocumentCard from "../../../../components/Corporation/Dashboard/Documents/DocumentCard";
import DocumentCardSkeleton from "../../../../components/Corporation/Dashboard/Documents/DocumentCardSkeleton";
import DocumentModal from "../../../../components/Corporation/Dashboard/Documents/DocumentModal";
import DocumentReadModal from "../../../../components/Corporation/Dashboard/Documents/DocumentReadModal";
import {
  corporationAPI,
  corporationDocuments,
  corporationDocumentsAPI,
} from "../../../../constants/API";
import axios from "axios";
import { set } from "date-fns";
import Loading from "../../../../components/Elements/Loading";

const dummyData = {
  data: {
    data: [
      {
        ID: 1,
        Document_Name: "Test Data 1",
        Document_URL:
          "https://condominiumstorage.blob.core.windows.net/cpdevtest/contract/1729405820465shoping.jpg",
        isActive: true,
      },
      {
        ID: 2,
        Document_Name: "Test Data 2",
        Document_URL: null,
        isActive: true,
      },
    ],
  },
};

const CorporationDocuments = () => {
  const cookies = new Cookies();
  const [load, setLoad] = useState(false);
  const path = useLocation().pathname;
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [section, setSection] = useState("Rules");
  const [pageLoading, setPageLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoad(true);
      const response = await axios.get(
        `${corporationDocumentsAPI}/corporation`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
          params: {
            Section: path.includes("rules")
              ? 1
              : path.includes("bylaws")
              ? 2
              : path.includes("declaration")
              ? 3
              : path.includes("budget")
              ? 4
              : null,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [path]);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedData(null);
  };

  const handleDeleteDocument = async (Document) => {
    setPageLoading(true);
    try {
      await axios.delete(`${corporationDocumentsAPI}/${Document.ID}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      const newData = data.filter((doc) => doc.ID !== Document.ID);
      setData(newData);
      // fetchData(); // Fetch meetings after adding/updating
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message || "Failed to save meeting");
    } finally {
      setPageLoading(false);
    }
  };

  const handleIsActiveDocument = async (Document) => {
    const formDate = new FormData();
    formDate.append("IsActive", !Document.IsActive);
    try {
      setPageLoading(true);
      await axios.put(`${corporationDocumentsAPI}/${Document.ID}`, formDate, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      data.forEach((doc) => {
        if (doc.ID === Document.ID) {
          doc.IsActive = !doc.IsActive;
        }
      });

      // fetchData(); // Fetch meetings after adding/updating
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message || "Failed to save meeting");
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (path.includes("rules")) {
      setSection("Rules");
    } else if (path.includes("bylaws")) {
      setSection("ByLaws");
    } else if (path.includes("declaration")) {
      setSection("Declaration");
    } else {
      setSection("Budget");
    }
  }, [path]);

  return (
    <div className="rounded-2xl">
      <Loading load={pageLoading} />
      <div className="w-full  rounded-2xl px-4 m-auto  p-6 flex flex-col justify-center">
        <div className="flex flex-wrap gap-5 justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            {section}
          </h1>

          {cookies.get("access") === "property manager" && (
            <div
              className="h-11 w-fit px-2.5 py-1.5 items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center bg-secondaryl text-[#ffffff] text-sm truncate   hover:bg-opacity-90  transition duration-300 ease-in-out gap-2"
              onClick={() => setShowModal(true)}
            >
              <Icon
                icon="fluent-mdl2:calculator-addition"
                className="font-bold"
              />
              Add {section}
            </div>
          )}
        </div>

        <div className="">
          {load ? (
            <DocumentCardSkeleton />
          ) : data.length > 0 ? (
            data.map((data, index) => (
              <div key={index}>
                <DocumentCard
                  data={data}
                  section={section}
                  onDelete={() => handleDeleteDocument(data)}
                  onToggleActive={() => handleIsActiveDocument(data)}
                />
              </div>
            ))
          ) : (
            <div className="text-secondaryl text-lg py-4 text-center">
              No {section} found
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <div className={`fixed inset-0 z-30 overflow-y-auto bg-[#231F20B2] `}>
          {/* <Loading load={load} /> */}

          <div className="flex justify-center items-center h-full">
            <div className="bg-white max-h-fit rounded-lg md:w-[40%] w-4/5 h-[80%] overflow-y-auto relative">
              <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 relative">
                {showModal &&
                  (cookies.get("access") === "property manager" ? (
                    <DocumentModal
                      onClose={handleCloseModal}
                      fetchData={fetchData}
                      section={section}
                      setLoad={setLoad}
                      document={document}
                    />
                  ) : (
                    <DocumentReadModal
                      data={selectedData}
                      onClose={handleCloseModal}
                      section={section}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CorporationDocuments;
