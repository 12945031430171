import React, { useState } from "react";
import circle from "../../assets/images/contactcircle.png";
import axios from "axios";
import { baseUrl } from "../../constants/API";
import { NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Loading from "../../components/Elements/Loading";
import { Icon } from "@iconify/react";

function ContactForm() {
  const [load, setLoad] = useState(false);
  const schema = yup.object().shape({
    First_Name: yup.string().required("First Name is required"),
    Last_Name: yup.string().required("Last Name is required"),
    Email: yup
      .string()
      .email()
      .required("Email is required")
      .test("valid-email", "Invalid Email Address", (value) => {
        if (!value) return true; // Skip validation if the field is empty
        // Check if the value matches the pattern of an email
        return /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(value);
      }),
    Phone_No: yup
      .string()
      .required("Phone Number is required")
      .test("invalid-phoneno", "Invalid Phone No", (value) => {
        if (value.length > 10) {
          return true;
        }
        return false;
      }),
    Subject: yup.string().required("Subject is required"),
    Message: yup.string().required("Message is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onContactUsSubmit = async (data, e) => {
    e.preventDefault();
    // console.log(data);
    try {
      setLoad(true);
      const response = await axios.post(`${baseUrl}/contactus`, data);
      // console.log(response);
      // if (response.status === 200) {
      //   navigate("/");
      // }

      toast.success("Message Sent Successfully");
      reset();
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Failed to send message. Please try again."
      );
    } finally {
      setLoad(false);
    }
  };

  const handleKeyDown = (event) => {
    // Check if the pressed key is not a number
    if (
      !(
        (
          (event.key >= "0" && event.key <= "9") ||
          event.key === "+" ||
          event.key === "Tab" ||
          event.key === "F5" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowRight" ||
          event.key === "Delete" ||
          event.keyCode === 8 || // backspace
          (event.ctrlKey && event.keyCode === 65) ||
          (event.ctrlKey && event.keyCode === 86) || // Ctrl + V
          (event.ctrlKey && event.keyCode === 67)
        ) // Ctrl + C
      )
    ) {
      // Prevent the default behavior (don't write the character)
      event.preventDefault();
    }
  };
  return (
    <div className="w-full rounded-2xl  m-auto  p-6  flex flex-col justify-center">
      <Loading load={load} />
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-8 md:gap-12 lg:gap-20 lg:pr-4 pt-3">
        {/* Contact Information */}

        <div className="lg:col-span-3">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            Contact Us
          </h1>
          <p className="text-infromation mb-4">
            Any question or remarks? Just write us a Message!
          </p>
          <div className="">
            <form onSubmit={handleSubmit(onContactUsSubmit)}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="">
                  <label
                    htmlFor="First_Name"
                    className="block text-base font-medium text-secondaryl"
                  >
                    First Name<span className="text-red-500">{" * "}</span>
                  </label>
                  <input
                    type="text"
                    id="First_Name"
                    name="First_Name"
                    placeholder="Joe"
                    className="mt-1 p-2 block w-full border-b bg-[#FFFFFF80] border-gray-300 rounded-t-md shadow-sm focus:outline-none focus:border-secondaryl focus:ring focus:ring-secondaryl focus:ring-opacity-50"
                    {...register("First_Name")}
                  />
                  <div className="h-[2vh] pt-1">
                    {errors.First_Name && (
                      <p className="text-red-500 text-xs leading-none">
                        {errors.First_Name.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="Last_Name"
                    className="block text-base font-medium text-secondaryl"
                  >
                    Last Name <span className="text-red-500">{" * "}</span>
                  </label>
                  <input
                    type="text"
                    id="Last_Name"
                    name="Last_Name"
                    placeholder="Doe"
                    className="mt-1 p-2 block w-full border-b bg-[#FFFFFF80] border-gray-300 rounded-t-md shadow-sm focus:outline-none  focus:border-secondaryl focus:ring focus:ring-secondaryl  focus:ring-opacity-50"
                    {...register("Last_Name")}
                  />
                  <div className="h-[2vh] pt-1">
                    {errors.Last_Name && (
                      <p className="text-red-500 text-xs leading-none">
                        {errors.Last_Name.message}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="Email"
                    className="block text-base font-medium text-secondaryl"
                  >
                    Email<span className="text-red-500">{" * "}</span>
                  </label>
                  <input
                    type="text"
                    id="Email"
                    name="Email"
                    placeholder="Joe@doe.com"
                    className="mt-1 p-2 block w-full border-b bg-[#FFFFFF80] border-gray-300 rounded-t-md shadow-sm focus:outline-none  focus:border-secondaryl focus:ring focus:ring-secondaryl  focus:ring-opacity-50"
                    {...register("Email")}
                  />
                  <div className="h-[2vh] pt-1">
                    {errors.Email && (
                      <p className="text-red-500 text-xs leading-none">
                        {errors.Email.message}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="Phone_No"
                    className="block text-base font-medium text-secondaryl"
                  >
                    Phone Number<span className="text-red-500">{" * "}</span>
                  </label>
                  <input
                    type="tel"
                    id="Phone_No"
                    name="Phone_No"
                    placeholder="+1012 3456 789"
                    className="mt-1 p-2 block w-full border-b border-gray-300 bg-[#FFFFFF80]  rounded-t-md shadow-sm focus:outline-none  focus:border-secondaryl focus:ring focus:ring-secondaryl  focus:ring-opacity-50"
                    {...register("Phone_No")}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="h-[2vh] pt-1">
                    {errors.Phone_No && (
                      <p className="text-red-500 text-xs leading-none">
                        {errors.Phone_No.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-full">
                  <label className="block text-base font-medium text-secondaryl">
                    Subject?<span className="text-red-500">{" * "}</span>
                  </label>

                  <div className="flex-col flex md:flex-row items-start md:gap-10 ">
                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        id="generalInquiry"
                        name="Subject"
                        value="generalInquiry"
                        className="focus:ring-secondaryl h-4 w-4 accent-secondaryl border-gray-300 bg-[#FFFFFF80]"
                        {...register("Subject")}
                      />
                      <label
                        htmlFor="generalInquiry"
                        className="text-base pt-2 text-gray-900"
                      >
                        General Inquiry
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        id="contractor"
                        name="Subject"
                        value="contractor"
                        className=" focus:ring-secondaryl h-4 w-4 accent-secondaryl border-gray-300 bg-[#FFFFFF80] "
                        {...register("Subject")}
                      />
                      <label
                        htmlFor="contractor"
                        className="pt-2 text-base text-gray-900"
                      >
                        Contractor
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        id="corporation"
                        name="Subject"
                        value="corporation"
                        className=" focus:ring-secondaryl h-4 w-4 accent-secondaryl border-gray-300 bg-[#FFFFFF80] "
                        {...register("Subject")}
                      />
                      <label
                        htmlFor="corporation"
                        className="pt-2 text-base text-gray-900"
                      >
                        Corporation
                      </label>
                    </div>
                  </div>

                  <div className="h-[2vh] pt-1">
                    {errors.Subject && (
                      <p className="text-red-500 text-xs leading-none">
                        {errors.Subject.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-full ">
                  <label
                    htmlFor="Message"
                    className="block text-base font-medium text-secondaryl"
                  >
                    Message <span className="text-red-500"> {" * "}</span>
                  </label>
                  <textarea
                    id="Message"
                    name="Message"
                    rows="2"
                    placeholder="Write your Message..."
                    className="mt-1 p-2 block w-full border-b border-gray-300 bg-[#FFFFFF80]  rounded-t-md shadow-sm focus:outline-none  focus:border-secondaryl focus:ring focus:ring-secondaryl  focus:ring-opacity-50"
                    {...register("Message")}
                  ></textarea>
                  <div className="h-[2vh] pt-1">
                    {errors.Message && (
                      <p className="text-red-500 text-xs leading-none">
                        {errors.Message.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-full flex">
                  <button
                    type="submit"
                    className="h-11  px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl  truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out text-xl"
                  >
                    SEND MESSAGE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="lg:col-span-3">
          <div className="bg-primaryl rounded-lg p-6 relative">
            <div>
              <img
                src={circle}
                alt="contactcircle"
                className="absolute h-[60%] w-[25%] -right-7 -bottom-7 z-0"
              />
            </div>

            <div>
              <img
                src={circle}
                alt="contactcircle"
                className="absolute h-[30%] w-[15%] right-[17%] bottom-[17%] z-0"
              />
            </div>

            <h2 className="text-2xl lg:text-4xl text-white my-2 euclidbold ">
              Contact Information
            </h2>
            <div className="flex items-center text-sm lg:text-xl  tracking-wide gap-4 my-3 ">
              <Icon
                icon="carbon:location-filled"
                className="text-white text-3xl"
              />
              <div className="text-white ">
                1290 Central Pkwy W 11th Floor Suite 1100, Mississauga, ON L5C
                4R3
              </div>
            </div>
            <div className="flex items-center text-sm lg:text-xl tracking-wide  gap-4 my-3 ">
              <Icon
                icon="fluent:person-call-20-filled"
                className="text-white text-2xl"
              />
              <div className="text-white cursor-pointer">
                {" "}
                <NavLink to="tel:+249-777-7667">(249-777-7667)</NavLink>
              </div>
            </div>

            <div className="flex items-center tracking-wide gap-4 my-3 text-xs lg:text-xl overflow-hidden">
              <Icon
                icon="foundation:mail"
                className="text-white text-2xl w-[4%]"
              />
              <div className="text-white cursor-pointer">
                <NavLink
                  to="mailto:support@condominiumportal.com"
                  className="line-clamp-1"
                >
                  support@condominiumportal.com
                </NavLink>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <NavLink
                to="https://www.facebook.com/share/mypoXz4tCZbT9p1X/?mibextid=LQQJ4d"
                target="_blank"
              >
                <div className="text-[#ffffff] rounded-full flex justify-center items-center w-8 h-8  border-[1px] border-[#ffffff] hover cursor-pointer hover:bg-white hover:border-none hover:text-primaryl text-lg">
                  <Icon icon="eva:facebook-fill" className="text-xl " />
                </div>
              </NavLink>
              <NavLink
                to="https://www.instagram.com/condominiumportal?igsh=MXVmN2hiaHV0OTU3ZQ=="
                target="_blank"
              >
                <div className="text-[#ffffff] rounded-full flex justify-center items-center w-8 h-8  border-[1px] border-[#ffffff] hover cursor-pointer hover:bg-white hover:border-none hover:text-primaryl text-lg">
                  <Icon icon="formkit:instagram" className="text-xl " />

                  {/* <FaInstagram /> */}
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactForm;
