import React, { useEffect, useState } from "react";
import { get, set, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { tenantAPI } from "../../../../constants/API.js";
import { InputFileIcon, Spinner } from "../../../../assets/images/index.jsx";
import toast from "react-hot-toast";
import InputBox from "../../../Elements/InputBox.jsx";
import InputBoxLive from "../../../Elements/InputBoxLive.jsx";
import Loading from "../../../Elements/Loading";
import Cookies from "universal-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { getCorporation } from "../../../../constants/Request.jsx";
import DropDownStyle1 from "../../../Elements/DropDownStyle1.jsx";
import { Icon } from "@iconify/react";
import InputBoxLiveSimpleDate from "../../../Elements/InputBoxLiveSimpleDate.jsx";

function CreateTenant() {
  const [load, setLoad] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [selfRegister, setSelfRegister] = useState(false);
  const [corporationOption, setCorporationOption] = useState(null);

  useEffect(() => {
    setLoad(true);
    if (!location.pathname.includes("corporations")) {
      getCorporation()
        .then((data) => {
          // console.log("data", data);
          setCorporationOption(data);
        })
        .catch((error) => {
          // console.log("error", error);
        });

      setSelfRegister(true);
    }
    setLoad(false);
  }, []);

  const [uploadingFile, setUploadingFile] = useState(false);
  const cookies = new Cookies();
  const [Lease_Agreement_Document, setLease_Agreement_Document] =
    useState(null);
  const [Lease_Agreement_Document_Error, setLease_Agreement_Document_Error] =
    useState(null);
  const [Id_Proof_Document, setId_Proof_Document] = useState(null);
  const [Id_Proof_Document_Error, setId_Proof_Document_Error] = useState(null);
  const [selectCorporation, setSelectCorporation] = useState(null);

  // Form validation schema using yup
  const schema = yup.object().shape({
    First_Name: yup.string().required("First Name is required"),
    Last_Name: yup.string().required("LastName is required"),
    Corporation_ID: yup
      .string()
      .test(
        "is-required-if-self-register",
        "Corporation is required",
        function (value) {
          if (selfRegister) {
            return !!value; // If `selfRegister` is true, `Corporation_ID` is required
          }
          return true; // If `selfRegister` is false, skip the required validation
        }
      ),
    Email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
        "Invalid Email"
      ),
    Phone_No: yup
      .string()
      .required("Phone No is required")
      .matches(
        /^[0-9]{10}$/, // Regular expression for 10-digit phone number
        "Phone No must be exactly 10 digits"
      ),
    Date_Of_Birth: yup
      .string()
      .test("is-required", "Date of Birth is required", function (value) {
        if (selfRegister) {
          return !!value;
        }
        return true; // If `selfRegister` is false, skip the required validation
      }),
    Residential_Address: yup.string().required("Home Address is required"),
    Unit_No: yup.string(),
    Parking: yup.string().required("Parking is required"),
    Locker: yup.string().required("Locker is required"),
    Id_Proof_Document: yup
      .string()
      .test("is-required", "Id Proof Document is required", function (value) {
        if (selfRegister) {
          return !!value;
        }
        return true;
      }),

    Property_Type: yup.string().required("Property Type is required"),
    Lease_Agreement_Document: yup
      .string()

      .test(
        "is-required",
        "Lease Agreement Document is required",
        function (value) {
          if (selfRegister) {
            return !!value;
          }
          return true;
        }
      ),
    Lease_Start_Date: yup.string().required("Lease Start Date is required"),
    Lease_Duration: yup.string().required("Lease Duration is required"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  const onTenantSignUp = async (data, e) => {
    e.preventDefault();
    setLoad(true);
    // setLoad(true);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.set(key, data[key]);
    });

    if (Lease_Agreement_Document) {
      formData.set("Lease_Agreement_Document", Lease_Agreement_Document);
    }

    if (Id_Proof_Document) {
      formData.set("Id_Proof_Document", Id_Proof_Document);
    }

    try {
      let response;
      if (selfRegister) {
        // console.log("tenant");

        response = await axios.post(`${tenantAPI}/createbytenant`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: cookies.get("token"),
            companyType: "corporation",
          },
        });
        navigate("/tenant/login");
      } else {
        // console.log("corporation");

        response = await axios.post(
          `${tenantAPI}/createbycorporation`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: cookies.get("token"),
              companyType: "corporation",
            },
          }
        );
        navigate("/corporations/dashboard");
      }

      toast.success(response.data.message);
      // console.log("response", response);
      reset();
    } catch (error) {
      toast.error(error.response.data.message);
      // console.log("error", error);
    } finally {
      setLoad(false);
    }
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024;

  const handleChangeLeaseAgreementDocument = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setLease_Agreement_Document_Error("Please Upload a file");
      clearErrors("Lease_Agreement_Document");
      setValue("Lease_Agreement_Document", "");
      setLease_Agreement_Document(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FORMATS.includes(file.type)
      ) {
        setLease_Agreement_Document_Error("");
        clearErrors("Lease_Agreement_Document");
        setValue("Lease_Agreement_Document", file);
        setLease_Agreement_Document(file);
      } else if (file && !SUPPORTED_FORMATS.includes(file.type)) {
        setLease_Agreement_Document_Error(
          "Unsupported file type file type must be jpg/jpeg/png/pdf"
        );
        clearErrors("Lease_Agreement_Document");
        setValue("Lease_Agreement_Document", "");

        setLease_Agreement_Document(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setLease_Agreement_Document_Error(
          "File size exceeds limit. File size must be less than 5 MB"
        );
        clearErrors("Lease_Agreement_Document");
        setValue("Lease_Agreement_Document", "");
        setLease_Agreement_Document(null);
      } else {
        setLease_Agreement_Document_Error(
          "Something went wrong please try again later"
        );
        clearErrors("Lease_Agreement_Document");
        setValue("Lease_Agreement_Document", "");
        setLease_Agreement_Document(null);
      }
    }
  };

  const handleChangeIdProofDocument = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setId_Proof_Document_Error("Please Upload a file");
      clearErrors("Id_Proof_Document");
      setValue("Id_Proof_Document", "");
      setId_Proof_Document(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FORMATS.includes(file.type)
      ) {
        setId_Proof_Document_Error("");
        clearErrors("Id_Proof_Document");
        setValue("Id_Proof_Document", file);
        setId_Proof_Document(file);
      } else if (file && !SUPPORTED_FORMATS.includes(file.type)) {
        setId_Proof_Document_Error(
          "Unsupported file type file type must be jpg/jpeg/png/pdf"
        );
        clearErrors("Id_Proof_Document");
        setValue("Id_Proof_Document", "");

        setId_Proof_Document(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setId_Proof_Document_Error(
          "File size exceeds limit. File size must be less than 5 MB"
        );
        clearErrors("Id_Proof_Document");
        setValue("Id_Proof_Document", "");
        setId_Proof_Document(null);
      } else {
        setId_Proof_Document_Error(
          "Something went wrong please try again later"
        );
        clearErrors("Id_Proof_Document");
        setValue("Id_Proof_Document", "");
        setId_Proof_Document(null);
      }
    }
  };

  const documentClearError = () => {
    setLease_Agreement_Document_Error("");
    setId_Proof_Document_Error("");
  };

  // console.log(getValues());
  // console.log(errors);

  return (
    <div>
      <Loading load={load} />

      <div className="w-full px-4 m-auto p-6 rounded-2xl flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold flex justify-center  md:justify-start  ">
          Create Tenant Account
        </h1>
        <p className="my-1 text-infromation text-base leading-tight flex justify-center  lg:justify-start ">
          Join the community and find your next home with ease!
        </p>

        {/* SignUp Form Start Here  */}
        <form
          onSubmit={handleSubmit(onTenantSignUp)}
          encType="multipart/form-data"
        >
          <div className="py-2">
            <h2 className="text-secondaryl font-extrabold text-xl euclidbold  items-center justify-start my-4  gap-2  flex">
              <Icon className="text-2xl" icon="material-symbols:person" />
              Personal information
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full lg:w-[90%]">
              <InputBox
                title={"First Name"}
                register={register}
                filedName={"First_Name"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
              />

              <InputBox
                title={"Last Name"}
                register={register}
                filedName={"Last_Name"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
              />
            </div>

            {/*  Email And Phone No */}
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full lg:w-[90%]">
              <InputBoxLive
                title={"Email"}
                register={register}
                filedName={"Email"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                schema={schema}
                setError={setError}
              />

              <InputBoxLive
                title={"Phone No"}
                register={register}
                filedName={"Phone_No"}
                must={true}
                type="tel"
                errors={errors}
                className={"md:text-sm lg:text-base"}
                maxLength="10"
                schema={schema}
                setError={setError}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full lg:w-[90%]">
              {selfRegister && (
                <div className="">
                  <label
                    htmlFor={"Corporation_ID"}
                    className="block font-normal"
                  >
                    <p>
                      Select Corporation
                      <span className="text-red-500 text-base">*</span>
                    </p>
                  </label>

                  <DropDownStyle1
                    title="Select Corporation"
                    options={corporationOption}
                    onSelect={setSelectCorporation}
                    selectOption={selectCorporation}
                    setValue={setValue}
                    filedName={"Corporation_ID"}
                    clearErrors={clearErrors}
                    errors={errors}
                    dbfield={"Corporation_Name"}
                  />
                </div>
              )}
              <InputBoxLiveSimpleDate
                type="date"
                title={"Date of Birth"}
                register={register}
                filedName={"Date_Of_Birth"}
                must={selfRegister}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                schema={schema}
                setError={setError}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>

            <h2 className="text-secondaryl font-extrabold text-xl euclidbold  items-center justify-start my-4  gap-2  flex">
              <Icon
                className="text-2xl"
                icon="fluent:mountain-location-bottom-24-filled"
              />
              Address information
            </h2>

            {/* Property Address  */}
            <div className="flex flex-col md:flex-row w-full lg:w-[90%] md:gap-16">
              <div className="w-full md:w-[35%] lg:w-[30%]">
                <InputBox
                  title={"Unit No"}
                  register={register}
                  filedName={"Unit_No"}
                  must={false}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                />
              </div>
              <div className="w-full md:w-[65%] lg:w-[70%]">
                <InputBox
                  title={"Property Address"}
                  register={register}
                  filedName={"Residential_Address"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full lg:w-[90%]">
              <InputBox
                title={"Parking (If not applicable write NA)"}
                register={register}
                filedName={"Parking"}
                must={true}
                errors={errors}
                placeHolder="Type Parking"
                className={"md:text-sm lg:text-base"}
              />
              <InputBox
                title={"Locker (If not applicable write NA)"}
                register={register}
                filedName={"Locker"}
                must={true}
                errors={errors}
                placeHolder="Type Locker"
                className={"md:text-sm lg:text-base"}
              />
            </div>

            <h2 className="text-secondaryl font-extrabold text-xl euclidbold  items-center justify-start my-4  gap-2  flex">
              <Icon
                className="text-2xl"
                icon="heroicons:identification-solid"
              />
              Identification Information
            </h2>

            <div className="">
              <label
                className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
              >
                Id Proof Document
                {selfRegister && <span className="text-red-500">*</span>}
              </label>

              <div className="flex items-center gap-5 w-full lg:w-[90%]">
                <input
                  type="file"
                  id="Id_Proof_Document"
                  name="Id_Proof_Document"
                  className={`hidden ${
                    uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                  } `}
                  onChange={handleChangeIdProofDocument}
                  disabled={uploadingFile}
                  accept=".jpg,.jpeg,.png,application/pdf"
                />

                <label
                  htmlFor="Id_Proof_Document"
                  className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none ${
                    Id_Proof_Document_Error
                      ? "ring-[ring-0.5px] ring-red-500"
                      : ""
                  }  ${
                    uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                  } `}
                  disabled={uploadingFile}
                >
                  <div className="flex items-center h-full space-x-1 overflow-hidden">
                    <InputFileIcon />
                    {Id_Proof_Document ? (
                      <span className="line-clamp-1">
                        {Id_Proof_Document.name}
                      </span>
                    ) : (
                      <span className="text-infromation text-opacity-50  text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                        Upload Id Proof Document
                      </span>
                    )}
                  </div>
                </label>
              </div>
              {/* Error */}
              <div className="h-[3vh] mb-3">
                {uploadingFile && <Spinner />}
                {Id_Proof_Document_Error && (
                  <p className="text-red-500 text-xs leading-none">
                    {Id_Proof_Document_Error}
                  </p>
                )}
                {errors.Id_Proof_Document && ( // Error message from yup validation
                  <p className="text-red-500 text-xs leading-none">
                    {errors.Id_Proof_Document.message}
                  </p>
                )}
              </div>
            </div>

            <h2 className="text-secondaryl font-extrabold text-xl euclidbold  items-center justify-start my-4  gap-2  flex">
              <Icon className="text-2xl" icon="fluent:person-home-32-filled" />
              Property Details
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full lg:w-[90%]">
              <InputBox
                title={"Property Type"}
                register={register}
                filedName={"Property_Type"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full lg:w-[90%]">
              <InputBoxLive
                type="date"
                title={"Lease Start Date"}
                register={register}
                filedName={"Lease_Start_Date"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                schema={schema}
                setError={setError}
              />
              <InputBox
                title={"Lease Duration (In Months)"}
                register={register}
                type="number"
                filedName={"Lease_Duration"}
                must={true}
                errors={errors}
                min={1}
                className={"md:text-sm lg:text-base"}
              />
            </div>

            <div className="">
              <label
                className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
              >
                Lease Agreement Document
                {selfRegister && <span className="text-red-500">*</span>}
              </label>

              <div className="flex items-center gap-5 w-full lg:w-[90%]">
                <input
                  type="file"
                  id="Lease_Agreement_Document"
                  name="Lease_Agreement_Document"
                  className={`hidden ${
                    uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                  } `}
                  onChange={handleChangeLeaseAgreementDocument}
                  disabled={uploadingFile}
                  accept=".jpg,.jpeg,.png,application/pdf"
                />

                <label
                  htmlFor="Lease_Agreement_Document"
                  className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none ${
                    Lease_Agreement_Document_Error
                      ? "ring-[ring-0.5px] ring-red-500"
                      : ""
                  }  ${
                    uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                  } `}
                  disabled={uploadingFile}
                  style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                >
                  <div className="flex items-center h-full space-x-1 overflow-hidden">
                    <InputFileIcon />
                    {Lease_Agreement_Document ? (
                      <span className="line-clamp-1">
                        {Lease_Agreement_Document.name}
                      </span>
                    ) : (
                      <span className="text-infromation text-opacity-50  text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                        Upload Lease Agreement Document
                      </span>
                    )}
                  </div>
                </label>
              </div>
              {/* Error */}
              <div className="h-[3vh] mb-3">
                {uploadingFile && <Spinner />}
                {Lease_Agreement_Document_Error && (
                  <p className="text-red-500 text-xs leading-none">
                    {Lease_Agreement_Document_Error}
                  </p>
                )}
                {errors.Lease_Agreement_Document && ( // Error message from yup validation
                  <p className="text-red-500 text-xs leading-none">
                    {errors.Lease_Agreement_Document.message}
                  </p>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <div className="mb-3">
              <button
                type="submit"
                className={`
                h-11 w-fit euclidbold  ring-secondaryl rounded-md flex justify-center text-secondaryl  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 cursor-pointer euclidbold  text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out  ${
                  uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={documentClearError}
                disabled={uploadingFile}
              >
                CREATE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateTenant;
