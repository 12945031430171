import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import profile from "../../assets/image/LOGO.png";
import NotificationIcons from "../../assets/images/NotificationIcons";
import LogoutIcon from "../../assets/images/LogoutIcon";
import Cookies2 from "js-cookie";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";

function DashboardHeader() {
  const cookies = new Cookies();
  const [showProfile, setShowProfile] = useState(false);

  const [isNotification, setIsNotification] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // SetUp Notification Availability or not
  useEffect(() => {
    setIsNotification(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowProfile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const logoutHandler = () => {
    // Remove all cookies related to the session
    // console.log(" logoutHandler called");
    Cookies2.remove("token");
    Cookies2.remove("companyType");
    Cookies2.remove("Profile_Photo");
    Cookies2.remove("access");
    Cookies2.remove("selectedPropertyManagerId");
    Cookies2.remove("status");
    cookies.remove("token");
    cookies.remove("companyType");
    cookies.remove("access");
    cookies.remove("Profile_Photo");
    cookies.remove("selectedPropertyManagerId");
    cookies.remove("status");

    navigate("/");
    window.location.reload();
  };

  return (
    <header className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-3 rounded-lg  mb-3 flex  justify-center md:justify-end relative">
      <div className="flex items-center space-x-4 ">
        <span
          className="cursor-pointer"
          onClick={() => toast.success("Under Maintenance")}
        >
          <NotificationIcons isNotification={isNotification} />
        </span>

        <div
          onClick={() => setShowProfile(!showProfile)}
          ref={dropdownRef}
          className="w-14 h-14  rounded-full overflow-hidden cursor-pointer"
        >
          <img
            src={profile}
            alt="Profile"
            className="w-full h-full object-cover "
          />
          {/* 
          {showProfile && (
            <ul
              onClick={() => setShowProfile(!showProfile)}
              className="absolute bg-white flex-col  bg-opacity-50 flex items-center justify-center"
            >
              <li className="p-4 rounded-lg">
                <NavLink to="/corporations/profile" className="text-gray-800">
                  Profile
                </NavLink>
              </li>
              <li className="p-4 rounded-lg">
                <button
                  onClick={() => setShowProfile(!showProfile)}
                  className=""
                >
                  Logout
                </button>
              </li>
            </ul>
          )} */}

          {/* Dropdown Menu */}
          {showProfile && (
            <div className="absolute  right-5 md:right-0 mt-2 w-48 bg-white text-secondaryl  rounded-lg shadow-lg z-10">
              <ul className="">
                {cookies.get("access") !== "board member" && (
                  <li
                    className="hover:bg-primaryl tracking-wide hover:text-white px-4 py-2 cursor-pointer rounded-lg font-semibold"
                    onClick={() => {
                      if (location.pathname.includes("corporations")) {
                        navigate("/corporations/dashboard/profile");
                      } else if (location.pathname.includes("contractors")) {
                        navigate("/contractors/dashboard/profile");
                      }
                    }}
                  >
                    My Profile
                  </li>
                )}

                <li
                  onClick={(e) => {
                    e.preventDefault();
                    logoutHandler();
                  }}
                  className="hover:bg-primaryl  z-50 tracking-wide flex items-center gap-2 hover:text-white px-4 py-2 cursor-pointer rounded-lg font-semibold"
                >
                  <LogoutIcon />
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default DashboardHeader;
