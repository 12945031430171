import React from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

function Hero() {
  const navigate = useNavigate();

  return (
    <div className="w-full flex items-center justify-center h-[89vh] relative landing_page">
      <div className="w-full h-full flex items-center flex-wrap justify-center gap-1 flex-col">
        {/* <button
          onClick={() =>
            navigate("/corporations", { state: { companyType: "corporation" } })
          }
          className="px-6 py-2.5 ring-1 ring-white hover:ring-transparent  text-white text-xl font-bold tracking-widest hover:bg-secondaryl  transition duration-100 ease-in-out "
        >
          CORPORATIONS
        </button>
        <button
          onClick={() =>
            navigate("/contractors/login", {
              state: { companyType: "contractor" },
            })
          }
          className=" px-6 ring-1 py-2.5 ring-white hover:ring-transparent text-white text-xl font-bold tracking-widest   hover:bg-secondaryl   transition duration-100 ease-in-out"
        >
          CONTRACTORS
        </button> */}
        <h3 className="text-white lg:text-4xl md:text-3xl sm:text-xl px-[2.5%] font-bold">
          We Eliminate Property Management Issues and Hassles
        </h3>
        <p className="mb-4 italic text-gray-300 text-justify px-[2.5%]">
          At Condominium Portal Inc., we specialize in eliminating property
          management issues and hassles, ensuring a smooth and stress-free
          experience for property owners. Our comprehensive services address all
          aspects of property management, from maintenance and tenant relations
          to financial oversight and legal compliance. With our expertise and
          dedication, you can enjoy peace of mind knowing your property is in
          capable hands
        </p>
      </div>
      <div className="absolute bottom-[1%] left-0 w-full h-[17%] bg-gradient-to-b from-transparent to-[#0893bd]"></div>
    </div>
  );
}

export default Hero;
