// export const baseUrl = "http://localhost:8080";
// export const baseUrl = "https://condominiumportaldev.azurewebsites.net"; // test
export const baseUrl = "https://cpbackendlive.azurewebsites.net/"; // live

// MAIN APIS
export const corporationAPI = `${baseUrl}/corporation`;
export const contractAPI = `${baseUrl}/contract`;
export const contractorAPI = `${baseUrl}/contractor`;
export const proposalAPI = `${baseUrl}/proposal`;
export const reviewsAPI = `${baseUrl}/reviews`;
export const adminAPI = `${baseUrl}/admin`;
export const blogAPI = `${baseUrl}/blog`;
export const ownerAPI = `${baseUrl}/owners`;
export const tenantAPI = `${baseUrl}/tenant`;
export const meetingAPI = `${baseUrl}/meeting`;
export const meetingDocumentRequestTenantAPI = `${baseUrl}/meetingdocumentrequesttenant`;
export const meetingDocumentRequestOwnersAPI = `${baseUrl}/meetingdocumentrequestowners`;
export const serviceCorporationApi = `${baseUrl}/servicecorporation`;
export const subServiceCorporationApi = `${baseUrl}/subservicecorporation`;
export const serviceRequestOwnersAPI = `${baseUrl}/servicerequestowners`;
export const serviceRequestTenantAPI = `${baseUrl}/servicerequesttenant`;
export const corporationDocumentsAPI = `${baseUrl}/corporationdocuments`;
export const corporationNoticeAPI = `${baseUrl}/corporationnotice`;
export const corporationPICAPI = `${baseUrl}/corporationpublicinformationcertificate`;
export const tenantComplaintSuggestionAPI = `${baseUrl}/tenantcomplaintsuggestion`;
export const ownersComplaintSuggestionAPI = `${baseUrl}/ownerscomplaintsuggestion`;

// FILE APIs
export const corporationCertificateAPI = `${baseUrl}/corporation/certificate`;
export const uploadFileAPI = `${baseUrl}/contractor/certificate`;
export const uploadBusinessCard = `${baseUrl}/contractor/businesscard`;
export const contractimageAPI = `${baseUrl}/contract/certificate`;
export const proposalFileAPI = `${baseUrl}/proposal/certificate`;
export const insuranceCertificateAPI = `${baseUrl}/contractor/insurancecertificate`;
export const contractorProfileImagesAPI = `${baseUrl}/contractor/contractorprofileimage`;

//LOGIN APIs
export const contractorLoginAPI = `${baseUrl}/contractor/login`;
export const propertyManagerLoginAPI = `${baseUrl}/propertymanager/login`;
export const boardMemberLoginAPI = `${baseUrl}/boardmember/login`;
export const resetPasswordMailAPI = `${baseUrl}/sendresetpasswordmail`;
export const setPasswordAPI = `${baseUrl}/setpassword`;
export const ownerLoginAPI = `${baseUrl}/owners/login`;
export const tenantLoginAPI = `${baseUrl}/tenant/login`;

//SAMPLE APIs
export const contractsAPI = `${baseUrl}/contracts/service`;
export const corporationBoardMemberAPI = `${baseUrl}/corporation/board-member`;
export const designationAPI = `${baseUrl}/designation`;
export const cityareaAPI = `${baseUrl}/cityarea`;
export const serviesAPI = `${baseUrl}/service`;
export const contracts = `${baseUrl}/contract`;

// getAppiedContract
export const getAppiedContracts = `${baseUrl}/contract/applied`;

// paginationContract
export const getContractsPagination = `${baseUrl}/contractor/contractspagination`;

// Manager Project(Contacts) in Corporation
export const getManageProject = `${baseUrl}/corporation/corporationcontracts`;

// getProfileData
export const getContractorProfileAPI = `${baseUrl}/contractor/profile`;
export const corporationProfile = `${baseUrl}/corporation/profile`;

// getContractWithCheckProposal
export const getContractWithCheckProposal = `${baseUrl}/contractor/detaliscontract`;

// getContractorProposal
export const getContractorProposal = `${baseUrl}/contractor/getproposalcontractor`;

// save Profile in Contractor
export const saveContractorProfile = `${baseUrl}/contractor/saveprofile`;

// property Manager Delete
export const propertyManager = `${baseUrl}/propertymanager`;

// broadMember
export const boardMember = `${baseUrl}/boardmember`;
