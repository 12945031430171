import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { ApprovedIcon, CountDay } from "../../../../assets/images";
import axios from "axios";
import { boardMember } from "../../../../constants/API";
import toast from "react-hot-toast";
import Cookies from "universal-cookie";
import { Icon } from "@iconify/react";

const ManageBoardMembers = ({ boardMemberData, setLoad }) => {
  const cookies = new Cookies();
  const [boardMembersCount, setboardMembersCount] = useState(0);
  const [isEdit, setIsEdit] = useState([]);

  const schema = yup.object().shape({
    boardMembers: yup.array().of(
      yup.object().shape({
        Board_Member_Name: yup
          .string()
          .required("Board Member Name is required"),
        Board_Member_Email: yup
          .string()
          .required("Board Member Email is required")
          .matches(
            /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
            "Invalid email"
          ),
        Board_Member_Phone_No: yup
          .string()
          .required("Board Member Phone Number is required")
          .matches(/^[0-9]{10}$/, "Phone No must be exactly 10 digits"),
        Designation: yup.string().required("Designation is required"),
        isEdit: yup.boolean(),
      })
    ),
  });

  const {
    getValues,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    handleSubmit,
    trigger,
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    // getBoardMembers().then((data) => {
    //   if (data) {
    //     setValue("boardMembers", data);
    //     setIsEdit(Array(data.length).fill(true));
    //     setboardMembersCount(data.length);
    //   }
    // });
    if (boardMemberData) {
      // console.log("boardMemberData", boardMemberData);
      setValue("boardMembers", boardMemberData);

      setIsEdit(Array(boardMemberData.length).fill(true));
      setboardMembersCount(boardMemberData.length);
    }
  }, [boardMemberData, register]);

  const validateField = (fieldName, value, index) => {
    schema
      .validateAt(`boardMembers[${index}].${fieldName}`, { [fieldName]: value })
      .then(() => {
        // If the field is valid, remove the error message
        setError((prevErrors) => ({
          ...prevErrors,
          [fieldName]: "",
        }));
      })
      .catch((err) => {
        // If the field is invalid, add the error message
        setError((prevErrors) => ({
          ...prevErrors,
          [fieldName]: err.errors[0],
        }));
      });
  };

  const handleAddBoardMembers = () => {
    let boardMembersArray = getValues("boardMembers");
    if (!boardMembersArray) {
      boardMembersArray = [];
    }
    boardMembersArray.push({
      Board_Member_Name: "",
      Board_Member_Email: "",
      Board_Member_Phone_No: "",
      Designation: "",
    });

    const length = boardMembersArray.length;
    setboardMembersCount(length);
    setValue("boardMembers", boardMembersArray);
  };

  const handleDeleteBoardMembers = (indexToDelete) => {
    let boardMembersArray = getValues("boardMembers");
    const updateboardMembers = boardMembersArray.filter(
      (_, index) => index !== indexToDelete
    );
    const length = updateboardMembers.length;
    setboardMembersCount(length);
    setIsEdit(isEdit.filter((_, index) => index !== indexToDelete));
    setValue("boardMembers", updateboardMembers);
  };

  const handleBoardMembersChange = (index, field, value) => {
    setValue(`boardMembers[${index}].${field}`, value);
    trigger(`boardMembers[${index}].${field}`);
  };

  const handleSaveBoardMembers = async (index) => {
    const isValid = await trigger();

    if (isValid) {
      try {
        setLoad(true);
        const response = await axios.post(
          boardMember,
          getValues(`boardMembers[${index}]`),
          {
            headers: {
              authorization: cookies.get("token"),
              companyType: cookies.get("companyType"),
            },
          }
        );

        toast.success(response.data.message);
        setValue(`boardMembers[${index}]`, response.data.data);
        setIsEdit([...isEdit, true]);
        setLoad(false);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setLoad(false);
      }
    }
  };

  const handleEditBoardMembers = async (index) => {
    const updatedIsEdit = [...isEdit]; // Create a copy of isEdit array
    updatedIsEdit[index] = !updatedIsEdit[index]; // Modify the value at index 2
    setIsEdit(updatedIsEdit);
  };

  const handleUpdateBoardMembers = async (index) => {
    try {
      setLoad(true);
      const id = getValues(`boardMembers[${index}].ID`);
      const response = await axios.put(
        `${boardMember}/${id}`,
        getValues(`boardMembers[${index}]`),
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      toast.success(response.data.message);
      const updatedIsEdit = [...isEdit]; // Create a copy of isEdit array
      updatedIsEdit[index] = !updatedIsEdit[index]; // Modify the value at index 2
      setIsEdit(updatedIsEdit);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  const handleDeleteBoardMemeberById = async (indexToDelete) => {
    try {
      setLoad(true);
      const id = getValues(`boardMembers[${indexToDelete}].ID`);
      const response = await axios.delete(`${boardMember}/${id}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      const boardMembersArray = getValues("boardMembers");
      const updatedboardMember = boardMembersArray.filter(
        (_, index) => index !== indexToDelete
      );

      const length = updatedboardMember.length;
      setboardMembersCount(length);
      const updatedIsEdit = [...isEdit]; // Create a copy of isEdit array

      setIsEdit(isEdit.filter((_, index) => index !== indexToDelete));
      setValue("boardMembers", updatedboardMember);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  const handleKeyDown = (event) => {
    // Check if the pressed key is not a number
    if (
      !(
        (
          (event.key >= "0" && event.key <= "9") ||
          event.key === "Tab" ||
          event.key === "F5" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowRight" ||
          event.key === "Delete" ||
          event.keyCode === 8 || // backspace
          (event.ctrlKey && event.keyCode === 65) ||
          (event.ctrlKey && event.keyCode === 86) || // Ctrl + V
          (event.ctrlKey && event.keyCode === 67)
        ) // Ctrl + C
      )
    ) {
      // Prevent the default behavior (don't write the character)
      event.preventDefault();
    }
  };

  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; //  MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png"];
  const [signature, setSignature] = useState([]);
  const [signatureError, setSignatureError] = useState([]);
  const [signatureUploded, setSignatureUploded] = useState([]);
  const [signatureURL, setSignatureURL] = useState([]);
  const [uploadingFile, setUploadingFile] = useState([]);
  const [uploadingSignature, setUploadingSignature] = useState([]);

  // Handle Signature Upload Local
  // const handleChangeSignature = (e, index) => {
  //   const file = e.target.files[0];
  //   if (!file) {
  //     const updateSignature = [...signature]; // Create a copy of isEdit array
  //     updateSignature[index] = null; // Modify the value at index 2
  //     setSignature(updateSignature);

  //     const updateSignatureError = [...signatureError];
  //     updateSignatureError[index] = "Please Attach a Signature";
  //     setSignatureError(updateSignatureError);

  //     const updateSignatureUploded = [...signatureUploded];
  //     updateSignatureUploded[index] = "";
  //     setSignatureUploded(updateSignatureUploded);

  //     const updateSignatureURL = [...signatureURL];
  //     updateSignatureURL[index] = "";
  //     setSignatureURL(updateSignatureURL);

  //     clearErrors(`boardMembers[${index}].Signature`);
  //   } else {
  //     if (
  //       file &&
  //       file.size <= FILE_SIZE_LIMIT &&
  //       SUPPORTED_FILE_TYPES.includes(file.type)
  //     ) {
  //       const updateSignature = [...signature];
  //       updateSignature[index] = file;
  //       setSignature(updateSignature);

  //       const updateSignatureError = [...signatureError];
  //       updateSignatureError[index] = "";
  //       setSignatureError(updateSignatureError);

  //       const updateSignatureUploded = [...signatureUploded];
  //       updateSignatureUploded[index] = "";
  //       setSignatureUploded(updateSignatureUploded);

  //       const updateSignatureURL = [...signatureURL];
  //       updateSignatureURL[index] = "";
  //       setSignatureURL(updateSignatureURL);

  //       clearErrors(`boardMembers[${index}].Signature`);
  //     } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
  //       const updateSignature = [...signature];
  //       updateSignature[index] = null;
  //       setSignature(updateSignature);

  //       const updateSignatureError = [...signatureError];
  //       updateSignatureError[index] =
  //         "Unsupported file type file type must be jpg , jpeg, png";
  //       setSignatureError(updateSignatureError);

  //       const updateSignatureUploded = [...signatureUploded];
  //       updateSignatureUploded[index] = "";
  //       setSignatureUploded(updateSignatureUploded);

  //       const updateSignatureURL = [...signatureURL];
  //       updateSignatureURL[index] = "";
  //       setSignatureURL(updateSignatureURL);

  //       clearErrors(`boardMembers[${index}].Signature`);
  //     } else if (file && !file.size <= FILE_SIZE_LIMIT) {
  //       const updateSignature = [...signature];
  //       updateSignature[index] = null;
  //       setSignature(updateSignature);

  //       const updateSignatureError = [...signatureError];
  //       updateSignatureError[index] = "File size exceeds limit (5 MB)";
  //       setSignatureError(updateSignatureError);

  //       const updateSignatureUploded = [...signatureUploded];
  //       updateSignatureUploded[index] = "";
  //       setSignatureUploded(updateSignatureUploded);

  //       const updateSignatureURL = [...signatureURL];
  //       updateSignatureURL[index] = "";
  //       setSignatureURL(updateSignatureURL);

  //       clearErrors(`boardMembers[${index}].Signature`);
  //     } else {
  //       const updateSignature = [...signature];
  //       updateSignature[index] = null;
  //       setSignature(updateSignature);

  //       const updateSignatureError = [...signatureError];
  //       updateSignatureError[index] = "Something went wrong! Please try again.";
  //       setSignatureError(updateSignatureError);

  //       const updateSignatureUploded = [...signatureUploded];
  //       updateSignatureUploded[index] = "";
  //       setSignatureUploded(updateSignatureUploded);

  //       const updateSignatureURL = [...signatureURL];
  //       updateSignatureURL[index] = "";
  //       setSignatureURL(updateSignatureURL);

  //       clearErrors(`boardMembers[${index}].Signature`);
  //     }
  //   }
  // };

  // Handle Signature Upload API
  // const handleUploadSignature = async (index) => {
  //   if (!signature[index]) {
  //     const updateSignatureError = [...signatureError];
  //     updateSignatureError[index] = "Please Select a Signature to Upload";
  //     setSignatureError(updateSignatureError);

  //     const updateSignatureUploded = [...signatureUploded];
  //     updateSignatureUploded[index] = "";
  //     setSignatureUploded(updateSignatureUploded);

  //     clearErrors(`boardMembers[${index}].Signature`);
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("companyCertificate", signature[index]);

  //   try {
  //     let updateSignatureError = [...signatureError];
  //     updateSignatureError[index] = "";
  //     setSignatureError(updateSignatureError);

  //     // const updateSignatureUploded = [...signatureUploded];
  //     // updateSignatureUploded[index] = "";
  //     // setSignatureUploded(updateSignatureUploded);
  //     // const updateSignatureURL = [...signatureURL];
  //     // updateSignatureURL[index] = "";
  //     // setSignatureURL(updateSignatureURL);

  //     const updateUploadingFile = [...uploadingFile];
  //     updateUploadingFile[index] = true;
  //     setUploadingFile(updateUploadingFile);

  //     const updateUploadingSignature = [...uploadingSignature];
  //     updateUploadingSignature[index] = true;
  //     setUploadingSignature(updateUploadingSignature);

  //     console.log("formData", signature[index]);
  //     const response = await axios.post(corporationCertificateAPI, formData, {
  //       headers: {
  //         authorization: cookies.get("token"),
  //         companyType: "corporation",
  //       },
  //     });
  //     console.log("response", response);
  //     const updateSignatureURL = [...signatureURL];
  //     updateSignatureURL[index] = response.data.data.url;
  //     setSignatureURL(updateSignatureURL);

  //     updateSignatureError = [...signatureError];
  //     updateSignatureError[index] = "";
  //     setSignatureError(updateSignatureError);

  //     const updateSignatureUploded = [...signatureUploded];
  //     updateSignatureUploded[index] = "Uploaded Successfully";
  //     setSignatureUploded(updateSignatureUploded);

  //     setValue(`boardMembers[${index}].Signature`, response.data.data.url);

  //     clearErrors(`boardMembers[${index}].Signature`);
  //   } catch (error) {
  //     console.error("Error uploading image:", error);

  //     const updateSignatureError = [...signatureError];
  //     updateSignatureError[index] = "Failed to upload image";
  //     setSignatureError(updateSignatureError);

  //     const updateSignatureUploded = [...signatureUploded];
  //     updateSignatureUploded[index] = "";
  //     setSignatureUploded(updateSignatureUploded);

  //     const updateSignatureURL = [...signatureURL];
  //     updateSignatureURL[index] = "";
  //     setSignatureURL(updateSignatureURL);

  //     setValue(`boardMembers[${index}].Signature`, "");
  //     clearErrors(`boardMembers[${index}].Signature`);
  //   } finally {
  //     const updateUploadingFile = [...uploadingFile];
  //     updateUploadingFile[index] = false;
  //     setUploadingFile(updateUploadingFile);

  //     const updateUploadingSignature = [...uploadingSignature];
  //     updateUploadingSignature[index] = false;
  //     setUploadingSignature(updateUploadingSignature);
  //   }
  // };

  watch("boardMembers");

  return (
    <div>
      {/* Add Board Members */}
      <div className="flex gap-3 items-center mb-3">
        <div
          id="addboardMembers"
          className="cursor-pointer flex gap-3 hover:opacity-80"
          onClick={handleAddBoardMembers}
        >
          <Icon icon="bi:plus-square" className="text-secondaryl text-2xl" />
          <label className="cursor-pointer text-secondaryl ">
            Add more Board Member
          </label>
        </div>
      </div>

      {/* Property Managers structure */}

      <div className="">
        {Array.from({ length: boardMembersCount }).map((_, index) => (
          <div key={index}>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
              <div className="mb-3 w-full">
                <label
                  htmlFor={`Board_Member_Name-${index}`}
                  className="block font-normal text-secondaryl"
                >
                  Board Member {index + 1} Name
                  <sup className="text-red-500 text-base">*</sup>
                </label>
                <input
                  id={`Board_Member_Name-${index}`}
                  name={`boardMembers[${index}].Board_Member_Name`}
                  type="text"
                  {...register(`boardMembers[${index}].Board_Member_Name`)}
                  className={`w-full  block  h-11 rounded-sm text-black px-2 focus:ring-[0.5px] ps-10 focus:ring-secondaryl focus:outline-none ${
                    isEdit[index]
                      ? "bg-primaryl/[10%] cursor-not-allowed"
                      : "bg-[#FFFFFF80]"
                  }`}
                  onChange={(e) =>
                    handleBoardMembersChange(
                      index,
                      "Board_Member_Name",
                      e.target.value
                    )
                  }
                  disabled={isEdit[index]}
                />

                <div className="h-[2vh] pt-1">
                  {errors["boardMembers"] &&
                    errors["boardMembers"][index] &&
                    errors["boardMembers"][index]["Board_Member_Name"] && (
                      <p className="text-red-500 text-xs leading-none">
                        {
                          errors["boardMembers"][index]["Board_Member_Name"]
                            .message
                        }
                      </p>
                    )}
                </div>
              </div>

              <div className="mb-3 w-full">
                <label
                  htmlFor={`Board_Member_Phone_No-${index}`}
                  className="block font-normal text-secondaryl"
                >
                  Phone No
                  <sup className="text-red-500 text-base">*</sup>
                </label>
                <input
                  id={`Board_Member_Phone_No-${index}`}
                  name={`boardMembers[${index}].Board_Member_Phone_No`}
                  type="tel"
                  {...register(`boardMembers[${index}].Board_Member_Phone_No`)}
                  maxLength="10"
                  min={0}
                  max={10}
                  onKeyDown={handleKeyDown}
                  className={`w-full  block  h-11 rounded-sm text-black px-2 focus:ring-[0.5px] ps-10 focus:ring-secondaryl focus:outline-none ${
                    isEdit[index]
                      ? "bg-primaryl/[10%] cursor-not-allowed"
                      : "bg-[#FFFFFF80]"
                  }`}
                  onChange={(e) =>
                    handleBoardMembersChange(
                      index,
                      "Board_Member_Phone_No",
                      e.target.value
                    )
                  }
                  disabled={isEdit[index]}
                />
                <div className="h-[2vh] pt-1">
                  {errors["boardMembers"] &&
                    errors["boardMembers"][index] &&
                    errors["boardMembers"][index]["Board_Member_Phone_No"] && (
                      <p className="text-red-500 text-xs leading-none">
                        {
                          errors["boardMembers"][index]["Board_Member_Phone_No"]
                            .message
                        }
                      </p>
                    )}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
              <div className="mb-3 w-full">
                <label
                  htmlFor={`Board_Member_Email-${index}`}
                  className="block font-normal text-secondaryl"
                >
                  Board Member {index + 1} Email
                  <sup className="text-red-500 text-base">*</sup>
                </label>
                <input
                  id={`Board_Member_Email-${index}`}
                  name={`boardMembers[${index}].Board_Member_Email`}
                  type="email"
                  {...register(`boardMembers[${index}].Board_Member_Email`)}
                  onChange={(e) =>
                    handleBoardMembersChange(
                      index,
                      "Board_Member_Email",
                      e.target.value
                    )
                  }
                  className={`w-full  block  h-11 rounded-sm text-black px-2 focus:ring-[0.5px] ps-10 focus:ring-secondaryl focus:outline-none ${
                    getValues(`boardMembers[${index}].Signature`)
                      ? "bg-primaryl/[10%] cursor-not-allowed"
                      : isEdit[index]
                      ? "bg-primaryl/[10%] cursor-not-allowed"
                      : "bg-[#FFFFFF80]"
                  }`}
                  disabled={
                    getValues(`boardMembers[${index}].Signature`)
                      ? true
                      : isEdit[index]
                      ? true
                      : false
                  }
                />
                <div className="h-[2vh] pt-1">
                  {errors["boardMembers"] &&
                    errors["boardMembers"][index] &&
                    errors["boardMembers"][index]["Board_Member_Email"] && (
                      <p className="text-red-500 text-xs leading-none">
                        {
                          errors["boardMembers"][index]["Board_Member_Email"]
                            .message
                        }
                      </p>
                    )}
                </div>
              </div>

              <div className="mb-3 w-full">
                <label
                  htmlFor={`Designation-${index}`}
                  className="block font-normal text-secondaryl"
                >
                  Board Member {index + 1} Designation
                  <sup className="text-red-500 text-base">*</sup>
                </label>
                <input
                  id={`Designation-${index}`}
                  name={`boardMembers[${index}].Designation`}
                  type="text"
                  {...register(`boardMembers[${index}].Designation`)}
                  onChange={(e) =>
                    handleBoardMembersChange(
                      index,
                      "Designation",
                      e.target.value
                    )
                  }
                  className={`w-full  block  h-11 rounded-sm text-black px-2 focus:ring-[0.5px] ps-10 focus:ring-secondaryl focus:outline-none ${
                    isEdit[index]
                      ? "bg-primaryl/[10%] cursor-not-allowed"
                      : "bg-[#FFFFFF80]"
                  }`}
                  disabled={isEdit[index]}
                />
                <div className="h-[2vh] pt-1">
                  {errors["boardMembers"] &&
                    errors["boardMembers"][index] &&
                    errors["boardMembers"][index]["Designation"] && (
                      <p className="text-red-500 text-xs leading-none">
                        {errors["boardMembers"][index]["Designation"].message}
                      </p>
                    )}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 md:gap-16 w-full">
              <div className="mb-4 w-full text-secondaryl">
                {getValues(`boardMembers[${index}].Signature`) &&
                  getValues(`boardMembers[${index}].ID`) && (
                    <p className="flex flex-wrap gap-1 items-center">
                      Verification Successful{" "}
                      <ApprovedIcon className="text-secondaryl" />{" "}
                    </p>
                  )}
                {!getValues(`boardMembers[${index}].Signature`) &&
                  getValues(`boardMembers[${index}].ID`) && (
                    <p className="flex flex-wrap gap-1 items-cente">
                      Verification Pending <CountDay color="red" />
                    </p>
                  )}
              </div>
              <div className="mb-4 w-full flex justify-end  gap-5">
                {isEdit[index] ? (
                  <div
                    onClick={() => {
                      if (cookies.get("status") === 2) {
                        return toast.error("profile paused");
                      } else {
                        return handleEditBoardMembers(index);
                      }
                    }}
                    className={`h-11 w-full max-w-32 text-sm px-6 py-2.5 euclidbold  ring-1 ring-secondaryl flex justify-center items-center text-secondaryl sm:text-xl tracking-widest hover:bg-secondaryl rounded-md hover:text-white transition duration-300 ease-in-out md:mt-0 cursor-pointer`}
                  >
                    EDIT
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      getValues(`boardMembers[${index}].ID`)
                        ? handleUpdateBoardMembers(index)
                        : handleSaveBoardMembers(index)
                    }
                    className={`h-11 w-full max-w-32 text-sm px-6 py-2.5 euclidbold  ring-1 ring-secondaryl flex justify-center items-center text-secondaryl sm:text-xl tracking-widest hover:bg-secondaryl rounded-md hover:text-white transition duration-300 ease-in-out md:mt-0 cursor-pointer`}
                  >
                    {getValues(`boardMembers[${index}].ID`) ? "SAVE" : "ADD"}
                  </div>
                )}

                <div
                  onClick={() => {
                    if (cookies.get("status") === 2) {
                      return toast.error("profile paused");
                    } else {
                      return getValues(`boardMembers[${index}].ID`)
                        ? handleDeleteBoardMemeberById(index)
                        : handleDeleteBoardMembers(index);
                    }
                  }}
                  className={`h-11 w-full max-w-32 text-sm px-6 py-2.5 euclidbold  ring-1 ring-secondaryl flex justify-center items-center text-secondaryl sm:text-xl tracking-widest hover:bg-secondaryl rounded-md hover:text-white transition duration-300 ease-in-out md:mt-0 cursor-pointer`}
                >
                  DELETE
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageBoardMembers;
