import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import Cookies from "universal-cookie";

const BlogSkeleton = () => {
  const cookies = new Cookies();
  const [isLogin, setIsLogin] = useState(cookies.get("companyType"));
  return (
    <div
      className={`grid ${
        isLogin
          ? "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
          : "sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4"
      }  gap-5 mt-4 justify-center`}
    >
      {Array(8)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className="w-full  max-w-sm min-w-sm  rounded overflow-hidden  ring-[0.5px] ring-[#3E5CAAB2] p-2"
          >
            <Skeleton width="100%" height={192} />

            <div className="pt-3  text-center">
              <Skeleton width="70%" height={24} />
            </div>
            <div className="py-1 text-center">
              <Skeleton width="45%" height={20} />
            </div>

            <div className="py-1 text-center">
              <Skeleton width="60%" height={45} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default BlogSkeleton;
