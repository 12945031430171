import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import toast from "react-hot-toast";
import { serviceCorporationApi } from "../../../../../constants/API";
import ServiceViewModel from "./ServiceViewModel";
import { Icon } from "@iconify/react/dist/iconify.js";

const ServiceCard = ({
  service,
  setShowEditService,
  setService,
  loading,
  fetchData,
  fetchServices,
  setData,
  setFilterData,
  setLoad,
}) => {
  const cookies = new Cookies();
  const [viewService, setviewService] = useState({});
  const [showServiceViewModel, setShowServiceViewModel] = useState(false);
  const notifysuccess = (props) => toast.success(props, { duration: 4000 });
  const notifyfailure = (props) => toast.error(props, { duration: 2000 });

  const [serviceBookFromDate, setServiceBookFromDate] = useState("");
  const [serviceBookToDate, setServiceBookToDate] = useState("");
  const [serviceBookFromTime, setServiceBookFromTime] = useState("");
  const [serviceBookToTime, setServiceBookToTime] = useState("");

  useEffect(() => {
    if (service.Book_From) {
      const date = new Date(service.Book_From);
      setServiceBookFromDate(date.toLocaleDateString());
      setServiceBookFromTime(
        date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );
    }
    if (service.Book_To) {
      const date = new Date(service.Book_To);
      setServiceBookToDate(date.toLocaleDateString());
      setServiceBookToTime(
        date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );
    }
  }, []);

  const deleteService = async (id) => {
    setLoad(true);
    try {
      const response = await axios.delete(`${serviceCorporationApi}/${id}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      if (response.data.isDeleted) {
        // Update the data array by removing the deleted sub-service
        setData((prevData) => {
          const updatedData = prevData.filter((service) => {
            return service.ID !== id;
          });
          return updatedData;
        });
        // Update the filterData array similarly
        setFilterData((prevData) => {
          const updatedData = prevData.filter((service) => {
            return service.ID !== id;
          });
          return updatedData;
        });
      }
      notifysuccess("Service deleted Successfully", { duration: 4000 });
    } catch (error) {
      // console.log(error);
      notifyfailure(
        error.message || "Failed to delete service. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const handleUpdateRequest = async (status, id) => {
    setLoad(true);
    try {
      await axios.put(
        `${serviceCorporationApi}/request/${id}`,
        { status },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      fetchData();
      notifysuccess("Service updated Successfully", { duration: 4000 });
    } catch (error) {
      // console.log(error);
      notifyfailure(
        error.message || "Failed to approve service. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const showServiceHandler = (from, serviceData) => {
    if (from) {
      setShowServiceViewModel(true);
      setviewService(serviceData);
    } else {
      setShowServiceViewModel(true);
      setviewService({
        Service_Name: serviceData.ServiceCorporation.Service_Name,
        Service_Description: serviceData.ServiceCorporation.Service_Description,
        Service_Image: serviceData?.ServiceCorporation?.Service_Image,
        Book_From_Date: serviceBookFromDate,
        Book_From_Time: serviceBookFromTime,
        Book_To_Date: serviceBookToDate,
        Book_To_Time: serviceBookToTime,
        ID: serviceData.ID,
        Status: serviceData.Status,
      });
    }
  };

  return (
    <>
      {showServiceViewModel && viewService && (
        <ServiceViewModel
          service={viewService}
          setShowServiceViewModel={setShowServiceViewModel}
        />
      )}
      {!service.ServiceCorporation ? (
        <div
          className="w-full  flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
          style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
        >
          {/* Card Images  */}
          <div className="h-full relative lg:w-[20%] flex-none bg-cover rounded-md text-center flex justify-center">
            {service && service.Service_Image ? (
              <img
                src={service?.Service_Image}
                alt={service?.Service_Name}
                className="lg:h-36 lg:w-52  rounded-md"
              />
            ) : (
              <div className="h-32 flex items-center"> No Image </div>
            )}
          </div>
          {/* Card Content */}

          <div className="flex-col lg:w-[60%] justify-center leading-normal">
            {" "}
            <div className="space-y-1.5">
              {/* Title */}
              <p className="text-secondaryl euclidbold text-2xl ">
                {service?.Service_Name}
              </p>
              {/* Location */}
              <div className="text-base flex gap-1 items-center ml-[1px]">
                <p className="text-infromation line-clamp-3">
                  {" "}
                  {service.Service_Description}
                </p>
              </div>
            </div>
          </div>
          {/* Card Buttons */}
          <div className="flex-col justify-center my-auto relative w-auto lg:w-[20%] space-y-3">
            {cookies.get("access") === "property manager" && (
              <>
                <div
                  onClick={() => {
                    setShowEditService(true);
                    setService(service);
                  }}
                  className="h-11 w-full px-2 uppercase py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                >
                  Edit
                </div>
                <div
                  onClick={() => deleteService(service.ID)}
                  className="h-11 w-full px-2 uppercase py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                >
                  Delete
                </div>
              </>
            )}
            <div
              onClick={() => showServiceHandler(true, service)}
              className="h-11 w-full px-2 py-2.5 uppercase euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
            >
              VIEW
            </div>
          </div>
        </div>
      ) : (
        <div
          className="w-full  flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
          style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
        >
          {/* Card Images  */}
          <div className="h-full relative lg:w-[20%] flex-none bg-cover rounded-md text-center flex justify-center">
            {service && service.ServiceCorporation?.Service_Image ? (
              <img
                src={service.ServiceCorporation?.Service_Image}
                alt={service.ServiceCorporation?.Service_Name}
                className="lg:h-36 lg:w-52  rounded-md"
              />
            ) : (
              <div className="h-32 flex items-center"> No Image </div>
            )}
          </div>
          {/* Card Content */}

          {/* Card Content */}
          <div className="flex-col lg:w-[60%] justify-center leading-normal">
            <div className="space-y-1.5">
              <div className="text-secondaryl euclidbold text-2xl line-clamp-1">
                {service.ServiceCorporation.Service_Name}
              </div>
              <div className="py-1 space-y-1.5">
                <div className="text-xl flex gap-1  font-bold items-center line-clamp-1">
                  <Icon
                    icon="carbon:location-filled"
                    className="text-secondaryl  text-lg"
                  />
                  <p className="text-secondaryl">
                    {serviceBookFromDate + " To " + serviceBookToDate}
                  </p>
                </div>
                <div className="text-base flex gap-1  font-bold  items-center line-clamp-1">
                  <Icon
                    icon="carbon:location-filled"
                    className="text-secondaryl text-lg"
                  />
                  <p className="text-secondaryl">
                    {serviceBookFromTime + " To " + serviceBookToTime}
                  </p>
                </div>
              </div>

              {service.Status === 1 && (
                <div className="text-sm flex gap-1 items-center text-[#00A701]">
                  <Icon icon="duo-icons:approved" className=" text-lg" />
                  <p className="">Approved</p>
                </div>
              )}

              {service.Status === 2 && (
                <div className="text-sm flex gap-1 items-center text-[#EB3333]">
                  <Icon icon="tabler:cancel" className=" text-lg" />
                  <p className="">Reject</p>
                </div>
              )}

              {service.Status === 0 && (
                <div className="text-sm flex gap-1 items-center text-[#FFA640]">
                  <Icon icon="lsicon:minus-filled" className=" text-lg" />
                  <p className="">Pending</p>
                </div>
              )}
            </div>
          </div>
          {/* Card Buttons */}
          <div className="flex-col justify-center my-auto relative w-auto lg:w-[20%] space-y-3">
            {cookies.get("access") === "property manager" &&
              service.Status === 0 && (
                <>
                  <div
                    onClick={() => handleUpdateRequest(1, service.ID)}
                    className="h-11 w-full px-2 uppercase py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                  >
                    Approve
                  </div>
                  <div
                    onClick={() => handleUpdateRequest(2, service.ID)}
                    className="h-11 w-full px-2 uppercase py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                  >
                    Reject
                  </div>
                </>
              )}
            <div
              onClick={() => showServiceHandler(false, service)}
              className="h-11 w-full px-2 py-2.5 uppercase euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
            >
              VIEW
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceCard;
