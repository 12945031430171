import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useLoading } from "../../../../context/LoadingContext";
import { corporationAPI, ownerAPI, tenantAPI } from "../../../../constants/API";
import axios from "axios";
import CountUp from "react-countup";

function Overview() {
  const cookies = new Cookies();
  const { load } = useLoading();
  const [isOwner, setIsOwner] = useState(false);
  const navigate = useNavigate();

  const [ownerDashboard, setOwnerDashboard] = useState({
    Create: { value: 0 },
    "Total Request": { value: 0 },
    "Pending Request": { value: 0 },
    "Approve Request": { value: 0 },
    "Rejected Request": { value: 0 },
  });

  const [tenantDashboard, setTenantDashboard] = useState({
    Create: { value: 0 },
    "Total Request": { value: 0 },
    "Pending Request": { value: 0 },
    "Approve Request": { value: 0 },
    "Rejected Request": { value: 0 },
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${corporationAPI}/overviewdashboard`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      const data = response.data.data;
      setOwnerDashboard(data.owners);
      setTenantDashboard(data.tenant);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="rounded-2xl ">
      {/* <Loading load={load} /> */}

      <div className="w-full  rounded-2xl px-4 m-auto p-6 flex flex-col">
        <div className="flex flex-wrap gap-5 justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            Dashboard
          </h1>
        </div>

        <div className="w-full py-3">
          <p htmlFor="" className="text-lg text-secondaryl font-medium pb-2">
            Owners
          </p>
          <div className="flex flex-wrap gap-5 w-full justify-center md:justify-start">
            {Object.entries(ownerDashboard).map(([key, request], index) => (
              <div
                key={index}
                className="bg-[#FFFFFF80] h-40 w-52 flex flex-col flex-grow justify-around items-center rounded"
                style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
              >
                <label
                  htmlFor=""
                  className="text-2xl text-secondaryl euclidsemibold"
                >
                  {key}
                </label>
                <div className="text-2xl text-secondaryl  euclidsemibold">
                  <CountUp
                    start={0}
                    end={request.value}
                    duration={2.5}
                    separator=","
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full py-3">
          <label
            htmlFor=""
            className="text-lg text-secondaryl font-medium pb-2"
          >
            Tenant
          </label>
          <div className="flex flex-wrap gap-5 w-full justify-center md:justify-start">
            {Object.entries(tenantDashboard).map(([key, request], index) => (
              <div
                key={index}
                className="bg-[#FFFFFF80] h-40 w-52 flex flex-col flex-grow  justify-around items-center rounded"
                style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
              >
                <label
                  htmlFor=""
                  className="text-2xl text-secondaryl euclidsemibold"
                >
                  {key}
                </label>
                <div className="text-2xl text-secondaryl  euclidsemibold">
                  <CountUp
                    start={0}
                    end={request.value}
                    duration={2.5}
                    separator=","
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
