import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../constants/API";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { viewFileHandler } from "../../constants/Request";

const ContractorDetailsModal = ({
  isOpen,
  onClose,
  contractor,
  setLoad,
  fetchContractorData,
}) => {
  const cookies = new Cookies();
  // console.log("contractor", contractor);
  const [cities, setCities] = useState([]);
  const [services, setServices] = useState([]);
  const [profilephoto, setProfilePhoto] = useState("");
  const [businesscard, setBusinessCard] = useState("");
  const [insurancecertificateurl, setInsuranceCertificateUrl] = useState("");
  /*
  const handleDelete = async () => {
    setLoad(true);
    try {
      const res = await axios.delete(
        `${baseUrl}/contractor/${contractor?.ID}`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      toast.success("Contractor deleted successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting contractor:", error);
    } finally {
      setLoad(false);
    }
  };

  const handleStatusChange = async (newStatus) => {
    setLoad(true);
    try {
      const res = await axios.put(
        `${baseUrl}/admin/contractor/${contractor?.ID}`,
        {
          Verification_Status: newStatus,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      // setStatus(newStatus);
      fetchContractorData();
      onClose();
      toast.success(`Contractor Status Updated`);
      // Perform any additional actions after successful status change, like updating the UI
    } catch (error) {
      console.error(`Error updating status to ${newStatus}:`, error);
      toast.error("Failed to update contractor status");
      // Handle error gracefully, show error message, etc.
    } finally {
      setLoad(false);
    }
  };*/

  const fetchcontractorCityareas = async (id) => {
    try {
      // console.log(id);
      const response = await axios.get(`${baseUrl}/cityarea/contractor/${id}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "admin",
        },
      });
      setCities(response.data.data);
    } catch (error) {
      // console.log(error);
    }
  };
  const fetchcontractorServices = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/service/contractor/${id}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "admin",
        },
      });
      setServices(response.data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchcontractorCityareas(contractor?.ID);
    fetchcontractorServices(contractor?.ID);
  }, [contractor?.ID]);

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75 text-red-600 font-bold cursor-pointer">
            X
          </div>
        </div>

        <div
          className="inline-block z-50 w-[90vw] md:w-[80vw] align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 w-[80vw] lg:w-auto">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 flex flex-wrap items-center justify-evenly gap-x-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Contractor Details
                </h3>
                {contractor?.Verification_Status ? (
                  <div className="text-green-500">Verified</div>
                ) : (
                  <div className="text-red-500">Not Verified/Rejected</div>
                )}
                <div className="w-full flex flex-wrap gap-x-12 mt-4 lg:gap-4 lg:justify-start  lg:items-center">
                  <div>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">First Name:</span>{" "}
                      {contractor?.First_Name || "NA"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Last Name:</span>{" "}
                      {contractor?.Last_Name || "NA"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Email:</span>{" "}
                      {contractor?.Uid_Or_Email || "NA"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Company Name:</span>{" "}
                      {contractor?.Company_Name || "NA"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Phone No:</span>{" "}
                      {contractor?.Phone_No || "NA"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Company Address:</span>{" "}
                      {contractor?.Company_Address || "NA"}
                    </p>
                  </div>
                  <div>
                    {" "}
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Designation:</span>{" "}
                      {contractor?.Designation || "NA"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Company Fax No:</span>{" "}
                      {contractor?.Company_Fax_No || "NA"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Insurance:</span>{" "}
                      {contractor?.Insurance || "NA"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Other Service:</span>{" "}
                      {contractor?.Other_Service || "NA"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Phone No:</span>{" "}
                      {contractor?.Phone_No || "NA"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Recovery Email:</span>{" "}
                      {contractor?.Recovery_Email || "NA"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Term And Policy:</span>{" "}
                      {contractor?.Term_And_Policy ? "True" : "False"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Working Hours From:</span>{" "}
                      {contractor?.Working_Hours_From || "NA"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Working Hours To:</span>{" "}
                      {contractor?.Working_Hours_To || "NA"}
                    </p>
                    <div className="flex gap-8">
                      <div>
                        Contractor Cities
                        {cities.map((city, index) => (
                          <p
                            key={index}
                            className="text-sm text-gray-500 whitespace-nowrap"
                          >
                            <span className="font-bold">Name:</span> {city.name}
                          </p>
                        ))}
                      </div>
                      <div>
                        Contractor Services
                        {services.map((service, index) => (
                          <p
                            key={index}
                            className="text-sm text-gray-500 whitespace-nowrap"
                          >
                            <span className="font-bold">Name:</span>
                            {service.name}
                          </p>
                        ))}
                      </div>
                    </div>

                    {contractor?.Company_Business_Card && (
                      <Link
                        to={contractor?.Company_Business_Card}
                        target="_blank"
                        className="text-sm text-gray-500 hover:text-cyan-500"
                      >
                        View Business Card
                      </Link>
                    )}
                    {contractor?.Profile_Photo && (
                      <Link
                        to={contractor?.Profile_Photo}
                        target="_blank"
                        className=" m-2 text-sm text-gray-500 hover:text-cyan-500"
                      >
                        View Profile Photo
                      </Link>
                    )}

                    {contractor?.Insurance_Certificate_URL && (
                      <Link
                        to={contractor?.Insurance_Certificate_URL}
                        target="_blank"
                        className=" m-2 text-sm text-gray-500 hover:text-cyan-500"
                      >
                        View Insurance Certificate
                      </Link>
                    )}
                    {/* <p className="text-sm text-gray-500">
                      <span className="font-bold">Verification Status:</span>{" "}
                      {contractor?.Verification_Status
                        ? "Verified"
                        : "Not Verified"}
                    </p> */}
                  </div>
                  {/* {contractor?.Verification_Status === null && (
                    <div className="flex flex-col gap-2 justify-end items-end w-full">
                      <button
                        className={`bg-green-500 text-white px-3 py-1 rounded-full mr-2`}
                        onClick={() => handleStatusChange(1)}
                      >
                        Approve
                      </button>
                      <button
                        className={`bg-red-500 text-white px-3 py-1 rounded-full mr-2`}
                        onClick={() => handleStatusChange(0)}
                      >
                        Reject
                      </button>
                    </div>
                  )}
                  {contractor?.Verification_Status === true && (
                    <div className="flex flex-row justify-end items-end gap-2 w-full">
                      <button
                        className={`bg-red-500 text-white px-3 py-1 rounded-full mr-2`}
                        onClick={() => handleStatusChange(0)}
                      >
                        Reject
                      </button>
                      <button
                        className={`bg-orange-500 text-white px-3 py-1 rounded-full mr-2`}
                        onClick={() => handleStatusChange(2)}
                      >
                        Pause
                      </button>
                    </div>
                  )}
                  {contractor?.Verification_Status === false && (
                    <div className="flex flex-col justify-end items-end w-full">
                      <button
                        className={`bg-green-500 text-white px-3 py-1 rounded-full mr-2`}
                        onClick={() => handleStatusChange(1)}
                      >
                        Approve
                      </button>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-500 text-base font-medium text-white hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorDetailsModal;
