import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReadInputBox from "../../../Elements/ReadInputBox";
import ReadTextArea from "../../../Elements/ReadTextArea";
import { viewFileHandler } from "../../../../constants/Request";
import { Icon } from "@iconify/react";
import { momSchema } from "../../../../schema";

function MeetingRequestView({ meeting, onClose }) {
  const [meetingDocument, setMeetingDocument] = useState(null);
  const [meetingDocumentType, setMeetingDocumentType] = useState(null);
  const [role, setRole] = useState("owner"); // default to owner

  // Initialize react-hook-form with yup validation
  const {
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(momSchema),
  });

  // Load MoM data into the form when the component mounts
  useEffect(() => {
    if (meeting) {
      setRole(meeting.Owner ? "owner" : "tenant");
      reset({
        Meeting_Topic: meeting.Meeting?.Meeting_Topic || "",
        Meeting_Details: meeting.Meeting?.Meeting_Details || "",
        Meeting_Outcome: meeting.Meeting?.Meeting_Outcome || "",
        Meeting_Date: meeting.Meeting?.Meeting_Date || "",
        First_Name: meeting.Owner
          ? meeting.Owner?.First_Name || ""
          : meeting.Tenant?.First_Name || "", // Tenant-specific data
        Last_Name: meeting.Owner
          ? meeting.Owner?.Last_Name || ""
          : meeting.Tenant?.Last_Name || "",
        Phone_No: meeting.Owner
          ? meeting.Owner?.Phone_No || ""
          : meeting.Tenant?.Phone_No || "",
        Residential_Address: meeting.Owner
          ? meeting.Owner?.Residential_Address || ""
          : meeting.Tenant?.Residential_Address || "",
      });

      loadMeetingDocument();
    }
  }, [meeting, reset]);

  // Load and display MoM document
  const loadMeetingDocument = async () => {
    const documentUrl = meeting?.Meeting?.Meeting_Document;
    if (documentUrl) {
      if (documentUrl.endsWith(".pdf")) {
        const fileUrl = await viewFileHandler(documentUrl, "meetings");
        setMeetingDocumentType("pdf");
        setMeetingDocument(fileUrl);
      } else {
        setMeetingDocumentType("image");
        setMeetingDocument(documentUrl);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-30">
      <style>{`body { overflow: hidden; }`}</style>
      <div className="flex justify-center items-center h-full">
        <div className="bg-white rounded-2xl w-[80%] h-[80%] overflow-auto">
          <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8">
            {/* Header */}
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-secondaryl font-extrabold text-3xl euclidbold">
                MoM Details ({role})
              </h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <Icon
                  icon="carbon:close-filled"
                  className="text-secondaryl hover:text-opacity-95 text-3xl"
                />
              </button>
            </div>

            {/* MoM Data Form */}
            <form>
              {/* Owner/Tenant Details */}
              <div className="my-4 rounded-md">
                <h3 className="text-xl font-semibold text-secondaryl mb-2">
                  {role === "owner" ? "Owner" : "Tenant"} Details
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="">
                    <ReadInputBox
                      title={`${
                        role === "owner" ? "Owner" : "Tenant"
                      } First Name`}
                      register={register}
                      filedName="First_Name"
                      cursorNotAllowed={false}
                    />
                  </div>
                  <div className="">
                    <ReadInputBox
                      title={`${
                        role === "owner" ? "Owner" : "Tenant"
                      } Last Name`}
                      register={register}
                      filedName="Last_Name"
                      cursorNotAllowed={false}
                    />
                  </div>
                  <div className="">
                    <ReadInputBox
                      title="Phone Number"
                      register={register}
                      filedName="Phone_No"
                      cursorNotAllowed={false}
                    />
                  </div>
                  <div className="">
                    <ReadInputBox
                      title="Property Address"
                      register={register}
                      filedName="Residential_Address"
                      cursorNotAllowed={false}
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* MoM Topic */}

                <ReadInputBox
                  title="MoM Topic"
                  register={register}
                  filedName="Meeting_Topic"
                  cursorNotAllowed={false}
                />

                {/* MoM Date */}

                <ReadInputBox
                  title="MoM Date"
                  register={register}
                  filedName="Meeting_Date"
                  cursorNotAllowed={false}
                />
              </div>

              <div className="grid grid-cols-1">
                <div className="">
                  <ReadTextArea
                    title="MoM Details"
                    register={register}
                    filedName="Meeting_Details"
                    cursorNotAllowed={false}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1">
                {" "}
                {/* MoM Outcome */}
                <div className="md:col-span-2">
                  <ReadTextArea
                    title="MoM Outcome"
                    register={register}
                    filedName="Meeting_Outcome"
                    cursorNotAllowed={false}
                    error={errors.Meeting_Outcome?.message}
                  />
                </div>
              </div>

              {/* Uploaded Document */}
              <div className="mb-3">
                <label className="block font-normal text-secondaryl text-[12px] sm:text-[12px] md:text-base">
                  Uploaded MoM Document:
                </label>

                <div className="pt-3">
                  {meetingDocument ? (
                    meetingDocumentType === "pdf" ? (
                      <div className="relative">
                        <object
                          data={`${meetingDocument}`}
                          type="application/pdf"
                          width="100%"
                          height="600px"
                          className="w-full h-[90vh] cursor-not-allowed user-select-none"
                          onContextMenu={(e) => e.preventDefault()}
                        >
                          <p>PDF could not be displayed</p>
                        </object>
                      </div>
                    ) : (
                      <img
                        src={meetingDocument}
                        alt="MoM Document"
                        className="w-full h-[50vh] object-fill ring-[0.1px] ring-information"
                      />
                    )
                  ) : (
                    <div className="text-information">No Document Provided</div>
                  )}
                </div>
              </div>

              {/* Close Button */}
              <div className="flex justify-start mt-6">
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetingRequestView;
