import InputBox from "../../../../components/Elements/InputBox";
import { Icon } from "@iconify/react";
import { InputFileIcon, Spinner } from "../../../../assets/images";
import { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useLoading } from "../../../../context/LoadingContext";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  serviceCorporationApi,
  subServiceCorporationApi,
} from "../../../../constants/API";
import { serviceSchema } from "../../../../schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { set, useForm } from "react-hook-form";
import { object } from "yup";
import Loading from "../../../Elements/Loading";

const EditService = ({
  setShowEditService,
  service,
  setService,
  fetchServices,
  setLoad,
  setFilterData,
  setData,
  filterData,
  data,
}) => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(serviceSchema) });
  const cookies = new Cookies();
  const navigate = useNavigate();
  const notifysuccess = (props) => toast.success(props, { duration: 4000 });
  const notifyfailure = (props) => toast.error(props, { duration: 2000 });
  const [subServiceCount, setSubServiceCount] = useState(0);
  const [Service_Image, setService_Image] = useState(null); // For main service image
  const [displayService_Image, setDisplayService_Image] = useState(null); // Display main service image

  const [subServiceImages, setSubServiceImages] = useState(Array(6).fill(null));
  const [displaySubServiceImages, setDisplaySubServiceImages] = useState(
    Array(6).fill(null)
  );
  const [Service_ImageError, setService_ImageError] = useState("");
  const [Service_ImageUploading, setService_ImageUploading] = useState(false);

  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"]; // Example supported file types
  const handleChangeService_Image = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      file.size <= FILE_SIZE_LIMIT &&
      SUPPORTED_FILE_TYPES.includes(file.type)
    ) {
      setService_Image(file);
      setDisplayService_Image(URL.createObjectURL(file));
    } else {
      setService_ImageError(
        "File type should be .jpeg, .jpg, .png and size less than 5MB."
      );
    }
  };

  const handleChangeSubServiceImage = (event, index) => {
    const file = event.target.files[0];
    if (
      file &&
      file.size <= FILE_SIZE_LIMIT &&
      SUPPORTED_FILE_TYPES.includes(file.type)
    ) {
      setSubServiceImages((prev) => {
        const updatedImages = [...prev];
        updatedImages[index] = file;
        return updatedImages;
      });
      setDisplaySubServiceImages((prev) => {
        const updatedDisplayImages = [...prev];
        updatedDisplayImages[index] = URL.createObjectURL(file);
        return updatedDisplayImages;
      });
    } else {
      setService_ImageError(
        "File type should be .jpeg, .jpg, .png and size less than 5MB."
      );
    }
  };

  const handleDeleteSubService = async (subServiceId, serviceId) => {
    const formData = new FormData();
    const subServices = getValues("Sub_Services");
    const subService = subServices.find((key) => key.ID === subServiceId);
    // console.log(subService);
    formData.append("Sub_Service_ID", subService.ID);

    setLoad(true);
    try {
      const response = await axios.delete(
        `${subServiceCorporationApi}/${subService.ID}`,
        formData,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      if (response.data.isDeleted) {
        // Update the data array by removing the deleted sub-service
        setData((prevData) => {
          const updatedData = prevData.map((item) => {
            if (item.ID === serviceId) {
              return {
                ...item,
                SubServiceCorporations: item.SubServiceCorporations.filter(
                  (subService) => subService.ID !== subServiceId
                ),
              };
            }
            return item;
          });
          return updatedData;
        });

        // Update the filterData array similarly
        setFilterData((prevData) => {
          const updatedData = prevData.map((item) => {
            if (item.ID === serviceId) {
              return {
                ...item,
                SubServiceCorporations: item.SubServiceCorporations.filter(
                  (subService) => subService.ID !== subServiceId
                ),
              };
            }
            return item;
          });
          return updatedData;
        });
      }
      notifysuccess("Sub service Update Successfully", { duration: 4000 });
      reset();
      setShowEditService(false);
      setService({});
      navigate("/corporations/dashboard/manageservice");
    } catch (error) {
      // console.log(error);
      notifyfailure(
        error.message || "Failed to Create Contract. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const handleRemoveServiceImage = () => {
    setService_Image(null);
    setDisplayService_Image(null);
  };

  const handleRemoveSubServiceImage = (index) => {
    setSubServiceImages((prev) => {
      const updatedImages = [...prev];
      updatedImages[index] = null; // or empty string "" if you prefer
      return updatedImages;
    });

    setDisplaySubServiceImages((prev) => {
      const updatedDisplayImages = [...prev];
      updatedDisplayImages[index] = null; // or remove the image from display
      return updatedDisplayImages;
    });
  };

  const handleEditSubService = async (subServiceId, index) => {
    const formData = new FormData();
    const subServices = getValues("Sub_Services");
    const subService = subServices.find((key) => key.ID === subServiceId);
    const subServiceImage = subServiceImages[index];

    formData.append("Sub_Service_Name", subService.Sub_Service_Name);
    formData.append(
      "Sub_Service_Description",
      subService.Sub_Service_Description
    );
    formData.append("Sub_Service_ID", subService.ID);

    if (subServiceImage) {
      formData.append("Sub_Service_Image", subServiceImage);
    }

    setLoad(true);
    try {
      const response = await axios.put(
        `${subServiceCorporationApi}/${subService.ID}`,
        formData,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      if (response.data.isUpdated) {
        const updatedSubService = response.data.SubServiceCorporation;
        console.log("step1");
        setData((prevData) => {
          const updatedData = [...prevData];
          const index = updatedData.findIndex(
            (item) => item.ID === updatedSubService.Service_ID
          );
          if (index !== -1) {
            const subIndex = updatedData[
              index
            ].SubServiceCorporations.findIndex(
              (key) => key.ID === updatedSubService.ID
            );
            if (subIndex !== -1) {
              updatedData[index].SubServiceCorporations[subIndex] =
                updatedSubService;
            }
          }
          return updatedData;
        });

        setFilterData((prevData) => {
          const updatedData = [...prevData];
          const index = updatedData.findIndex(
            (item) => item.ID === updatedSubService.Service_ID
          );
          if (index !== -1) {
            const subIndex = updatedData[
              index
            ].SubServiceCorporations.findIndex(
              (key) => key.ID === updatedSubService.ID
            );
            if (subIndex !== -1) {
              updatedData[index].SubServiceCorporations[subIndex] =
                updatedSubService;
            }
          }
          return updatedData;
        });
      }
      notifysuccess("Sub service Update Successfully", { duration: 4000 });
      reset();
      setShowEditService(false);
      setService({});
      navigate("/corporations/dashboard/manageservice");
    } catch (error) {
      // console.log(error);
      notifyfailure(
        error.message || "Failed to Create Contract. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const onUpdateService = async (data) => {
    const formData = new FormData();
    formData.append("Service_Name", data.Service_Name);
    formData.append("Service_Description", data.Service_Description);
    // Append the main service image if it exists
    if (Service_Image) {
      formData.set("Service_Image", Service_Image);
    }
    setLoad(true);
    try {
      const { data } = await axios.put(
        `${serviceCorporationApi}/${service.ID}`,
        formData,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      if (data.isUpdated) {
        setData((prevData) => {
          const updatedData = [...prevData];
          const index = updatedData.findIndex(
            (item) => item.ID === data.service.ID
          );
          if (index !== -1) {
            updatedData[index] = data.service;
          }
          return updatedData;
        });
        setFilterData((prevData) => {
          const updatedData = [...prevData];
          const index = updatedData.findIndex(
            (item) => item.ID === data.service.ID
          );
          if (index !== -1) {
            updatedData[index] = data.service;
          }
          return updatedData;
        });
      }
      notifysuccess("Service Update Successfully", { duration: 4000 });
      reset();
      setShowEditService(false);
      setService({});
      navigate("/corporations/dashboard/manageservice");
    } catch (error) {
      // console.log(error);
      notifyfailure(
        error.message || "Failed to Create Contract. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    // Prepopulate the form fields with the selected service data
    if (service) {
      setValue("Service_Name", service.Service_Name);
      setValue("Service_Description", service.Service_Description);

      if (service.SubServiceCorporations) {
        const { SubServiceCorporations } = service;
        // console.log(SubServiceCorporations);
        setValue("Sub_Services", SubServiceCorporations);

        if (SubServiceCorporations.length > 0) {
          SubServiceCorporations.forEach((subService, index) => {
            setSubServiceCount((prev) => prev + 1);
            if (subService.Sub_Service_Image) {
              // Ensure that `prev` is always an array before updating
              setSubServiceImages((prev = []) => {
                const updatedImages = [...prev];
                updatedImages[index] = subService.Sub_Service_Image;
                return updatedImages;
              });

              setDisplaySubServiceImages((prev = []) => {
                const updatedDisplayImages = [...prev];
                updatedDisplayImages[index] = subService.Sub_Service_Image;
                return updatedDisplayImages;
              });
            }
          });
        }
      }

      if (service.Service_Image) {
        setValue("Service_Image", service.Service_Image);
        setDisplayService_Image(service.Service_Image);
      }

      setService(service);
      // Add any other fields you need to prepopulate
    }
  }, [service, setValue, getValues]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onUpdateService)}
        encType="multipart/form-data"
      >
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-secondaryl font-extrabold text-xl euclidbold">
            Edit Service
          </h2>
          <button
            onClick={() => {
              setShowEditService(false);
              setService({});
            }}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            ✖
          </button>
        </div>
        {/* Project Name */}

        <div className="">
          {/* service name */}
          <InputBox
            title={"Service Name"}
            register={register}
            filedName={"Service_Name"}
            must={true}
            errors={errors}
            className={"md:text-sm lg:text-base"}
          />

          {/* service Description */}
          {/* Project Description */}
          <div className="mb-3">
            <label
              htmlFor="projectDescription"
              className="block mb-2 text-secondaryl"
            >
              Service Description
              <span className="text-red-500 text-base">*</span>
            </label>
            <textarea
              id="Service_Description"
              name="Service_Description"
              {...register("Service_Description")}
              className={`bg-[#FFFFFF80] w-full max-h-40 min-h-40 px-2 py-2 h-36 rounded-sm text-black   focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none ${
                errors?.Service_Description ? "ring-[0.5px] ring-red-500" : ""
              }`}
              placeholder="Enter service description"
            ></textarea>

            <div className="h-[2vh] pt-1">
              {errors?.Service_Description && (
                <p className="text-red-500 text-xs leading-none">
                  {errors?.Service_Description.message}
                </p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 place-items-end mb-3">
            <div className="place-self-start">
              <div className="mb-2 lg:mb-0">
                <label
                  className={`block font-normal mb-3 text-secondaryl text-[12px] sm:text-[12px] md:text-base`}
                >
                  Service Image (Optional)
                </label>

                <div className="flex flex-wrap gap-3 max-w-[720px]">
                  {displayService_Image ? (
                    <div className="relative">
                      <img
                        src={displayService_Image}
                        alt=""
                        className="object-cover h-52 w-52 rounded-sm "
                      />

                      <Icon
                        onClick={handleRemoveServiceImage}
                        icon="icon-park-solid:close-one"
                        className="absolute top-1 right-1 text-primaryl text-2xl hover:text-secondaryl cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div key={`Service_Image`}>
                      <div className="col-span-2 sm:col-span-2 md:col-span-1">
                        <input
                          type="file"
                          id={`Service_Image`}
                          name={`Service_Image`}
                          className="hidden"
                          accept=".jpg,.jpeg,.png"
                          onChange={(e) => handleChangeService_Image(e)}
                          disabled={Service_ImageUploading}
                          multiple
                        />

                        <label
                          htmlFor={`Service_Image`}
                          className={`block bg-[#FFFFFF80] h-52 w-52 rounded-sm text-black  px-2  focus:ring-sky-600 focus:outline-none ${
                            Service_ImageUploading
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          style={{
                            boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                          }}
                          disabled={Service_ImageUploading}
                        >
                          <div className="flex flex-col opacity-50 justify-center items-center h-full space-x-1 overflow-hidden">
                            <InputFileIcon width="40" height="45" />

                            <span className="text-infromation text-center text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                              .jpg, .png, .jpeg files only
                            </span>
                          </div>
                        </label>
                      </div>
                    </div>
                  )}

                  {/* Error */}
                  <div className="h-[3vh] mb-3">
                    {Service_ImageUploading && <Spinner />}

                    {Service_ImageError && (
                      <p className="text-red-500 text-xs leading-none">
                        {Service_ImageError}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Upload Button */}
            <div className="">
              <button
                type="submit"
                className={`
          h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out  ${
            Service_ImageUploading ? "cursor-not-allowed" : "cursor-pointer"
          }`}
                disabled={Service_ImageUploading}
              >
                Save
              </button>
            </div>
          </div>
          {/* Sub Service Form */}
          {subServiceCount > 0 && (
            <h2 className="text-secondaryl font-extrabold text-xl euclidbold mb-3">
              Edit Sub Service
            </h2>
          )}
          <div className="">
            {subServiceCount > 0 &&
              getValues("Sub_Services")?.length > 0 &&
              getValues("Sub_Services").map((sub_Service, index) => (
                <div key={index} className="mb-3">
                  <div className="">
                    <div className="">
                      <div className="mb-3 w-full">
                        <label
                          htmlFor={`Sub_Service_Name-${index}`}
                          className="block font-normal text-secondaryl"
                        >
                          Service Name {index + 2}
                          <sup className="text-red-500 text-base">*</sup>
                        </label>
                        <input
                          id={`Sub_Service_Name-${index}`}
                          name={`Sub_Services[${index}].Sub_Service_Name`}
                          type="text"
                          placeholder={`Enter Service Name ${index + 2}`}
                          {...register(
                            `Sub_Services[${index}].Sub_Service_Name`
                          )}
                          className=" block w-full  h-11 rounded-sm text-black bg-[#FFFFFF80]  px-2  ps-10 focus:ring-[0.5px] focus:ring-secondaryl   focus:outline-none"
                        />
                        <div className="h-[2vh] pt-1">
                          {errors["Sub_Services"] &&
                            errors["Sub_Services"][index] &&
                            errors["Sub_Services"][index][
                              "Sub_Service_Name"
                            ] && (
                              <p className="text-red-500 text-xs leading-none">
                                {
                                  errors["Sub_Services"][index][
                                    "Sub_Service_Name"
                                  ].message
                                }
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="mb-3 w-full">
                        <label
                          htmlFor={`Sub_Service_Description-${index}`}
                          className="block font-normal text-secondaryl"
                        >
                          Service Description {index + 2}
                          <sup className="text-red-500 text-base">*</sup>
                        </label>
                        <textarea
                          id={`Sub_Service_Description-${index}`}
                          name={`Sub_Services[${index}].Sub_Service_Description`}
                          className={`bg-[#FFFFFF80] w-full max-h-40 min-h-40 px-2 py-2 h-36 rounded-sm text-black   focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none ${
                            errors.Service_Description
                              ? "ring-[0.5px] ring-red-500"
                              : ""
                          }`}
                          placeholder={`Enter Service Name ${index + 2}`}
                          {...register(
                            `Sub_Services[${index}].Sub_Service_Description`
                          )}
                        ></textarea>
                        <div className="h-[2vh] pt-1">
                          {errors["Sub_Services"] &&
                            errors["Sub_Services"][index] &&
                            errors["Sub_Services"][index][
                              "Sub_Service_Description"
                            ] && (
                              <p className="text-red-500 text-xs leading-none">
                                {
                                  errors["Sub_Services"][index][
                                    "Sub_Service_Description"
                                  ].message
                                }
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 place-items-end">
                      <div className="place-self-start">
                        <div className="lg:mb-0 w-full">
                          <label
                            className={`block font-normal text-secondaryl text-[12px] sm:text-[12px] md:text-base`}
                          >
                            Service Image (Optional)
                          </label>

                          <div className="flex flex-wrap gap-3 max-w-[720px]">
                            {displaySubServiceImages[index] ? (
                              <div
                                key={`serviceImage${index}`}
                                className="relative"
                              >
                                <img
                                  src={displaySubServiceImages[index]}
                                  alt=""
                                  className="object-cover h-52 w-52 rounded-sm "
                                />

                                <Icon
                                  onClick={() =>
                                    handleRemoveSubServiceImage(index)
                                  }
                                  icon="icon-park-solid:close-one"
                                  className="absolute top-1 right-1 text-primaryl text-2xl hover:text-secondaryl cursor-pointer"
                                />
                              </div>
                            ) : (
                              <div key={`Service_Image${index}`}>
                                <div className="col-span-2 sm:col-span-2 md:col-span-1">
                                  <input
                                    type="file"
                                    id={`Service_Image${index}`}
                                    name={`Service_Image${index}`}
                                    className="hidden"
                                    accept=".jpg,.jpeg,.png"
                                    onChange={(e) =>
                                      handleChangeSubServiceImage(e, index)
                                    }
                                    disabled={Service_ImageUploading}
                                    multiple
                                  />

                                  <label
                                    htmlFor={`Service_Image${index}`}
                                    className={`block bg-[#FFFFFF80] h-52 w-52 rounded-sm text-black  px-2  focus:ring-sky-600 focus:outline-none ${
                                      Service_ImageUploading
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    }`}
                                    style={{
                                      boxShadow:
                                        "0px 4px 4px 0px #FFFFFF1A inset",
                                    }}
                                    disabled={Service_ImageUploading}
                                  >
                                    <div className="flex flex-col opacity-50 justify-center items-center h-full space-x-1 overflow-hidden">
                                      <InputFileIcon width="40" height="45" />

                                      <span className="text-infromation text-center text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                                        .jpg, .png, .jpeg files only
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            )}
                            {/* Error */}
                            <div className="h-[3vh] mb-3">
                              {Service_ImageUploading && <Spinner />}

                              {Service_ImageError && (
                                <p className="text-red-500 text-xs leading-none">
                                  {Service_ImageError}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-4 mb-2">
                        <button
                          type="button"
                          className=" h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out"
                          onClick={() =>
                            handleEditSubService(sub_Service.ID, index)
                          }
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className=" h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out"
                          onClick={() =>
                            handleDeleteSubService(sub_Service.ID, service.ID)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditService;
